import { create } from "zustand";

interface SubmenuState {
  isSubmenuOpen: boolean;
  submenu: string;
  setSubmenu: (route: string) => void;
  hideSubmenu: () => void;
}

const SubmenuStore = create<SubmenuState>((set) => ({
  isSubmenuOpen: false,
  submenu: "",
  setSubmenu: (route: string) => {
    set({ isSubmenuOpen: true, submenu: route });
  },
  hideSubmenu: () => set({ isSubmenuOpen: false }),
}));

export default SubmenuStore;
