import { twMerge } from "tailwind-merge";

const SELECTED_STYLE = "ring-primary-500";
const UNSELECTED_STYLE = "ring-neutral-500";
const SELECTED_STYLE_CENTER = "visible opacity-100";
const UNSELECTED_STYLE_CENTER = "invisible opacity-0";

interface RadioButtonProps {
  selected?: boolean;
  onClick?: () => void;
  className?: string;
  children?: string;
}

export function RadioButton(props: RadioButtonProps) {
  const { selected, onClick, className, children } = props;
  return (
    <div className={className}>
      <button
        className="flex text-xs font-normal text-center items-center cursor-pointer"
        onClick={onClick}
      >
        <div
          className={twMerge(
            "rounded-full w-2 h-2 ring-2 text-left bg-neutral-100 items-center mr-2",
            selected ? SELECTED_STYLE : UNSELECTED_STYLE
          )}
        >
          <div
            className={twMerge(
              "w-1 h-1 relative top-0.5 left-0.5 bg-primary-500 rounded-full transition-opacity duration-300 ease-in-out",
              selected ? SELECTED_STYLE_CENTER : UNSELECTED_STYLE_CENTER
            )}
          ></div>
        </div>
        {children}
      </button>
    </div>
  );
}

export default RadioButton;
