import { create } from "zustand";

interface IRequestTrigger {
  requestTrigger: number;
  setRequestTrigger: () => void;
}

const useRequestTrigger = create<IRequestTrigger>((set) => ({
  requestTrigger: 0,
  setRequestTrigger: () => {
    // wait one second then increment the requestTrigger
    setTimeout(() => {
      set((state) => ({ requestTrigger: state.requestTrigger + 1 }));
    }, 2000);
  },
}));

export default useRequestTrigger;
