import { useGetGridCapacity } from "@/api/grid";
import { Columns } from "@/pages/forecast/types";
import { useEffect, useMemo, useState } from "react";
import { createOption } from "../Option";
import { useGetDemandData } from "../api/getData";
import { addSubstation } from "../data/addSubstationLine";
import processData from "../data/processing";

export function useOption(
  substations: string[],
  selected_values: keyof Columns,
  relative: boolean,
  energyVPower: "energy" | "power" | "min power",
  selectedYear: number,
  selectedLegends: { [key: string]: boolean }
) {
  const [figureData, setFigureData] = useState<any>(null); // [object Object
  const [isLoading, setIsLoading] = useState<boolean>(false); // [object Object]
  const [option, setOption] = useState<any>({}); // [object Object]

  const { data: rawData, loading: demandDataLoading } = useGetDemandData(
    energyVPower,
    substations,
    selected_values,
    relative
  );
  const { data: transformerData, refreshData } =
    useGetGridCapacity(substations);

  useEffect(() => {
    refreshData();
  }, [substations]);

  useEffect(() => {
    const asyncFunc = async () => {
      if (substations.length === 0 || !substations[0]) return;
      if (!rawData) return;
      setIsLoading(true);
      let data = JSON.parse(JSON.stringify(rawData));
      const processedData = await processData({
        dataItem: data,
        selectedYear: selectedYear,
        selectedDataColumn: selected_values,
      });
      if (
        transformerData &&
        (energyVPower === "power" || energyVPower === "min power") &&
        transformerData.length > 0 &&
        transformerData[0].transformer_capacity &&
        !relative
      ) {
        let transformerDataCopy = transformerData[0].transformer_capacity;
        if (energyVPower === "min power") {
          transformerDataCopy *= -1;
        }
        processedData.series = addSubstation(
          processedData.series,
          transformerDataCopy
        );
      }
      setFigureData(processedData);
      setIsLoading(false);
    };
    asyncFunc();
  }, [substations, energyVPower, selected_values, rawData]);

  useMemo(() => {
    if (!figureData) {
      return;
    }
    setIsLoading(true);
    let processedData = figureData;
    setOption(createOption(processedData, energyVPower, selectedLegends));
    setIsLoading(false);
  }, [figureData]);

  return { option, isLoading: isLoading || demandDataLoading };
}
