import { RequestGraph } from "@/features/RequestGraph";
import { useSectorStore } from "@/pages/requests/stores/sectorStore";

export function RequestProfile() {
  const sectors = useSectorStore((state) => {
    return {
      industry: state.industries,
      residential: state.residentials,
      commercial: state.commercials,
      public: state.publics,
    };
  });

  return (
    <div>
      <RequestGraph
        hidden={false}
        sectors={sectors}
        height="29rem"
      ></RequestGraph>
    </div>
  );
}
