import { Commercial } from "@/api/request/commercial";
import { Input } from "@/components/Input";
import { StatusColor } from "@/components/StatusDot";
import { Local } from "@/pages/requests/helper/api";
import { useEffect } from "react";
import { EditorProps } from "../types";
import { BorderWrap } from "./BorderWrap";
import { Container } from "./Container";

type CommercialColor = Partial<{
  [key in keyof Local<Commercial>]: StatusColor | undefined;
}>;

function getColor(commercial: Local<Commercial>): CommercialColor {
  const comArea = commercial.area && commercial.area > 0;
  const comDemand = commercial.power_demand && commercial.power_demand > 0;
  const comEnergyPerf =
    commercial.energy_performance && commercial.energy_performance > 0;

  return {
    solar_power:
      commercial.solar_power && commercial.solar_power > 0 ? "green" : "yellow",
    area:
      commercial.forecast_metric === "area"
        ? comArea || comDemand || comEnergyPerf
          ? comArea
            ? "green"
            : comDemand && comEnergyPerf
            ? undefined
            : "red"
          : "red"
        : undefined,
    forecast_metric:
      commercial.forecast_metric === "demand"
        ? comDemand
          ? "green"
          : "red"
        : undefined,

    energy_performance:
      commercial.forecast_metric === "area"
        ? comArea || comDemand || comEnergyPerf
          ? comEnergyPerf
            ? "green"
            : comArea && comDemand
            ? undefined
            : "red"
          : "red"
        : undefined,
  };
}

function getStatus(commercialColors: CommercialColor) {
  const CommercialComplete =
    Object.keys(commercialColors).length > 0 &&
    (commercialColors.area === "green" ||
      commercialColors.forecast_metric === "green");
  return CommercialComplete;
}

interface CommercialEditorProps extends EditorProps {
  sector?: Local<Commercial>;
  setSector?: (value: Local<Commercial>) => void;
  setStatus?: (value: boolean) => void;
}

export function CommercialEditor(props: CommercialEditorProps) {
  const { sector, setSector, setStatus, className } = props;
  if (!sector || !setSector) return null;
  const colors = getColor(sector);

  const status = getStatus(colors);

  useEffect(() => {
    if (setStatus) {
      setStatus(status);
    }
  }, [colors]);

  return (
    <Container className={className}>
      <BorderWrap
        legend="Profile from area"
        selected={sector.forecast_metric === "area"}
        onClick={() => {
          setSector({ ...sector, forecast_metric: "area" });
        }}
      >
        <div className="space-y-4 pb-4">
          <Input
            title="Total area"
            className="w-full"
            statusColor={colors.area}
            value={sector.area}
            onChange={(value) => {
              setSector({ ...sector, area: Number(value) });
            }}
            unit="m²"
            type="number"
          />
          <Input
            title="Energy Performance"
            className="w-full"
            statusColor={colors.energy_performance}
            value={
              sector.energy_performance && sector.energy_performance / 1000
            }
            onChange={(value) => {
              setSector({
                ...sector,
                energy_performance: Number(value) * 1000,
              });
            }}
            unit="kwh/m²/year"
            type="number"
          />
        </div>
      </BorderWrap>
      <BorderWrap
        legend="Profile from power"
        selected={sector.forecast_metric === "demand"}
        onClick={() => {
          setSector({ ...sector, forecast_metric: "demand" });
        }}
      >
        <Input
          title="Requested power"
          className="w-full"
          statusColor={colors.forecast_metric}
          value={sector.power_demand && sector.power_demand / 1000}
          onChange={(value) => {
            setSector({
              ...sector,
              power_demand: Number(value) * 1000,
            });
          }}
          type="number"
          unit="kW"
        />
      </BorderWrap>
      <Input
        title="Solar PV capacity"
        className="w-full"
        value={sector.solar_power && sector.solar_power / 1000}
        onChange={(value) => {
          setSector({
            ...sector,
            solar_power: Number(value) * 1000,
          });
        }}
        unit="kW"
        type="number"
      />
    </Container>
  );
}
