import { Request } from "@/api/request/request";
import { SubmenuStore } from "@/hooks";
import CloseIcon from "@mui/icons-material/Close";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Columns } from "../../columns";
import { useAnalysisSettings } from "../../stores/analysisSettings";

type Entry = {
  label: string;
  value: string;
};
interface SelectDropdownProps {
  label: string;
  value: string;
  entries: Entry[];
  onChange?: (e: any) => void;
}

function SelectDropdown(props: SelectDropdownProps) {
  return (
    <FormControl sx={{ minWidth: 120, m: 1 }} size="small">
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        size="small"
      >
        {props.entries.map((entry) => (
          <MenuItem value={entry.value}>{entry.label} </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
const GROUPBY_COLUMNS: (keyof Request)[] = [
  "requested_power",
  "estimated_power",
  "gross_area",
  "charging_stations",
  "solar_panels",
  "quantity",
];
const LEGEND_COLUMNS: (keyof Request)[] = [
  "sector",
  "start_date",
  "completion_date",
  "substation",
];
const HISTAXIS_COLUMNS: (keyof Request)[] = [
  "sector",
  "start_date",
  "completion_date",
  "substation",
];

export default function Settings() {
  const { hideSubmenu } = SubmenuStore((state) => state);
  const { setGroupbyColumn, setLegend, setHistXAxis, settings } =
    useAnalysisSettings();
  const groupbyEntries = Columns.filter((col) =>
    GROUPBY_COLUMNS.includes(col.value)
  );
  const legendEntries = Columns.filter((col) =>
    LEGEND_COLUMNS.includes(col.value)
  );
  const histXAxisEntries = Columns.filter((col) =>
    HISTAXIS_COLUMNS.includes(col.value)
  );

  return (
    <div className="">
      <div className="flex justify-end">
        <button
          onClick={() => {
            hideSubmenu();
          }}
          className="p-2"
        >
          <CloseIcon />
        </button>
      </div>
      <h1 className="font-medium text-xl text-left pl-4 pb-6">Settings</h1>
      <Divider className="text-neutral-600 pb-4"> Data Analysis </Divider>
      <div className="flex flex-col text-neutral-600">
        <div className="my-4 text-sm text-center">
          Which columns to calculate charts axes from
        </div>
        <SelectDropdown
          label="Calculated Axis"
          value={settings.groupbyColumn}
          entries={groupbyEntries}
          onChange={(e: any) => setGroupbyColumn(value)}
        />

        <div className="my-4 text-sm text-center">
          Which column to use as legend
        </div>
        <SelectDropdown
          label="Set legend"
          value={settings.legend}
          entries={legendEntries}
          onChange={(e: any) => setLegend(value)}
        />

        <div className="my-4 text-sm text-center">
          Which column to use as x axis for Histogram
        </div>
        <SelectDropdown
          label="Histogram X axis"
          value={settings.histXAxis}
          entries={histXAxisEntries}
          onChange={(e: any) => setHistXAxis(value)}
        />
      </div>
    </div>
  );
}
