import { Commercial } from "@/api/request/commercial";
import { useSectorStore } from "@/pages/requests/stores/sectorStore";
import { CommercialEditor } from "../../../SectorEditor";
import { SectorTabs } from "../Generic/SectorWrapper";

export function CommercialPanel() {
  const [
    addDefaultCommercial,
    commercials,
    updateCommercial,
    deleteCommercial,
  ] = useSectorStore((state) => [
    state.addDefaultCommercial,
    state.commercials,
    state.updateCommercial,
    state.deleteCommercial,
  ]);

  return (
    <>
      <SectorTabs<Commercial>
        sectorName="commercial"
        sectors={commercials}
        setSectors={updateCommercial}
        addSector={addDefaultCommercial}
        deleteSector={deleteCommercial}
        SectorComponent={CommercialEditor}
      />
    </>
  );
}
