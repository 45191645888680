import { EChartsInstance } from "echarts-for-react";

export default function histChartEvents() { }

export function highlightSelectedYear(yearIndex: number, chartRef: any) {
  const chart: EChartsInstance =
    chartRef.current.getEchartsInstance() as EChartsInstance;

  let series = chart.getOption().series;
  series.forEach((s: any) => {
    if (s.name === "Total" || s.name === "Substation Capacity") {
      return;
    }

    s.data.map((d: any, j: number) => {
      d.itemStyle = {
        opacity: j === yearIndex ? 1 : 0.3,
      };
    });
  });

  chart.setOption({ series });
}

export const setSelectedYearEvent = (
  params: any,
  setSelectedYear: any,
  chartRef: any
) => {
  setSelectedYear(Number(params.name));
  if (chartRef.current === null) {
    return;
  }
  highlightSelectedYear(params.dataIndex, chartRef);
};

export const selectLegend = (legend: string, chartRef: any) => {
  if (chartRef.current === null) {
    return;
  }
  const chart = chartRef.current.getEchartsInstance();
  chart.dispatchAction({
    type: "legendAllSelect",
  });
  if (legend === "Total") {
    return;
  }
  chart.dispatchAction({
    type: "legendInverseSelect",
    name: legend,
  });
  chart.dispatchAction({
    type: "legendSelect",
    name: legend,
  });
};
