// maps legends to colors
import { Columns } from "./types";

export const COLORS: { [K in keyof Partial<Columns>]: string } = {
  "Solar PV": "#fac858",
  Heating: "#ee6666",
  Housing: "#73c0de",
  Base: "#73c0de",
  Car: "#5470c6",
  "Public Charging": "#0057e0",
  "Home Charging": "#8ab0ed",
  "HV Loads": "#3ba272",
  Truck: "#fc8452",
  Bus: "#95cc71",
  Total: "#000000",
  Requests: "#6e6e6e",
};

export const COLORS_TRUCK: { [K in keyof Partial<Columns>]: string } = {
  "Direct Charging": "#fc8452",
  "Night Charging": "#cc4c16",
};
export const COLORS_BUS: { [K in keyof Partial<Columns>]: string } = {
  "Direct Charging": "#95cc71",
  "Night Charging": "#738c62",
};
export function getColor(key: keyof Columns, subcategory?: keyof Columns) {
  let colors = COLORS;
  if (subcategory === "Truck") {
    colors = { ...COLORS, ...COLORS_TRUCK };
  } else if (subcategory === "Bus") {
    colors = { ...COLORS, ...COLORS_BUS };
  }
  return colors[key];
}
