import { Color } from "../types";

export type ColorData = { [station_id: string]: Color };

type InputData = { [station_id: string]: number };
function roundToNearest(num: number) {
  const nearest = [0, 0.2, 0.4, 0.6, 0.8, 1];
  return nearest.reduce((prev, curr) => (num > curr ? curr : prev));
}

export function getMax(dataInput: InputData) {
  let max = Math.max(...Object.values(dataInput)); //quantileSeq([...Object.values(dataInput)], 0.99);
  if (max === 0) {
    max = Math.abs(Math.min(...Object.values(dataInput)));
  }
  if (max === 0) {
    max = 1;
  }
  return max as number;
}

export function getMin(dataInput: InputData) {
  // Always above zero
  return Math.min(...Object.values(dataInput));
}

interface LayerDataRow {
  station_id: string;
  energy?: number;
  power?: number;
  utilization?: number;
  color?: Color;
}

type LayerData = LayerDataRow[];

export function addColor(data?: LayerData, useDiscrete?: boolean) {
  let valueColumn: keyof LayerDataRow;
  if (!data || data.length === 0) return [];
  if (Object.keys(data[0]).includes("energy")) {
    valueColumn = "energy";
  } else if (Object.keys(data[0]).includes("power")) {
    valueColumn = "power";
  } else {
    valueColumn = "utilization";
  }
  let maxValue = Math.max(...data.map((d) => d[valueColumn] as number));

  let minValue = Math.min(...data.map((d) => d[valueColumn] as number));
  if (data.length === 1) {
    minValue = 0;
  }
  const range = maxValue - minValue;

  let dataWithColor = data.map((d) => {
    let colorNumber = ((d[valueColumn] as number) - minValue) / range;
    if ((d[valueColumn] as number) < 0) {
      colorNumber = ((d[valueColumn] as number) / minValue) * -1;
    }
    return {
      ...d,
      color: {
        color: !useDiscrete
          ? colorFromStationId(colorNumber)
          : colorFromStationIdDiscrete(d[valueColumn] as number),
        opacity: !useDiscrete ? Math.abs(colorNumber) * 0.3 + 0.3 : 0.5,
      },
    };
  });
  return dataWithColor;
}

export function colorFromStationId(colorNumber: number) {
  let color = 0;
  if (colorNumber < 0) {
    color = 120;
  }
  return `hsl(${color}, 100%, ${
    100 - Math.min(Math.abs(colorNumber), 1) * 50
  }%)`;
}
export function colorFromStationIdDiscrete(value: number) {
  if (value < 0) return "#FFFFFF"; // white
  if (value < 70) return "#0cb108"; // green
  if (value < 85) return "#fac858"; // yellow
  if (value < 100) return "#d18502"; // orange
  return "#ed1616"; // red
}

export function substationStyle(
  feature: any,
  selectedSubstations: string[],
  data?: LayerData
) {
  const stationId: string = feature.properties.station_id;
  let row = data?.find((d) => d.station_id === stationId);
  const color: Color = row ? row.color! : { color: "#B6b6b610", opacity: 0.3 };
  return {
    fillColor: color.color,
    color: "black",
    fillOpacity: color.opacity,
    weight: selectedSubstations.includes(stationId) ? 3 : 1,
  };
}
