import { HeaderRadioButtons } from "@/components/HeaderRadioButton";
import RoundedContainer from "@/components/rounded-container";
import { Map } from "@/features/Map";
import { SubmenuStore } from "@/hooks";
import { useState } from "react";
import { DataAnalysisTab } from "./features/DataAnalysis";
import { DataGrid } from "./features/DataGrid";
import Settings from "./features/Settings";

const BUTTONS = [
  {
    name: "Table",
    value: "datagrid",
  },
];

export default function Requests() {
  const [selectedPage, setSelectedPage] = useState("datagrid"); // "datagrid" or "map"
  const [selectedSubstations, setSelectedSubstations] = useState([]); // ["substation1", "substation2"
  const { submenu, isSubmenuOpen } = SubmenuStore((state) => state);
  return (
    <div className="grid grid-flow-row grid-cols-6 h-full">
      <div className="col-span-4 h-full flex flex-col">
        <HeaderRadioButtons
          radioItems={BUTTONS}
          selected={selectedPage}
          onChange={(e) => setSelectedPage(e.target.value)}
        />
        {selectedPage === "datagrid" ? (
          <div className="m-2 flex flex-col flex-auto shadow-lg h-auto max-h-30">
            <DataGrid />
          </div>
        ) : selectedPage === "analysis" ? (
          <DataAnalysisTab />
        ) : selectedPage === "hourly" ? null : null}
      </div>
      <div className="col-span-2">
        <div className="grid grid-flow-row h-full p-2">
          {submenu === "request-settings" && isSubmenuOpen ? (
            <RoundedContainer classNameOuter="col-span-2 pb-0">
              <Settings />
            </RoundedContainer>
          ) : (
            <Map
              className="rounded-lg border-2 border-neutral-300 shadow-md"
              selectedSubstations={selectedSubstations}
              setSelectedSubstations={setSelectedSubstations}
              markers={true}
              tiles={true}
              showGridData={false}
            ></Map>
          )}
        </div>
      </div>
    </div>
  );
}
