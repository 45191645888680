import { Public } from "@/api/request/public";
import { useSectorStore } from "@/pages/requests/stores/sectorStore";
import { PublicEditor } from "../../../SectorEditor";
import { SectorTabs } from "../Generic/SectorWrapper";

export function PublicPanel() {
  const [addDefaultPublic, publics, updatePublic, deletePublic] =
    useSectorStore((state) => [
      state.addDefaultPublic,
      state.publics,
      state.updatePublic,
      state.deletePublic,
    ]);

  return (
    <>
      <SectorTabs<Public>
        sectorName="public"
        sectors={publics}
        setSectors={updatePublic}
        addSector={addDefaultPublic}
        deleteSector={deletePublic}
        SectorComponent={PublicEditor}
      />
    </>
  );
}
