import { twMerge } from "tailwind-merge";

export const CONTAINER_HEIGHT = "h-[26rem]";
export const CONTAINER_WIDTH = "w-60";

interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
}

export function Container(props: ContainerProps) {
  const { children, className } = props;
  return (
    <div
      className={twMerge(
        "bg-neutral-100 p-4 rounded-md shadow-md ring-1 ring-neutral-400 w-60 h-[26rem] flex justify-center",
        className
      )}
    >
      <div className="text-neutral-800 space-y-6 rounded-md w-full">
        {children}
      </div>
    </div>
  );
}
