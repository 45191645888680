import { useEffect, useState } from "react";
import { useApi } from "./Api";

const cache: { [key: string]: any } = {};

interface UseCacheProps<T> {
  endpoint: string;
  cacheKey: string;
}

export function useCache<T>({ endpoint, cacheKey }: UseCacheProps<T>) {
  const { loading, fetchData } = useApi("GET");
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    if (!cacheKey) return;
    if (endpoint.includes("undefined")) return;
    if (endpoint.includes("=&")) return;

    if (cache[cacheKey]) {
      setData(cache[cacheKey]);
      return;
    }

    fetchData(endpoint)
      .then((data: T) => {
        setData(data);
        cache[cacheKey] = data;
      })
      .catch((error) => {
        console.error(error);
      });
  }, [endpoint, cacheKey]);

  return { data, loading };
}
