import RoundedContainer from "@/components/rounded-container";
import { DistanceChart } from "./DistanceChart";
import { getCarData, CarData } from "../api/getCarData";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "@/components/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useLocationStore } from "@/stores";

export interface TrucksTabProps {
  selectedSubstations: number[];
}

export function CarsTab(props: TrucksTabProps) {
  const { selectedSubstations } = props;
  const [carData, setCarData] = useState<CarData>();
  const { getAccessTokenSilently } = useAuth0();
  const { location } = useLocationStore();
  useEffect(() => {
    async function af() {
      const token = await getAccessTokenSilently();
      const data = await getCarData(token, location, selectedSubstations);
      setCarData(data);
    }
    af();
  }, [selectedSubstations, location]);

  return (
    <>
      <RoundedContainer classNameOuter="h-30">
        <div className="text-sm font-thin w-3/4">
          Car forecasts and analytics are based on endres mobility data. The
          data contains travel patterns such as days with more travel and days
          with less travel. These days usually correlate with specific events or
          holidays. These datasets cover where EV demand might be high
          (locations where trips ends) and the demand of these trips.
          <br />
          <br /> The categories are are defined as follows: Short (&lt;10km)
          Medium (10-100km), Long (&gt;100km)
        </div>
      </RoundedContainer>
      <RoundedContainer classNameOuter="grow" className="flex flex-col">
        <div className="flex justify-between">
          <div className="text-sm font-thin w-3/4">
            The total distance traveled in a day by all cars.
          </div>
        </div>

        <DistanceChart data={carData} />
      </RoundedContainer>
    </>
  );
}
