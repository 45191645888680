import { Request, useGetRequests } from "@/api/request/request";
import useRequestTrigger from "@/hooks/requestTrigger";
import { LatLngExpression } from "leaflet";
import { useEffect } from "react";

function processMarkerData(fetchedData: Request[]) {
  let MarkerData: RequestMarkerProps[] = fetchedData.map((request) => {
    return {
      location: [request.latitude!, request.longitude!],
      name: request.name,
    };
  });
  return MarkerData;
}

interface RequestMarkerProps {
  location: LatLngExpression;
  name: string;
}
export function useRequestMarkers() {
  const { data, refreshData, loading } = useGetRequests();
  const { requestTrigger, setRequestTrigger } = useRequestTrigger();

  useEffect(() => {
    refreshData();
  }, [requestTrigger]);

  if (data) {
    const markerData = processMarkerData(data);
    return { markers: markerData, loading };
  }
  return {};
}
