import { Request } from "@/api/request/request";

export default function processData(
  data: Request[],
  legend: keyof Request,
  value: keyof Request
) {
  const temp: { [name: string]: number } = {};
  const result: { name: string; value: number }[] = [];

  data.forEach((row) => {
    let rowLegend = row[legend]?.toString();
    if (!rowLegend) {
      rowLegend = "Unknown";
    }
    if (!temp[rowLegend]) {
      temp[rowLegend] = 0;
    }
    temp[rowLegend] += row[value] as number;
  });
  for (let [key, value] of Object.entries(temp)) {
    result.push({ name: key, value: value });
  }
  // Sort the data by name.
  // This is in order to ensure it can be referenced
  // easily with other charts
  result.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    return 1;
  });
  return result;
}
