import { Residential } from "@/api/request/residential";
import { useSectorStore } from "@/pages/requests/stores/sectorStore";
import { ResidentialEditor } from "../../../SectorEditor";
import { SectorTabs } from "../Generic/SectorWrapper";

export function ResidentialPanel() {
  const [
    addDefaultResidential,
    residentials,
    updateResidential,
    deleteResidential,
  ] = useSectorStore((state) => [
    state.addDefaultResidential,
    state.residentials,
    state.updateResidential,
    state.deleteResidential,
  ]);

  return (
    <>
      <SectorTabs<Residential>
        sectorName="residential"
        sectors={residentials}
        setSectors={updateResidential}
        addSector={addDefaultResidential}
        deleteSector={deleteResidential}
        SectorComponent={ResidentialEditor}
      />
    </>
  );
}
