import { useDebounce } from "@/hooks/debounce";
import { useApi } from "@/services/Api";
import { useCallback, useEffect, useRef, useState } from "react";

export function useGenericGet<T>(endpoint: string) {
  const { loading, fetchData } = useApi();
  const [data, setData] = useState<T | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const firstLoad = useRef(true);

  async function fetchResults(endpoint: string) {
    return fetchData(endpoint)
      .then((r) => {
        setData(r);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const refreshData = () => {
    setRefresh(!refresh);
  };

  const [debouncedLoading, setDebouncedLoading] = useState(false);
  const debouncedFetch = useCallback(useDebounce(fetchResults, 500), []);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    debouncedFetch(endpoint);
  }, [debouncedLoading, debouncedFetch]);

  useEffect(() => {
    setDebouncedLoading(true);
  }, [refresh]);

  useEffect(() => {
    if (!loading) {
      setDebouncedLoading(false);
    }
  }, [loading]);

  return { loading: loading || debouncedLoading, data, refreshData };
}
