import { Commercial, useInsertCommercial } from "@/api/request/commercial";
import { Public, useInsertPublic } from "@/api/request/public";
import { Residential, useInsertResidential } from "@/api/request/residential";

import { Industry, useInsertIndustry } from "@/api/request/industry";
import {
  Request,
  useDeleteRequest,
  useGetRequests,
  useInsertRequest,
  useUpdateRequest,
} from "@/api/request/request";
import { useEffect } from "react";
import { Local, omitLocal, toLocalArray } from "../helper/api";
import { useRequestStore } from "../stores/requestsStore";

export const RequestTemplate: Local<Request> = {
  id_: "",
  name: "",
  requested_power: undefined,
  estimated_power: undefined,
  start_date: "",
  completion_date: "",
  latitude: undefined,
  longitude: undefined,
  substation: "",
  status_: "new",
};

interface NewRequestInput {
  request: Local<Request>;
  industry: Local<Industry>[];
  residential: Local<Residential>[];
  commercial: Local<Commercial>[];
  public: Local<Public>[];
}

export function useRequests() {
  const [
    requests,
    deleteRequestLocal,
    addRequest,
    setRequests,
    updateRequestLocal,
  ] = useRequestStore((state) => [
    state.requests,
    state.deleteRequest,
    state.addRequest,
    state.setRequests,
    state.updateRequest,
  ]);
  const { data } = useGetRequests();
  const { insertRequest } = useInsertRequest();
  const { insertIndustry } = useInsertIndustry();
  const { insertResidential } = useInsertResidential();
  const { insertCommercial } = useInsertCommercial();
  const { insertPublic } = useInsertPublic();
  const { updateRequest: updateRequest_ } = useUpdateRequest();

  /*
  Deletes request both local and remote
  */
  const { deleteRequest: deleteRequest_ } = useDeleteRequest();
  async function deleteRequest(id: number) {
    await deleteRequest_(id);
    deleteRequestLocal(id);
  }

  /* 
  Updates request both local and remote. Id needed as input in order to change id
  */
  async function updateRequest(
    id: Local<Request>["id_"],
    request: Local<Request>
  ) {
    let ok = await updateRequest_(omitLocal(request));
    if (ok) {
      updateRequestLocal(id, request);
    }
    return ok;
  }

  /*
  inserts new Request
   */
  async function insertNewRequest(newRequest: NewRequestInput) {
    let newRequestId = await insertRequest(omitLocal(newRequest.request));
    if (!newRequestId) return;
    let ok = true;
    for (const industry of newRequest.industry) {
      ok =
        ok &&
        (await insertIndustry({
          ...omitLocal(industry),
          request_id: newRequestId,
        }));
    }
    for (const residential of newRequest.residential) {
      ok =
        ok &&
        (await insertResidential({
          ...omitLocal(residential),
          request_id: newRequestId,
        }));
    }
    for (const commercial of newRequest.commercial) {
      ok =
        ok &&
        (await insertCommercial({
          ...omitLocal(commercial),
          request_id: newRequestId,
        }));
    }
    for (const public_ of newRequest.public) {
      ok =
        ok &&
        (await insertPublic({
          ...omitLocal(public_),
          request_id: newRequestId,
        }));
    }
    if (ok) {
      addRequest({
        ...newRequest.request,
        id_: newRequestId,
        status_: "synced",
      });
    }
  }

  function DropNewRequests() {
    setRequests(requests.filter((r) => r.status_ !== "new"));
  }

  useEffect(() => {
    if (!data) return;
    setRequests(toLocalArray(data));
  }, [data]);

  return {
    refreshData: () => {},
    DropNewRequests,
    deleteRequest,
    insertNewRequest,
    updateRequest,
  };
}
