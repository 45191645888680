import { twMerge } from "tailwind-merge";

const COLORS = {
  primary: "text-neutral-100 bg-primary-600-80 hover:bg-primary-600-50",
  secondary: "text-neutral-100 bg-neutral-500 hover:bg-neutral-500-80",
  tertiary: "text-neutral-700 bg-neutral-400 hover:bg-neutral-400-80",
  reset: "text-neutral-100 bg-red-500 hover:bg-red-500-75",
};

const SIZES = {
  small: "text-xs py-1.5 px-2 w-20 font-normal",
  medium: "text-sm py-2 px-3 w-30 font-normal",
  large: "text-base py-2 px-3 w-40",
};

export interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "primary" | "secondary" | "tertiary" | "reset";
  size?: "small" | "medium" | "large";
  className?: string;
}

export function Button(props: ButtonProps) {
  const { children, onClick, disabled, type, className, size } = props;
  let color: ButtonProps["type"] = "primary";
  if (type) color = type;
  let size_: ButtonProps["size"] = "medium";
  if (size_) size_ = size;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={twMerge(
        "font-bold rounded-md shadow-lg h-fit font-",
        COLORS[color],
        size_ && SIZES[size_],
        className
      )}
    >
      {children}
    </button>
  );
}

export default Button;
