import { RadioGroupMat } from "@/components/RadioGroupMat";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import { Divider } from "@mui/material";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { Category, useTilesSettingsStore } from "../stores/tilesSettingsStore";
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

interface DemandCategoryItem {
  title: string;
  value: Category;
}

const DEMAND_CATEGORY: DemandCategoryItem[] = [
  {
    title: "Energy",
    value: "energy",
  },
  {
    title: "Power",
    value: "power",
  },
  {
    title: "Peak Loading",
    value: "utilization",
  },
];
const FLEX_CATEGORY = [
  {
    title: "Energy",
    value: "flex_energy",
  },
  {
    title: "Power",
    value: "flex_power",
  },
  {
    title: "Hours",
    value: "flex_hours",
  },
];

export function TilesOptions() {
  // stop propagation

  const { category, setCategory } = useTilesSettingsStore((state) => ({
    category: state.category,
    setCategory: state.setCategory,
  }));
  const [selected, setSelected] = useState(false);
  return (
    <div className={POSITION_CLASSES.topright}>
      <div className="leaflet-control pointer-events-auto">
        <div className="group">
          <div className="flex justify-end">
            <button
              className="w-20 fill-neutral-600 shadow-lg rounded-md bg-neutral-100-80 backdrop-blur  text-neutral-800 text-xs p-1"
              onClick={() => setSelected(!selected)}
            >
              Layers
              <LayersRoundedIcon
                className="ml-1"
                fontSize="inherit"
              ></LayersRoundedIcon>
            </button>
          </div>
          <div
            className={twMerge(
              " mt-2 transition-opacity ease-in-out duration-200 fill-neutral-600 p-0.5 shadow-lg rounded-md bg-neutral-100-80 backdrop-blur px-1 py-1 text-neutral-800 text-xs p-2",
              selected ? "opacity-100" : "opacity-0"
            )}
          >
            {selected ? (
              <>
                <span className="w-40 text-xs font-thin inline-block mt-6">
                  Here you can choose what layer to display on the map
                </span>
                <div className="m-2 text-xs text-neutral-700">
                  <Divider>Demand</Divider>
                </div>
                <RadioGroupMat
                  radioItems={DEMAND_CATEGORY}
                  selectedValue={category}
                  optionClassName="p-2"
                  onChange={(e) => {
                    setCategory(e.target.value as Category);
                  }}
                ></RadioGroupMat>
                <div className="my-2"></div>
                {/*}
                <div className="my-2">
                  <div className="m-2 text-xs text-neutral-700">
                    <Divider>Flexibility</Divider>
                  </div>
                  <div className="flex justify-center">
                    <RadioGroupMat
                      radioItems={FLEX_CATEGORY}
                      selectedValue={category}
                      optionClassName="p-2"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    ></RadioGroupMat>
                  </div>
                </div>
                    */}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
