import { suffixFormatter } from "@/utils/suffixFormatter";
import { Tooltip } from "react-leaflet";

export interface HoverInfo {
  station_id?: string;
  substation?: string;
  energy?: number;
  maxPower?: number;
  utilization?: number;
  year?: number;
}
export interface HoverTooltipProps {
  hoverInfo?: HoverInfo;
}

export function HoverTooltip(props: HoverTooltipProps) {
  const { hoverInfo } = props;
  return (
    <Tooltip direction="top" offset={[0, -10]} opacity={1}>
      {props && (
        <div className="p-1">
          <div className="">
            {hoverInfo && hoverInfo.substation ? (
              <div className="text-xl font-semibold">
                {hoverInfo.substation !== "Unknown"
                  ? hoverInfo.substation
                  : "Unknown Substation"}
              </div>
            ) : null}
            {hoverInfo && hoverInfo.station_id ? (
              <div className="text-sm mt-0 pt-0">
                ID: <span className="font-bold">{hoverInfo.station_id}</span>
              </div>
            ) : null}
          </div>
          {hoverInfo && hoverInfo.year ? (
            <div className="text-sm pb-3 pt-0">
              Showing data for year: {hoverInfo.year}
            </div>
          ) : null}
          {hoverInfo && hoverInfo.energy ? (
            <div className="flex justify-between text-sm">
              Energy:
              <span className="pl-3 text-neutral-700 text-center ">
                {suffixFormatter({ value: hoverInfo.energy }) + "Wh"}
              </span>
            </div>
          ) : null}
          {hoverInfo && hoverInfo.maxPower ? (
            <div className="flex justify-between text-sm">
              Power:
              <span className="pl-3 text-neutral-700 text-center ">
                {suffixFormatter({ value: hoverInfo.maxPower }) + "Wh/h"}
              </span>
            </div>
          ) : null}
          {hoverInfo && hoverInfo.utilization && hoverInfo.utilization >= 0 ? (
            <div className="flex justify-between text-sm">
              Peak loading:
              <span className="pl-3 text-neutral-700 text-center ">
                {suffixFormatter({ value: hoverInfo.utilization }) + "%"}
              </span>
            </div>
          ) : null}
        </div>
      )}
    </Tooltip>
  );
}
