import {
  Dropdown,
  DropdownButtonBare,
  DropdownOption,
  DropdownOptions,
} from "@/components/Dropdown";
import { SideBarButton } from "@/components/SideBarButton";
import { useSectorStore } from "@/pages/requests/stores/sectorStore";
import AddIcon from "@mui/icons-material/Add";
import FactoryIcon from "@mui/icons-material/Factory";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import SchoolIcon from "@mui/icons-material/School";
import StoreIcon from "@mui/icons-material/Store";
import { Divider } from "@mui/material";
let firstOptions = [
  {
    name: "Overview",
    value: "Overview",
  },
  {
    name: "Profile",
    value: "Profile",
  },
];

interface ISecondOptions {
  name: string;
  value: "Residential" | "Commercial" | "Industry" | "Public";
  icon: any;
}

let secondOptions: ISecondOptions[] = [
  { name: "Residential", value: "Residential", icon: MapsHomeWorkIcon },
  { name: "Commercial", value: "Commercial", icon: StoreIcon },
  { name: "Industry", value: "Industry", icon: FactoryIcon },
  { name: "Public", value: "Public", icon: SchoolIcon },
];

interface ISecondOptionMap {
  Residential: boolean;
  Commercial: boolean;
  Industry: boolean;
  Public: boolean;
}
interface AddSecondOptionProps {
  secondOptionsMap: ISecondOptionMap;
}

export function AddSecondOption(props: AddSecondOptionProps) {
  const { secondOptionsMap } = props;
  let { addIndustry, addResidential, addPublic, addCommercial } =
    useSectorStore((state) => {
      return {
        addIndustry: state.addDefaultIndustry,
        addResidential: state.addDefaultResidential,
        addPublic: state.addDefaultPublic,
        addCommercial: state.addDefaultCommercial,
      };
    });
  const secondOptionsAddNew = {
    Residential: addResidential,
    Commercial: addCommercial,
    Industry: addIndustry,
    Public: addPublic,
  };
  return (
    <Dropdown className="" size="small">
      <DropdownButtonBare>
        <SideBarButton className="p-2 my-1 min-w-32 mr-2">
          <AddIcon
            className="mr-2"
            sx={{ height: "2rem" }}
            fontSize="inherit"
          ></AddIcon>
          Sector
        </SideBarButton>
      </DropdownButtonBare>
      <DropdownOptions className="">
        {secondOptions.map((option) => {
          if (secondOptionsMap[option.value]) return null;
          return (
            <DropdownOption
              value={option.value}
              className="py-0 w-32"
              onClick={() => {
                secondOptionsAddNew[option.value]();
              }}
            >
              <option.icon
                className="mr-2"
                sx={{ height: "2rem" }}
                fontSize="inherit"
              ></option.icon>
              {option.name}
            </DropdownOption>
          );
        })}
      </DropdownOptions>
    </Dropdown>
  );
}

interface SideOptionsProps {
  currentOption: string;
  setCurrentOption: (value: string) => void;
}

export function SidePanel(props: SideOptionsProps) {
  const { currentOption, setCurrentOption } = props;
  let { industries, residentials, publics, commercials } = useSectorStore(
    (state) => {
      return {
        industries: state.industries,
        residentials: state.residentials,
        publics: state.publics,
        commercials: state.commercials,
      };
    }
  );
  const secondOptionsMap = {
    Residential: residentials.length > 0,
    Commercial: commercials.length > 0,
    Industry: industries.length > 0,
    Public: publics.length > 0,
  };

  return (
    <div className="h-full w-40 bg-primary-600-80 p-4 text-2xl">
      Request <br /> Editor
      <div className="h-full mt-8">
        {firstOptions.map((option) => (
          <SideBarButton
            className="p-2 my-1 min-w-32 mr-2"
            selected={option.value === currentOption}
            onClick={() => setCurrentOption(option.value)}
          >
            {option.name}
          </SideBarButton>
        ))}
        <div className="my-3">
          <Divider className="my-2" />
        </div>
        {secondOptions.map((option) => {
          if (!secondOptionsMap[option.value]) return null;
          return (
            <SideBarButton
              className="p-2 my-1 min-w-32 mr-2"
              selected={option.value === currentOption}
              onClick={() => setCurrentOption(option.value)}
            >
              <option.icon
                className="mr-2"
                sx={{ height: "2rem" }}
                fontSize="inherit"
              ></option.icon>
              {option.name}
            </SideBarButton>
          );
        })}
        {(Object.keys(secondOptionsMap) as (keyof ISecondOptionMap)[]).some(
          (key) => !secondOptionsMap[key]
        ) ? (
          <AddSecondOption secondOptionsMap={secondOptionsMap} />
        ) : null}
      </div>
    </div>
  );
}
