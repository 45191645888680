import { memo } from "react";
import { useLayerHook } from "../hooks/layerHook";
import { useTilesSettingsStore } from "../stores/tilesSettingsStore";
import { TilesGeoJSON } from "./TilesGeoJSON";
import { TilesLegend } from "./TilesLegend";
import { TilesLevel } from "./TilesLevel";
import { TilesLoadingSymbol } from "./TilesLoadingSymbol";
const DEFAULT_YEAR = 2025;

export interface TilesProps {
  columns: string[];
  year: number;
  selectedSubstations: string[];
  setSelectedSubstations: any;
  rightClickEvent?: (e: any) => void;
}

export const Tiles = memo(
  (props: TilesProps) => {
    let {
      columns,
      year,
      selectedSubstations,
      setSelectedSubstations,
      rightClickEvent,
    } = props;
    if (!year) {
      year = DEFAULT_YEAR;
    }
    const {
      gridData,
      tiles,
      filteredTiles,
      substationInfo,
      energyLoading,
      gridCapacityLoading,
      maxPowerLoading,
    } = useLayerHook({
      columns,
      year,
      setSelectedSubstations,
    });
    const category = useTilesSettingsStore((state) => state.category);
    let loading =
      category === "energy"
        ? energyLoading
        : category === "power"
        ? maxPowerLoading
        : gridCapacityLoading || maxPowerLoading;
    return (
      <>
        <>
          <TilesGeoJSON
            gridData={gridData}
            filteredTiles={filteredTiles}
            substationInfo={substationInfo}
            tiles={tiles}
            year={year}
            selectedSubstations={selectedSubstations}
            setSelectedSubstations={setSelectedSubstations}
            rightClickEvent={rightClickEvent}
          />
          <TilesLevel selectedSubstations={selectedSubstations}></TilesLevel>

          <TilesLegend
            gridData={gridData}
            category={category}
            useDiscrete={category === "utilization"}
          />
          <TilesLoadingSymbol loading={loading} />
        </>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.year === nextProps.year &&
      prevProps.selectedSubstations === nextProps.selectedSubstations
    );
  }
);
