import { useLocationStore } from "@/stores";
import { useGenericGet } from "./utils";

interface CapacityDataRow {
  station_id: string;
  transformer_capacity?: number;
}
type CapacityData = CapacityDataRow[];

export function useGetGridCapacity(station_id?: string[]) {
  const { location } = useLocationStore();
  let endpoint: string = "grid/capacity/" + location + "/";
  if (station_id) {
    endpoint += "?station_ids=" + station_id.join("&station_ids=");
  }
  return useGenericGet<CapacityData>(endpoint);
}

interface GridInfo {
  name: string;
  station_id: string;
  parent_station_id: string;
}

export function useGetGridInfo() {
  const { location } = useLocationStore();
  let endpoint = "grid/info/" + location + "/new";
  return useGenericGet<GridInfo[]>(endpoint);
}
