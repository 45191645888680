/// <reference types="vite-plugin-svgr/client" />
import { Commercial } from "@/api/request/commercial";
import { Industry } from "@/api/request/industry";
import { Public } from "@/api/request/public";
import { Request } from "@/api/request/request";
import { Residential } from "@/api/request/residential";
import Button from "@/components/Button";
import { StatusDotInfo } from "@/components/StatusDot";
import { Local } from "@/pages/requests/helper/api";
import { useRequests } from "@/pages/requests/hooks/requests";
import { Divider, Modal } from "@mui/material";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { Part1 } from "./Part1";
import { Part2 } from "./Part2";
import { Part3 } from "./Part3";
import { Part4 } from "./Part4";
import { Part5 } from "./Part5";

interface CircleProps {
  number: number;
  isCurrent: boolean;
  active: boolean;
  setCurrentStep: (value: number) => void;
}

function Circle(props: CircleProps) {
  const { active, number, setCurrentStep, isCurrent } = props;
  return (
    <button
      disabled={!active}
      className={twMerge(
        "transition-all duration-200",
        active
          ? "bg-primary-600 hover:bg-primary-600-80 hover:cursor-pointer"
          : "bg-neutral-500-80",
        isCurrent ? "bg-primary-600-50 text-neutral-300" : "",
        "p-2 rounded-full w-10 h-10 text-center"
      )}
      onClick={() => setCurrentStep(number)}
    >
      {number}
    </button>
  );
}

interface HeaderProps {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  highestStep: number;
}

function Header(props: HeaderProps) {
  const { currentStep, setCurrentStep, highestStep } = props;
  return (
    <div>
      <div className="flex justify-center w-full mb-6 mt-4">
        {[1, 2, 3, 4, 5].map((num) => (
          <>
            <Circle
              active={num <= highestStep}
              isCurrent={num === currentStep}
              number={num}
              setCurrentStep={setCurrentStep}
            ></Circle>
            {num < 5 && (
              <div
                className={twMerge(
                  "h-[0.1rem] rounded-full w-10 mt-[1.2rem]",
                  num < highestStep ? "bg-primary-600" : "bg-neutral-600"
                )}
              ></div>
            )}
          </>
        ))}
      </div>
      <Divider></Divider>
    </div>
  );
}

interface BottomInfoProps {
  show: boolean;
  children: React.ReactNode;
}

function BottomInfo(props: BottomInfoProps) {
  const { show, children } = props;
  return (
    <div
      className={twMerge(
        "transition-opacity duration-200",
        "absolute left-4 bottom-4",
        show ? "opacity-100 visible" : "opacity-0 invisible"
      )}
    >
      {children}
    </div>
  );
}

interface BottomProps {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  highestStep: number;
  setHighestStep: (value: number) => void;
  isNextDisabled: boolean;
  onClose: () => void;
  request: Local<Request>;
  sectors: Sectors;
}

function Bottom(props: BottomProps) {
  const {
    currentStep,
    setCurrentStep,
    highestStep,
    setHighestStep,
    isNextDisabled,
    request,
    sectors,
    onClose,
  } = props;
  const { insertNewRequest } = useRequests();
  let showPrevious = currentStep > 1;
  return (
    <div className="flex justify-end space-x-2">
      <BottomInfo show={currentStep === 1}>
        <span
          className={twMerge(
            "text-neutral-800 text-xs inline-block align-bottom hover:align-top leading-8 -mr-96"
          )}
        >
          You can change substation in a later step
        </span>
      </BottomInfo>
      <BottomInfo show={currentStep === 2}>
        <span
          className={twMerge(
            "text-neutral-800 text-xs inline-block align-bottom hover:align-top leading-8"
          )}
        >
          Must choose at least one sector
        </span>
      </BottomInfo>
      <BottomInfo show={currentStep === 3 || currentStep === 4}>
        <StatusDotInfo />
      </BottomInfo>
      <Button
        className={twMerge(
          "transition-all duration-200 delay-200",
          showPrevious ? "visible opacity-100" : "invisible opacity-0"
        )}
        size="medium"
        onClick={() => setCurrentStep(Math.max(currentStep - 1, 1))}
      >
        Previous page
      </Button>
      <Button
        className={twMerge(
          "transition-all duration-200",
          currentStep < 5
            ? "opacity-100 visble"
            : "opacity-0 invisible absolute"
        )}
        size="medium"
        onClick={() => {
          if (currentStep === highestStep) {
            setHighestStep(highestStep + 1);
          }
          setCurrentStep(Math.min(currentStep + 1, 5));
        }}
        disabled={isNextDisabled}
        type={isNextDisabled ? "tertiary" : "primary"}
      >
        Next page
      </Button>
      <Button
        className={twMerge(
          "transition-all duration-200",
          currentStep === 5
            ? "opacity-100 visble"
            : "opacity-0 invisible absolute"
        )}
        size="medium"
        onClick={() => {
          let ok = insertNewRequest({ request, ...sectors });
          onClose();
        }}
        disabled={isNextDisabled}
      >
        Create request
      </Button>
    </div>
  );
}

export const RequestTemplate: Local<Request> = {
  name: "",
  requested_power: undefined,
  estimated_power: undefined,
  start_date: "",
  completion_date: "",
  latitude: undefined,
  longitude: undefined,
  status: "not_started",
  substation: [""],
  id_: 0,
  status_: "new",
};

const sectorsTemplate: Sectors = {
  industry: [],
  residential: [],
  public: [],
  commercial: [],
};

interface AddRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Sectors {
  industry: Local<Industry>[];
  residential: Local<Residential>[];
  public: Local<Public>[];
  commercial: Local<Commercial>[];
}

export function AddRequestModal(props: AddRequestModalProps) {
  const { isOpen, onClose } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [highestStep, setHighestStep] = useState(1);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [request, setRequest] = useState<Local<Request>>(RequestTemplate);
  const [sectors, setSectors] = useState<Sectors>(sectorsTemplate);
  function internalOnClose() {
    setRequest(RequestTemplate);
    setSectors(sectorsTemplate);
    setCurrentStep(1);
    setHighestStep(1);
    setIsNextDisabled(true);
    onClose();
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        internalOnClose();
      }}
    >
      <div className="flex flex-col justify-between h-[44rem] w-[40rem] bg-neutral-300 absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg p-4">
        <Header
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          highestStep={highestStep}
        ></Header>
        <div className="flex justify-center">
          {currentStep === 1 && (
            <Part1
              setIsNextDisabled={setIsNextDisabled}
              request={request}
              setRequest={setRequest}
            ></Part1>
          )}
          {currentStep === 2 && (
            <Part2
              setIsNextDisabled={setIsNextDisabled}
              sectors={sectors}
              setSectors={setSectors}
            ></Part2>
          )}
          {currentStep === 3 && (
            <Part3
              setIsNextDisabled={setIsNextDisabled}
              sectors={sectors}
              setSectors={setSectors}
            ></Part3>
          )}
          {currentStep === 4 && (
            <Part4
              setIsNextDisabled={setIsNextDisabled}
              request={request}
              setRequest={setRequest}
            />
          )}
          {currentStep === 5 && <Part5 sectors={sectors}></Part5>}
        </div>
        <Bottom
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isNextDisabled={isNextDisabled}
          highestStep={highestStep}
          setHighestStep={setHighestStep}
          onClose={internalOnClose}
          request={request}
          sectors={sectors}
        ></Bottom>
      </div>
    </Modal>
  );
}
