import { create } from "zustand";

type Zoom = [number, number];

interface LoadZoomStore {
  durZoom: [number, number];
  hourZoom: [number, number];
  setDurZoom: (zoom: [number, number]) => void;
  setHourZoom: (zoom: [number, number]) => void;
}

export const useLoadZoomStore = create<LoadZoomStore>((set) => ({
  durZoom: [0, 100],
  hourZoom: [0, 100],
  setDurZoom: (zoom: [number, number]) => set({ durZoom: zoom }),
  setHourZoom: (zoom: [number, number]) => set({ hourZoom: zoom }),
}));
