/// <reference types="vite-plugin-svgr/client" />
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { max, min } from "mathjs";
import { twMerge } from "tailwind-merge";
import { useHierarchyStore } from "../stores/levelStore";

const style = "bg-neutral-100-80 text-black fill-neutral-800";
const disabledStyle = "bg-neutral-500-80 text-neutral-800 fill-neutral-800";
const buttonStyle =
  "p-1 px-1 py-1 rounded-md shadow-lg backdrop-blur flex w-fit m-2 text-sm font-thin";
interface TilesLevelProps {
  selectedSubstations: string[];
}

export function TilesLevel(props: TilesLevelProps) {
  const { selectedSubstations } = props;
  const {
    level,
    setLevel,
    maxLevel,
    deep,
    setDeep,
    topStationsIds: topStationId,
    setTopStation,
  } = useHierarchyStore();
  return (
    <div className="leaflet-top leaflet-left leaflet-control pointer-events-auto">
      <div className={twMerge(buttonStyle, level < 1 ? disabledStyle : style)}>
        <button
          disabled={level < 1}
          onClick={() => {
            setLevel(level - 1);
            if (topStationId.length > 1)
              setTopStation(
                topStationId.slice(0, max(topStationId.length - 1, 1))
              );
          }}
        >
          <ArrowUpwardIcon></ArrowUpwardIcon>
        </button>
      </div>
      <div
        className={twMerge(
          buttonStyle,
          level === maxLevel ? disabledStyle : style
        )}
      >
        <button
          disabled={level === maxLevel}
          onClick={() => {
            setLevel(min(level + 1, maxLevel));
            if (
              selectedSubstations[0] === topStationId[topStationId.length - 1]
            ) {
              setDeep(true);
            } else {
              setDeep(false);
            }
            setTopStation([...topStationId, selectedSubstations[0]]);
          }}
        >
          <ArrowDownwardIcon></ArrowDownwardIcon>
        </button>
      </div>
    </div>
  );
}
