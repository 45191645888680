import { SliderSettingsProps } from "@/components/SliderSettings";
import { round } from "mathjs";
import { useSettingsStore } from "../../../stores/SettingsStore";

const percentageMarks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 0.5,
    label: "25%",
  },
  {
    value: 1,
    label: "50%",
  },
  {
    value: 1.5,
    label: "75%",
  },
  {
    value: 2,
    label: "100%",
  },
];

export function useBehaviourSliders() {
  const { behaviorNumbers, setBehaviourNumbers } = useSettingsStore();
  const behaviourSliders: SliderSettingsProps[] = [
    {
      title: "Truck",
      value: behaviorNumbers.truck,
      onChange: (event: any, value: number | number[]) => {
        if (typeof value === "number")
          setBehaviourNumbers({ ...behaviorNumbers, truck: round(value, 2) });
      },
      marks: [
        {
          value: 0,
          label: "",
        },
        {
          value: 1,
          label: "",
        },
      ],
      min: 0,
      max: 1,
      step: 0.1,
      disabled: false,
      track: false,
      subtext: "When do trucks charge? Directly at arrival or during night.",
      percentageText: ["Direct", "Night"],
    },
    {
      title: "Bus",
      value: behaviorNumbers.bus,
      onChange: (event: any, value: number | number[]) => {
        if (typeof value === "number")
          setBehaviourNumbers({ ...behaviorNumbers, bus: round(value, 2) });
      },
      marks: [
        {
          value: 0,
          label: "",
        },
        {
          value: 1,
          label: "",
        },
      ],
      min: 0,
      max: 1,
      step: 0.1,
      disabled: false,
      track: false,
      subtext:
        "When do buses charge? Upon reaching end station or during night",
      percentageText: ["Direct", "Night"],
    },
    /*
    {
      title: "Car",
      value: growthNumbers.car,
      onChange: (event: any, value: number | number[]) => {
        if (typeof value === "number")
          setGrowthNumbers({ ...growthNumbers, car: value });
      },
      marks: [
        {
          value: 0,
          label: "Home",
        },
        {
          value: 2,
          label: "Direct",
        },
      ],
      min: 0,
      max: 2,
      step: 0.1,
      disabled: false,
      track: false,
      subtext: "When do cars charge?",
    },*/
  ];
  return { behaviourSliders };
}
