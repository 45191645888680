import { create } from "zustand";

interface DimensionLoadState {
  area: number;
  heating: string;
  ev: boolean;
  pv: boolean;
  type: string;
  smartControlled: boolean;
  Location: [number | undefined, number | undefined];
  setArea: (area: number) => void;
  setHeating: (heating: string) => void;
  setEv: (ev: boolean) => void;
  setPv: (pv: boolean) => void;
  setType: (type: string) => void;
  setSmartControlled: (smartControlled: boolean) => void;
  setLocation: (Location: [number | undefined, number | undefined]) => void;
}

export const useDimensionLoadStore = create<DimensionLoadState>((set) => ({
  area: 0,
  heating: "district_heating",
  ev: false,
  pv: false,
  type: "residential",
  smartControlled: false,
  Location: [undefined, undefined],
  setArea: (area: number) => set({ area }),
  setHeating: (heating: string) => set({ heating }),
  setEv: (ev: boolean) => set({ ev }),
  setPv: (pv: boolean) => set({ pv }),
  setType: (type: string) => set({ type }),
  setSmartControlled: (smartControlled: boolean) => set({ smartControlled }),
  setLocation: (Location: [number | undefined, number | undefined]) => set({ Location }),
}));
