import { Request } from "@/api/request/request";
import { create } from "zustand";

export type AnalysisSettings = {
  groupbyColumn: keyof Request;
  legend: keyof Request;
  histXAxis: keyof Request;
};

type AnalysisSettingsStore = {
  settings: AnalysisSettings;
  setSettings: (settings: AnalysisSettings) => void;
  setGroupbyColumn: (groupbyColumn: keyof Request) => void;
  setLegend: (legend: keyof Request) => void;
  setHistXAxis: (histXAxis: keyof Request) => void;
};

export const useAnalysisSettings = create<AnalysisSettingsStore>((set) => ({
  settings: {
    groupbyColumn: "requested_power",
    legend: "sector",
    histXAxis: "completion_date",
  },
  setSettings: (settings: AnalysisSettings) => set(() => ({ settings })),
  setGroupbyColumn: (groupbyColumn: keyof Request) =>
    set((state) => ({ settings: { ...state.settings, groupbyColumn } })),
  setLegend: (legend: keyof Request) =>
    set((state) => ({ settings: { ...state.settings, legend } })),
  setHistXAxis: (histXAxis: keyof Request) =>
    set((state) => ({ settings: { ...state.settings, histXAxis } })),
}));
