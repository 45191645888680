interface CustomRadioProps {
  label: string;
  name: string;
  value: string | number;
  checkedValue: string | number | boolean;
  svgImg?: any;
  onChange: (e: any) => void;
  className?: React.HTMLAttributes<HTMLDivElement>;
}

const selectedStyle =
  "bg-primary-100 ring-primary-600 text-neutral-800 fill-neutral-800";
const unselectedStyle =
  "bg-neutral-300 ring-neutral-500 text-neutral-700 fill-neutral-700";
// Custom radio button component
export default function CustomRadio(props: CustomRadioProps) {
  const isSelected = props.value === props.checkedValue;

  return (
    <label className={`flex items-center cursor-pointer ${props.className}`}>
      <input
        type="radio"
        name={props.name}
        value={props.value}
        checked={isSelected}
        onChange={props.onChange}
        className="hidden" // Hide the default radio button
      />

      <div
        className={`flex text-xs font-medium rounded-full ring-1 px-2 py-1 m-0 ${
          isSelected ? selectedStyle : unselectedStyle
        }`}
      >
        {props.svgImg ? <props.svgImg className="h-4 w-4" /> : null}
        {props.label}
      </div>
    </label>
  );
}
