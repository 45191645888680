import { Residential } from "@/api/request/residential";
import { Input } from "@/components/Input";
import Dropdown from "@/components/OldDropdown";
import RadioButton from "@/components/RadioButtonSecondary";
import { StatusColor } from "@/components/StatusDot";
import { Local } from "@/pages/requests/helper/api";
import { residentialSubsectors } from "@/pages/requests/stores/sectorStore";
import { useEffect } from "react";
import { EditorProps } from "../types";
import { Container } from "./Container";

type ResidentialColor = Partial<{
  [key in keyof Local<Residential>]: StatusColor | undefined;
}>;

function getColor(residential: Local<Residential>): ResidentialColor {
  const resDwellings = residential.dwellings && residential.dwellings > 0;
  const resDwellingArea =
    residential.dwelling_area && residential.dwelling_area > 0;
  const resEnergyPerformance =
    residential.energy_performance && residential.energy_performance > 0;
  const resSolarPower = residential.solar_power && residential.solar_power > 0;

  return {
    dwellings: resDwellings ? "green" : "red",
    dwelling_area: resDwellingArea ? "green" : "red",
    energy_performance: resEnergyPerformance ? "green" : "yellow",
    solar_power: resSolarPower ? "green" : "yellow",
  };
}

function getStatus(residentialColors: ResidentialColor) {
  const ResidentialComplete =
    Object.keys(residentialColors).length > 0 &&
    residentialColors.dwellings === "green" &&
    residentialColors.dwelling_area === "green";
  return ResidentialComplete;
}

interface ResidentialEditorProps extends EditorProps {
  sector: Local<Residential>;
  setSector: (value: Local<Residential>) => void;
  deleteResidential?: () => void;
  hidden?: boolean;
}

export function ResidentialEditor(props: ResidentialEditorProps) {
  const { sector, setSector, setStatus, className } = props;
  if (!sector || !setSector) return null;

  // Update publics if residential is updated
  /*
    useEffect(() => {
      if (!residential.dwellings) return;
      if (publics.length === 0) return;
  
      const pub = publics[0];
      const publicID = pub.id;
  
      updatePublic(publicID, {
        power_demand: residential.dwellings * 1.5 * 1000,
        use_demand: true,
      });
    }, [residential.dwellings]);*/

  const colors = getColor(sector);
  const status = getStatus(colors);

  useEffect(() => {
    setStatus && setStatus(status);
  }, [status]);
  return (
    <Container className={className}>
      <Dropdown
        className=""
        title="Residential type"
        list={residentialSubsectors}
        selectedValue={sector.subsector}
        onChange={(value) => {
          setSector({
            ...sector,
            subsector: value as Residential["subsector"],
          });
        }}
      />
      <div className="flex justify-between space-x-2">
        <div className="flex space-x-4  ring-neutral-500 rounded-md">
          <Input
            title="Dwellings"
            className="w-20"
            statusColor={colors.dwellings}
            value={sector.dwellings}
            onChange={(value) => {
              setSector({
                ...sector,
                dwellings: Number(value),
              });
            }}
            type="number"
            unit="#"
          />
          <Input
            title="Sqm per Dwelling"
            className="w-28"
            statusColor={colors.dwelling_area}
            value={sector.dwelling_area}
            onChange={(value) => {
              setSector({
                ...sector,
                dwelling_area: Number(value),
              });
            }}
            type="number"
            unit="m²"
          />
        </div>
      </div>
      <Input
        className="w-full"
        title="Energy Performance"
        statusColor={colors.energy_performance}
        value={sector.energy_performance && sector.energy_performance / 1000}
        onChange={(value) => {
          setSector({
            ...sector,
            energy_performance: Number(value) * 1000,
          });
        }}
        unit="kwh/m²/year"
        type="number"
      ></Input>
      <Input
        title="Solar PV capacity per Dwelling"
        className="w-full"
        statusColor={colors.solar_power}
        value={sector.solar_power && sector.solar_power / 1000}
        onChange={(value) => {
          setSector({
            ...sector,
            solar_power: Number(value) * 1000,
          });
        }}
        type="number"
        unit="kW"
      ></Input>

      <div className="text-sm text-neutral-800">
        What type of heat system?
        <div className="flex space-x-6 justify-center mt-2">
          <RadioButton
            selected={!sector.electric_heating}
            onClick={() => {
              setSector({
                ...sector,
                electric_heating: false,
              });
            }}
          >
            Non-electric heating
          </RadioButton>
          <RadioButton
            selected={sector.electric_heating}
            onClick={() => {
              setSector({
                ...sector,
                electric_heating: true,
              });
            }}
          >
            Heatpump
          </RadioButton>
        </div>
      </div>
    </Container>
  );
}
