import { HeaderRadioButtonProps } from "./types";

export function HeaderRadioButtons(props: HeaderRadioButtonProps) {
  const { radioItems, selected, onChange } = props;
  return (
    <div className=" bg-neutral-100 pt-3 rounded-md border-b-[1px] m-2 border-neutral-400 shadow-lg">
      <form className="flex flex-row justify-start w-full px-5 ">
        {radioItems.map((button, index) => {
          const isSelected = selected === button.value;
          return (
            <label
              key={index}
              className={`inline-flex items-center cursor-pointer px-3 font-medium`}
            >
              <input
                type="radio"
                className="hidden" // Hide the default radio button
                name="radio-colors"
                value={button.value || ""} // Handle 'undefined' value properly
                checked={isSelected}
                onChange={onChange}
              />
              <span
                className={`pb-2 ${
                  isSelected ? "border-b-[3px] border-primary-500" : ""
                }`}
              >
                {button.name}
              </span>
            </label>
          );
        })}
      </form>
    </div>
  );
}
