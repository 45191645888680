import { useGetLayerData } from "../api/layerData";
import { addColor } from "../data/Color";
import { useTilesSettingsStore } from "../stores/tilesSettingsStore";
import { Color, Features } from "../types";
import { useSubstationsHandle } from "./substationHook";

interface LayerHookProps {
  columns: string[];
  year: number;
  setSelectedSubstations?: React.Dispatch<React.SetStateAction<string[]>>;
}

interface LayerDataRow {
  station_id: string;
  energy?: number;
  power?: number;
  utilization?: number;
  color?: Color;
}

type LayerData = LayerDataRow[];

export interface GridData {
  power?: LayerData;
  energy?: LayerData;
  utilization?: LayerData;
  flex_energy?: LayerData;
  flex_power?: LayerData;
  flex_hours?: LayerData;
}

function filterGridData(data: LayerData, tiles?: Features | null) {
  if (!tiles) {
    return data;
  }
  let filteredStations = tiles.map((tile) => tile.properties.station_id);
  return data.filter((row) =>
    filteredStations.includes(row.station_id.toString())
  );
}

type EnergyData = ReturnType<typeof useGetLayerData>["energyData"];
type MaxPowerData = ReturnType<typeof useGetLayerData>["maxPowerData"];
type PeakLoadingData = ReturnType<typeof useGetLayerData>["peakLoadingData"];
type FilteredTiles = ReturnType<typeof useSubstationsHandle>["filteredTiles"];

function generateGridData(
  energyData: EnergyData,
  maxPowerData: MaxPowerData,
  peakLoadingData: PeakLoadingData,
  filteredTiles: FilteredTiles
) {
  if (!energyData && !maxPowerData && !peakLoadingData && !filteredTiles) {
    return;
  }
  if (!filteredTiles) {
    return;
  }
  let gridData: GridData = {} as GridData;
  if (energyData && energyData.length > 0) {
    gridData.energy = addColor(filterGridData(energyData, filteredTiles));
  }

  if (maxPowerData && maxPowerData.length > 0) {
    gridData.power = addColor(filterGridData(maxPowerData, filteredTiles));
  }
  if (peakLoadingData && peakLoadingData.length > 0) {
    gridData.utilization = addColor(
      filterGridData(peakLoadingData, filteredTiles),
      true
    );
  }
  return gridData;
}

export function useLayerHook(props: LayerHookProps) {
  const { columns, year, setSelectedSubstations } = props;
  const category = useTilesSettingsStore((state) => state.category);
  const {
    energyData,
    maxPowerData,
    peakLoadingData,
    maxPowerLoading,
    energyLoading,
    gridCapacityLoading,
  } = useGetLayerData(year, undefined, category);

  const { filteredTiles, tiles, substationInfo } = useSubstationsHandle({
    setSelectedSubstations,
  });

  let gridData = generateGridData(
    energyData,
    maxPowerData,
    peakLoadingData,
    filteredTiles
  );

  return {
    gridData,
    substationInfo,
    tiles,
    filteredTiles,
    energyLoading,
    maxPowerLoading,
    gridCapacityLoading,
  };
}
