import { Columns } from "../../types";
export default function histChartEvents() {}

export const detectLegendChange = (params: any, setSelectedLegends: any) => {
  setSelectedLegends(
    Object.keys(params.selected).filter((key) => {
      if (key === "Substation Capacity") return false;
      return params.selected[key];
    })
  );
};

export const setZoom = (
  start: number | undefined,
  end: number | undefined,
  setDurZoom: any,
  setHourZoom: any,
  durVHour: string,
  chartRef: any
) => {
  if (start === undefined || end === undefined) {
    return;
  }
  if (durVHour === "Duration") {
    setDurZoom([start, end]);
    return;
  } /*
  const chart = chartRef.current.getEchartsInstance();
  if (end - start < 15) {
    chart.dispatchAction({
      type: "legendSelect",
      name: "Total",
    });
  } else {
    chart.dispatchAction({
      type: "legendUnSelect",
      name: "Total",
    });
  }*/
  setHourZoom([start, end]);
};

const productionColumns: (keyof Columns)[] = ["Solar PV"];
const consumptionColumns: (keyof Columns)[] = [
  "Heating",
  "Housing",
  "Industry",
  "Car",
  "Public Charging",
  "Home Charging",
  "Truck",
  "Bus",
  "Base",
  "HV Loads",
  "Requests",
];
export const selectLegend = (legend: string, chartRef: any) => {
  if (chartRef.current === null) {
    return;
  }
  const chart = chartRef.current.getEchartsInstance();

  chart.dispatchAction({
    type: "legendAllSelect",
  });
  if (legend === "netto") {
    return;
  }
  let legends: string[] = chart.getOption().legend[0].data;

  chart.dispatchAction({
    type: "legendInverseSelect",
    name: legend,
  });
  let columns =
    legend === "production" ? productionColumns : consumptionColumns;
  columns = columns.filter((column: string) => legends.includes(column));
  for (const leg of columns) {
    chart.dispatchAction({
      type: "legendSelect",
      name: leg,
    });
  }
};
