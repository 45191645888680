import Checkbox from "@/components/Checkbox";
import Dropdown from "@/components/OldDropdown";
import RoundedContainer from "@/components/rounded-container";
import { useHierarchyStore } from "@/features/Map/stores/levelStore";
import { useLocationStore } from "@/stores";
import { useApiStore } from "@/stores/apiStore";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

const api_url = import.meta.env.VITE_API_URL as string;
const dev_api_url = import.meta.env.VITE_DEV_API_URL as string;
const LOCATIONS = [
  "Varberg",
  "Halmstad",
  "Mölndal",
  "Vimmerby",
  "Arvika",
  "Karlstad",
  "Mölndal demo",
];
export function Admin() {
  const { api, setApi } = useApiStore();
  const { setTopStation } = useHierarchyStore();
  const { location, setLocation } = useLocationStore();
  const [checked, setChecked] = useState(api.toString() === dev_api_url);
  const { user } = useAuth0();
  return (
    <>
      <RoundedContainer classNameOuter="w-80">
        <div className="font-bold text-xl">Settings</div>
      </RoundedContainer>
      <RoundedContainer classNameOuter="w-80">
        <div className="my-4">
          <Checkbox
            checked={checked}
            onChange={(checked) => {
              setChecked(checked);
              setApi(checked ? dev_api_url : api_url);
            }}
          >
            <div className="ml-1 ">Use development api</div>
          </Checkbox>
        </div>
        <div className="my-4">
          Set location
          <Dropdown
            className=" text-neutral-900 font-medium w-3/4"
            list={(user!.locs as number[]).map((loc, index) => ({
              name: LOCATIONS[loc],
              value: loc.toString(),
            }))}
            selectedValue={location?.toString()}
            onChange={(loc) => {
              setLocation(parseInt(loc));
              setTopStation([]);
            }}
          ></Dropdown>
        </div>
      </RoundedContainer>
    </>
  );
}
