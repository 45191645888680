import { Listbox, Transition } from "@headlessui/react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { DropdownProps, listItem } from "./types";

const SIZES = {
  small: "px-1 h-7 w-20 font-normal",
  medium: "px-1 h-7 w-30 font-normal",
  large: "px-1 h-9 w-40",
};

const SIZES_TEXT = {
  small: "text-xs",
  medium: "text-sm",
  large: "text-base",
};

/** @deprecated Use new Dropdown instead. compontents/Dropdown */
export function OldDropdown(props: DropdownProps) {
  const { list, className, selectedValue, onChange, disabled, size, title } =
    props;
  const listObject: { [name: string]: listItem } = {};
  list.forEach((item) => {
    listObject[item.value] = item;
  });
  let size_: DropdownProps["size"] = "medium";
  if (size) size_ = size;
  return (
    <div className="">
      <div
        className={twMerge("text-sm pb-1 text-neutral-700", SIZES_TEXT[size_])}
      >
        {title}
      </div>
      <Listbox value={selectedValue} onChange={onChange}>
        <div className={twMerge("relative w-full", className)}>
          <Listbox.Button
            className={twMerge(
              "relative w-full h-7 cursor-default rounded-md bg-neutral-100 ring-1 ring-neutral-200 py-1 px-2 pr-10 text-left shadow-sm ",
              "focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300",
              SIZES_TEXT[size_]
            )}
            aria-disabled={disabled}
          >
            <span className="block truncate text-neutral-800">
              {listObject[selectedValue] ? listObject[selectedValue].name : ""}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 ">
              <UnfoldMoreIcon style={{ color: "#949494", height: "1.2rem" }} />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={twMerge(
                "absolute mt-1 z-20 max-h-60 w-full overflow-auto rounded-md bg-white py-0 shadow-lg ring-1 ring-black/5",
                "focus:outline-none",
                SIZES_TEXT[size_]
              )}
            >
              {list.map((item, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-2 pr-4 ${
                      active
                        ? "bg-primary-100 text-primary-600"
                        : "text-gray-900"
                    }`
                  }
                  value={item.value}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {item.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
