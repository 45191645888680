/// <reference types="vite-plugin-svgr/client" />
import ArrowDown from "@/assets/Arrow Down.svg?react";
import ArrowUp from "@/assets/Arrow up.svg?react";
import { LoadingSymbol } from "@/components/LoadingSymbol";
import RoundedContainer from "@/components/rounded-container";
import { suffixFormatter } from "@/utils/suffixFormatter";
import { abs } from "mathjs";
import { useHandleData } from "../hooks/handleData";

interface YearlyGrowthProps {
  location: number;
  year: number;
}

function ChangeComponent(props: {
  value: number | null;
  suffix?: string;
  decimals?: number;
  invertedColor?: boolean;
}) {
  let { value, suffix, decimals, invertedColor } = props;
  if (!suffix) {
    suffix = "";
  }
  if (!decimals) {
    decimals = 0;
  }
  if (!invertedColor) {
    invertedColor = false;
  }

  if (value === null) {
    return <></>;
  }
  let color = !invertedColor
    ? "text-green-500 fill-green-500 bg-green-400"
    : "text-red-500 fill-red-500 bg-red-400";
  let colorInverse = !invertedColor
    ? "text-red-500 fill-red-500 bg-red-400"
    : "text-green-500 fill-green-500 bg-green-400";
  if (value > 0) {
    return (
      <span className={`flex text-xs ${color} rounded-lg  px-1 mx-2 p-1`}>
        <ArrowUp className="h-4" />
        {value.toFixed(decimals)}
        {suffix}
      </span>
    );
  } else if (value < 0) {
    return (
      <span className={`flex text-xs rounded-lg ${colorInverse} px-1 mx-2 p-1`}>
        <ArrowDown className="h-4" />
        {abs(value).toFixed(decimals)}
        {suffix}
      </span>
    );
  } else {
    return <></>;
  }
}

export function YearlyGrowth(props: YearlyGrowthProps) {
  const { location, year } = props;
  const { overloads, utilizationRate, energy, loading } = useHandleData(year);
  return (
    <div className="flex flex-col ">
      <RoundedContainer classNameOuter="grow" className="">
        <div className="font-medium pb-4 pt-2">Yearly growth</div>
        <div className="flex flex-row justify-between my-2 text-neutral-600">
          <div className="text-xs font-medium ">
            (
            {energy &&
              suffixFormatter({
                value: energy,
                type: "suffix",
              })}
            Wh)
          </div>
          <div className="text-xs">Hourly power</div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row justify-start items-center">
            <div className="text-4xl font-medium">
              {energy && !loading ? (
                suffixFormatter({ value: energy, type: "value" })
              ) : (
                <LoadingSymbol />
              )}
            </div>
          </div>
          <div className="flex flex-row justify-start items-center">
            <div className="text-4xl font-medium">-</div>
            <ChangeComponent value={0} suffix="%" decimals={2} />
          </div>
        </div>
      </RoundedContainer>
      <RoundedContainer classNameOuter="grow">
        <div className="font-medium pb-4 pt-2">Transformers</div>
        <div className="flex flex-row justify-between my-2 text-neutral-700">
          <div className="text-xs">Utilization rate</div>
          <div className="text-xs">Number of Overloaded</div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row justify-start items-center">
            <div className="text-4xl font-medium flex">
              {utilizationRate && !loading ? (
                utilizationRate.toFixed(2)
              ) : (
                <LoadingSymbol />
              )}
              %
            </div>
          </div>
          <div className="flex flex-row justify-start items-center">
            <div className="text-4xl font-medium flex">
              {overloads && !loading ? overloads : <LoadingSymbol />}st
            </div>
          </div>
        </div>
      </RoundedContainer>
    </div>
  );
}
