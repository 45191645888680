import { SliderSettingsProps } from "@/components/SliderSettings";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { LowToHighMarks } from "../Marks";

export function useGrowthSliders() {
  const { growthNumbers, setGrowthNumbers } = useSettingsStore();
  const growthSliders: SliderSettingsProps[] = [
    {
      title: "Car",
      value: growthNumbers.car,
      onChange: (event: any, value: number | number[]) => {
        if (typeof value === "number")
          setGrowthNumbers({ ...growthNumbers, car: value });
      },
      marks: LowToHighMarks,
      min: 0,
      max: LowToHighMarks.length - 1,
      step: 1,
      disabled: false,
      subtext: "Growth of personal vehicle electrification",
    },
    {
      title: "Truck",
      value: growthNumbers.truck,
      onChange: (event: any, value: number | number[]) => {
        if (typeof value === "number")
          setGrowthNumbers({ ...growthNumbers, truck: value });
      },
      marks: LowToHighMarks,
      min: 0,
      max: LowToHighMarks.length - 1,
      step: 1,
      disabled: false,
      subtext: "Growth of truck electrification",
    },
    {
      title: "Bus",
      value: growthNumbers.bus,
      onChange: (event: any, value: number | number[]) => {
        if (typeof value === "number")
          setGrowthNumbers({ ...growthNumbers, bus: value });
      },
      marks: LowToHighMarks,
      min: 0,
      max: LowToHighMarks.length - 1,
      step: 1,
      disabled: false,
      subtext: "Growth of Bus electrification",
    },
    {
      title: "Solar PV",
      value: growthNumbers.solar,
      onChange: (event: any, value: number | number[]) => {
        if (typeof value === "number")
          setGrowthNumbers({ ...growthNumbers, solar: value });
      },
      marks: LowToHighMarks,
      min: 0,
      max: LowToHighMarks.length - 1,
      step: 1,
      disabled: false,
      subtext: "Growth in distributed solar PV installations",
    },
    {
      title: "District Heating",
      marks: LowToHighMarks,
      min: 0,
      max: LowToHighMarks.length - 1,
      step: 1,
      defaultValue: 1,
      track: false,
      disabled: true,
      subtext: "Number of of district heating units",
    },
  ];
  return { growthSliders };
}
