import { Commercial } from "@/api/request/commercial";
import { Industry } from "@/api/request/industry";
import { Public } from "@/api/request/public";
import { Residential } from "@/api/request/residential";
import Tabs from "@/components/Tabs";
import { RequestGraph } from "@/features/RequestGraph";
import { Local } from "@/pages/requests/helper/api";
import { Divider } from "@mui/material";
import { ComponentProps, memo, useState } from "react";

type Sectors = ComponentProps<typeof RequestGraph>["sectors"];

export const SectorCard = memo(
  (props: { children: React.ReactNode; sectors: Sectors }) => {
    const { sectors, children } = props;
    return (
      <div className="bg-neutral-100 rounded-b-md flex ring-1 ring-neutral-400 shadow-md">
        {children}
        <Divider orientation="vertical" flexItem />
        <div className="grow">
          <RequestGraph
            className="shadow-none rounded-none pl-4 ring-0 w-7/8"
            sectors={sectors}
            height="21rem"
          ></RequestGraph>
        </div>
      </div>
    );
  },
  (prev, next) => {
    return prev.sectors === next.sectors;
  }
);

type Sector = Industry | Commercial | Residential | Public;

interface ISectorTabsProps<T> {
  SectorComponent: React.ComponentType<{
    className: string;
    sector: Local<T>;
    setSector: (value: Local<T>) => void;
    setStatus?: (value: boolean) => void;
  }>;
  sectorName?: "industry" | "commercial" | "residential" | "public";
  sectors: Local<T>[];
  setSectors: (id: any, value: Partial<Local<T>>) => void;
  addSector?: () => any;
  deleteSector?: (id: string | number) => void;
}

export const SectorTabs = memo(
  <T extends Sector>(props: ISectorTabsProps<T>) => {
    const {
      sectorName,
      SectorComponent,
      sectors,
      setSectors,
      addSector,
      deleteSector,
    } = props;
    if (sectors.length === 0) return null;
    const [activeTab, setActiveTab] = useState(sectors[0].id_.toString());

    return (
      <Tabs
        className="w-full mx-auto"
        activeTab={activeTab.toString()}
        onTabChange={(id) => setActiveTab(id)}
        onAddTab={() => {
          const newId = addSector?.();
          setActiveTab(newId);
        }}
        onRemoveTab={(id) => {
          deleteSector?.(parseInt(id) ? parseInt(id) : id);
          if (activeTab === id) {
            setActiveTab(sectors[0].id_.toString());
          }
        }}
      >
        <Tabs.List showAddButton={sectors.length < 15}>
          {sectors.map((sector, i) => (
            <Tabs.Tab id={sector.id_.toString()} closeable={sectors.length > 1}>
              {i + 1}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {sectors.map((sector, i) => {
          let sectorCardSector: ComponentProps<typeof RequestGraph>["sectors"] =
            {};
          if (sectorName) {
            sectorCardSector[sectorName] = [sector] as Sectors[keyof Sectors];
          }
          return (
            <Tabs.Panel id={sector.id_.toString()} className="p-0">
              <SectorCard key={sector.id_} sectors={sectorCardSector}>
                <SectorComponent
                  className="rounded-t-none"
                  sector={sector}
                  setSector={(value) => setSectors(sector.id_, value)}
                />
              </SectorCard>
            </Tabs.Panel>
          );
        })}
      </Tabs>
    );
  }
) as <T>(props: ISectorTabsProps<T>) => JSX.Element;
