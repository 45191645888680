import { RequestColumns } from "./d";

export const Columns: RequestColumns = [
  { value: "id", label: "ID", unit: "" },
  { value: "sector", label: "Sector", unit: "" },
  { value: "start_date", label: "Start Date", unit: "" },
  { value: "completion_date", label: "Completion Date", unit: "" },
  {
    value: "gross_area",
    label: "Building Area (BYA)",
    unit: "m²",
    serversideSuffix: 1,
  },
  {
    value: "requested_power",
    label: "Requested Power (W)",
    unit: "W",
    serversideSuffix: 1000,
  },
  {
    value: "estimated_power",
    label: "Estimated Power (W)",
    unit: "W",
    serversideSuffix: 1000,
  },
  { value: "latitude", label: "Latitude", unit: "" },
  { value: "longitude", label: "Longitude", unit: "" },
  { value: "substation", label: "Substation", unit: "" },
  { value: "address", label: "Address", unit: "" },
  {
    value: "solar_panels",
    label: "Solar Panels",
    unit: "",
    serversideSuffix: 1,
  },
  {
    value: "charging_stations",
    label: "Charging Stations",
    unit: "",
    serversideSuffix: 1,
  },
  { value: "quantity", label: "Quantity", unit: "", serversideSuffix: 1 },
  //{ value: "type", label: "Type", unit: "" },
];

interface ColumnMap {
  label: string;
  unit: string;
  serversideSuffix: number | undefined;
}

// Export columns but as a dict mapping from value to label
const ColumnDict: { [key: string]: ColumnMap } = {};

Columns.forEach((col) => {
  ColumnDict[col.value] = {
    label: col.label,
    unit: col.unit,
    serversideSuffix: col.serversideSuffix,
  };
});

export { ColumnDict };
