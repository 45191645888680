export type ID = number | string;
export type Status = "new" | "modified" | "synced" | "deleted";
export type LocalBase = { id_: ID; status_: Status }; // Change id to id_ to fix zustand error in sectorStore
export type Local<T> = Omit<T, "id"> & LocalBase;

type OmittedLocal<T> = Omit<T, "status_" | "id_"> & { id: number };

export function omitLocal<T extends LocalBase>(data: T): OmittedLocal<T> {
  const { id_, status_, ...rest } = data;
  return { ...rest, id: typeof id_ === "string" ? -1 : id_ };
}

export function toLocal<T extends { id: number }>(data: T): Local<T> {
  const { id, ...rest } = data;
  return { ...rest, id_: id, status_: "synced" };
}

export function toLocalArray<T extends { id: number }>(data: T[]): Local<T>[] {
  return data.map(toLocal);
}
