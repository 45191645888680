import { BarSeriesOption, LineSeriesOption } from "echarts";

type SeriesOption = BarSeriesOption | LineSeriesOption;

export function addSubstation(
  series: SeriesOption[],
  transformerCapacity: number
): (BarSeriesOption | LineSeriesOption)[] {
  const seriesCopy: SeriesOption[] = JSON.parse(JSON.stringify(series));
  const dataLength = (seriesCopy[0].data as any[]).length;
  let data = new Array(dataLength + 2);
  for (let i = 0; i < dataLength + 2; i++) {
    data[i] = [i - 1, transformerCapacity];
  }
  seriesCopy.push({
    name: "Substation Capacity",
    stack: "alone",
    type: "line",
    smooth: true,
    showSymbol: false,
    symbol: undefined,
    lineStyle: {
      color: "red",

      width: 2,
      type: "solid",
    },
    emphasis: {
      focus: "series",
    },
    itemStyle: {
      color: "transparent",
    },

    data: data, // This makes the line hit the chart edges
  });
  return seriesCopy;
}
