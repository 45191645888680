import { create } from "zustand";

interface LocationsState {
  locations: string[];
  setLocations: (locations: string[]) => void;
}

const LocationsStore = create<LocationsState>((set) => ({
  locations: [],
  setLocations: (locations: string[]) => set({ locations }),
}));

export default LocationsStore;
