function sumCombined(item: CombinedDataItem, excludeKeys: string[]): number {
  return Object.keys(item).reduce((acc, key) => {
    if (excludeKeys.includes(key)) return acc;
    if (typeof item[key] !== "number") return acc;
    return acc + (item[key] as number);
  }, 0);
}

type GenericObject = { [key: string]: number[] | string[] };

type CombinedDataItem = { [key: string]: number | string };

export function sortOnTotal<T extends GenericObject>(
  data: T,
  excludeKeys: string[] = ["time"]
): T {
  // Combine
  const keys = Object.keys(data);
  const combined = data[keys[0]].reduce(
    (acc: CombinedDataItem[], _, index: number) => {
      const obj = keys.reduce((d: CombinedDataItem, key) => {
        d[key] = data[key][index];
        return d;
      }, {});
      acc.push(obj);
      return acc;
    },
    []
  );

  // Sort
  combined.sort(
    (a, b) => sumCombined(b, excludeKeys) - sumCombined(a, excludeKeys)
  );

  // Separate the combined array back
  const sortedData: T = keys.reduce((acc: any, key) => {
    // @ts-ignore
    acc[key] = combined.map((obj) => obj[key]);
    return acc;
  }, {});
  return sortedData;
}
