import { Alert, AlertProps, Snackbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";

import { Request, useUpdateRequest } from "@/api/request/request";
import useRequestTrigger from "@/hooks/requestTrigger";
import { Local, omitLocal } from "@/pages/requests/helper/api";
import { useRequests } from "@/pages/requests/hooks/requests";
import { useRequestStore } from "@/pages/requests/stores/requestsStore";
import { columns } from "../columns";
import { CustomGridToolbar } from "./CustomGridToolbar";

interface DataGridProps {}
export function DataGrid_(props: DataGridProps) {
  const { requests } = useRequestStore();
  const { refreshData } = useRequests();
  const { updateRequest, loading } = useUpdateRequest();
  const [rows, setRows] = useState<Local<Request>[]>([]);
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const { requestTrigger, setRequestTrigger } = useRequestTrigger();

  useEffect(() => {
    if (requests) {
      setRows(requests.filter((r) => r.status !== "new"));
    }
  }, [requests]);

  useEffect(() => {
    refreshData();
  }, [requestTrigger]);

  const processRowUpdate = useCallback(
    async (newRow: Local<Request>, oldRow: Local<Request>) => {
      if (
        Object.keys(newRow).every(
          (key) =>
            newRow[key as keyof Local<Request>] ===
            oldRow[key as keyof Local<Request>]
        )
      ) {
        return newRow;
      }
      // check if any newrow field are null that were not null in oldRow
      let err;
      if (typeof newRow["substation"] === "string") {
        // convert to Array, for example "1,3,6" should be [1,3,6]
        newRow["substation"] = newRow["substation"].split(",");
      }
      const response = await updateRequest(omitLocal(newRow)).catch((error) => {
        err = error.response.data.detail;
      });
      if (err || !response) {
        setSnackbar({ children: "Error: " + err, severity: "error" });
        return newRow;
      }
      setSnackbar({
        children: "Row successfully updated",
        severity: "success",
      });
      setRequestTrigger();
      return newRow;
    },
    []
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);
  return (
    <div className="h-full">
      <DataGrid
        editMode="row"
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id_}
        checkboxSelection
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        slots={{ toolbar: CustomGridToolbar }}
        autoHeight={false}
        sx={{ height: "calc(100vh - 6rem)" }}
      ></DataGrid>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}
