import { rawData } from "@/pages/forecast/features/LoadFigure/types";
import { sortOnTotal } from "@/utils/sortOnTotal";
import { LineSeriesOption } from "echarts";
import { BaseColumns } from "./types";

function formatDate(date: string) {
  return new Date(date).toString().slice(0, 25);
}

interface formatOptionDataParams<T> {
  rawData: T;
  graphType: "Duration" | "Hourly";
  getColor?: (key: keyof T) => string;
}

export interface OptionData {
  series: LineSeriesOption[];
  xAxis: LineSeriesOption["data"];
}

export function formatOptionData<T extends BaseColumns>(
  data: formatOptionDataParams<T>
) {
  const { rawData, graphType, getColor } = data;
  // Your formatting logic here
  const processedData: OptionData = { xAxis: [], series: [] };
  if (!rawData || Object.keys(rawData).length === 0) {
    return processedData;
  }
  let rawDataCopy = { ...rawData };
  if (graphType === "Duration") {
    rawDataCopy = sortOnTotal(rawDataCopy, ["time"]);
  }
  //
  let stackingStrategy: "samesign" | "positive" | "all" = "samesign";
  processedData.xAxis = rawDataCopy.time.map((date: string) => {
    return formatDate(date);
  });
  let totalSeriesData: {
    value: [number | string | Date, number];
    name: string;
  }[] = [];
  if (graphType !== "Duration") {
    totalSeriesData = rawDataCopy.time.map((time: string) => {
      return { value: [time, 0], name: formatDate(time) };
    });
  }
  if (graphType === "Duration") {
    // format xAxis as range or 0 to length
    processedData.xAxis = rawDataCopy.time.map((_: string, i: number) => {
      return i.toString();
    });
    stackingStrategy = "all";
  }

  for (let key_string of Object.keys(rawDataCopy)) {
    const key = key_string as keyof typeof rawDataCopy;
    if (key === "time") {
      continue;
    }

    processedData.series.push({
      name: key as string,
      type: "line",
      stack: "Total",
      smooth: true,
      symbol: "none",
      sampling: "lttb",
      stackStrategy: stackingStrategy,
      progressive: 500,
      progressiveThreshold: 1000,
      animation: true,
      areaStyle: {
        color:
          getColor !== undefined ? getColor(key as keyof rawData) : "#0090CF",
      },
      lineStyle: {
        color: getColor ? getColor(key as keyof rawData) : "#0090CF",
        width: 0,
      },
      emphasis: {
        focus: "series",
      },
      data: rawDataCopy[key].map((columnValue: number, i: number) => {
        let xValue: number | Date;

        if (graphType === "Duration") {
          xValue = i;
        } else {
          xValue = new Date(rawDataCopy.time[i]);
          totalSeriesData[i].name = formatDate(rawDataCopy.time[i]);
          totalSeriesData[i].value[0] = xValue;
          totalSeriesData[i].value[1] += columnValue;
        }

        return {
          value: [xValue, columnValue],
          name: formatDate(rawDataCopy.time[i]),
        };
      }),
    });
  }
  /*
  if (graphType !== "Duration") {
    // Add the total series
    processedData.series.push({
      name: "Total",
      type: "line",
      stack: "self",
      symbol: "none",
      smooth: true,
      emphasis: {
        //disabled: true,
        focus: "series",
      },
      symbolSize: 0,
      itemStyle: {
        color: "#242424",
      },
      lineStyle: {
        color: "#242424",
        opacity: 0.6,
      },
      data: totalSeriesData,
    });
  }
  */
  // Sort the series by name.
  // This is in order to ensure it can be referenced
  // easily with other charts
  //processedData.series.sort(SeriesSort);

  return processedData;
}
