import { useGetGridInfo, useGetLocation, useGetTiles } from "@/api/location";
import { GEOJSON } from "@/features/Map/types";
import { useEffect, useState } from "react";

export function useMap() {
  const { data: tiles, loading: tilesLoading } = useGetTiles();
  const { data: gridInfo, loading: gridLoading } = useGetGridInfo();
  const { data: centroid } = useGetLocation();
  const [filteredTiles, setFilteredTiles] = useState<GEOJSON | null>(null);

  useEffect(() => {
    if (tiles && gridInfo) {
      const maxLevel = Math.max(...gridInfo.level);
      let filteredGrid = gridInfo.station_id.filter(
        (station_id, id) => gridInfo.level[id] === maxLevel
      );
      const filteredTiles: GEOJSON = {
        ...tiles,
        features: tiles.features.filter((tile) =>
          filteredGrid.includes(tile.properties.station_id)
        ),
      };
      setFilteredTiles(filteredTiles);
    }
  }, [tiles, gridInfo]);

  return {
    filteredTiles,
    centroid,
    stationIds: gridInfo?.station_id,
    loading: tilesLoading || gridLoading,
  };
}
