import { useApi } from "@/services/Api";
import { useGenericGet } from "./generic";
import { Sector } from "./request";

export interface Public extends Sector {
  power_demand?: number;
  area?: number;
  forecast_metric?: "demand" | "area";
  energy_performance: number;
}
export function useUpdatePublic() {
  const { loading, fetchData } = useApi("PUT");
  async function updatePublic(newRow: Public) {
    return fetchData("request/public/", newRow).then((data) => {
      return data;
    });
  }
  return { loading, updatePublic };
}

export function useInsertPublic() {
  const { loading, fetchData } = useApi("POST");
  async function insertPublic(newRow: Public) {
    return fetchData("request/public/", newRow);
  }
  return { loading, insertPublic };
}

export function useDeletePublic() {
  const { loading, fetchData } = useApi("DELETE");
  async function deletePublic(id: number) {
    return fetchData("request/public/" + id);
  }
  return { loading, deletePublic };
}

export function useGetPublics(request_id: number) {
  let endpoint = "request/public/" + request_id;
  return useGenericGet<Public[]>(endpoint);
}
