import { twMerge } from "tailwind-merge";

export interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  className?: string;
  selected?: boolean;
}
const selectedColor = "bg-primary-400 text-primary-500";
const neutralColor =
  "bg-neutral-300 ring-neutral-500 text-neutral-700 fill-neutral-700 hover:bg-primary-10";

export function Button(props: ButtonProps) {
  const { children, onClick, disabled, type, className, selected } = props;
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={twMerge(
        "flex text-xs font-medium rounded-full ring-1 px-2 py-1 m-0",
        selected ? selectedColor : neutralColor,
        className
      )}
    >
      {children}
    </button>
  );
}
