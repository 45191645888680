import { suffixFormatter } from "@/utils/suffixFormatter";
import { EChartsOption, LineSeriesOption } from "echarts";
import { TooltipFormatterParams } from "../../types";

function yAxisFormatter(val: number) {
  return suffixFormatter({ value: val, precision: 2 }) + "Wh/h";
}
function tooltipFormatter(params: TooltipFormatterParams[]) {
  const subParam = params.find(
    (param) => param.seriesName === "Substation Capacity"
  );
  return `
    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${
      params[0].name
    }</span>
    <br>
    ${
      params[0].axisValue.toString().length > 4
        ? "<br>"
        : "<span style='font-size:14px;color:#666;font-weight:400;margin-left:2px'>The " +
          params[0].axisValue +
          "th worst hour</span><br><br>"
    }
    
    ${params
      .map((param) => {
        if (
          param.seriesName === "Substation Capacity" ||
          param.seriesName === "Total"
        ) {
          return;
        }
        if (!param.value || param.value[1] === null) {
          return;
        }
        return `${param.marker}
      <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${
        param.seriesName
      }</span>
      <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${
        suffixFormatter({ value: param.value[1] }) + "Wh/h"
      }</span>`;
      })
      .join("<br>")}<br><br>
      ${
        subParam
          ? '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' +
            subParam.seriesName +
            "</span>" +
            '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
            suffixFormatter({ value: subParam.value[1] }) +
            "Wh/h</span>"
          : ""
      }
      <br>
    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">
    Total consumption
    </span>
    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
    ${
      suffixFormatter({
        value: params.reduce((n, { value, seriesName }) => {
          if (seriesName == "Substation Capacity" || seriesName == "Total")
            return n;
          return n + Math.max(value[1], 0);
        }, 0),
      }) + "Wh/h"
    }
    </span>
    <br>
    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">
    Total production
    </span>
    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
    ${
      suffixFormatter({
        value: params.reduce((n, { value, seriesName }) => {
          if (seriesName == "Substation Capacity") return n;
          return n + Math.min(value[1], 0);
        }, 0),
      }) + "Wh/h"
    }
    </span>`;
}

export interface optionProps {
  xAxis: string[] | number[];
  series: LineSeriesOption[];
}

export function createOption(
  props: optionProps | null,
  zoom: [number, number],
  durVHour: string,
  selectedDataColumn: string,
  selectedLegends: { [key: string]: boolean } | undefined
) {
  if (!props || !props.xAxis) {
    return DEFAULT_OPTION;
  }

  const newOption = JSON.parse(JSON.stringify(DEFAULT_OPTION));

  newOption.xAxis[0].show = props.xAxis.length > 0;
  newOption.yAxis[0].show = props.xAxis.length > 0;
  newOption.dataZoom[0].show = props.xAxis.length > 0;
  newOption.title.show = props.series.length === 0;

  newOption.series = props.series;
  newOption.xAxis[0].data = props.xAxis;
  newOption.color = props.series.map((series) => series.areaStyle?.color);

  // updating formatters to ensure they work correctly
  newOption.yAxis[0].axisLabel.formatter = yAxisFormatter;
  newOption.tooltip.formatter = tooltipFormatter;

  const documentWidth = document.documentElement.clientWidth;
  newOption.grid = {
    left: Math.round(documentWidth * 0.05),
    right: Math.round(documentWidth * 0.08) + 25,
    bottom: Math.round(documentWidth * 0.04) + 20,
    top: Math.round(documentWidth * 0.02),
  };
  newOption.dataZoom[0].height = Math.round(documentWidth * 0.01) + 20;
  newOption.dataZoom[0].start = zoom[0];
  newOption.dataZoom[0].end = zoom[1];
  if (zoom[1] - zoom[0] >= 15) {
  }
  newOption.legend.data = props.series.map((series) => {
    return series.name;
  });
  if (durVHour === "Duration") {
    newOption.xAxis[0].type = "category";
  }
  newOption.legend.selected = {
    "Substation Capacity": selectedDataColumn === "Total",
    ...selectedLegends,
  };
  return newOption;
}
const DEFAULT_OPTION: EChartsOption = {
  title: {
    show: true,
    textStyle: {
      color: "grey",
      fontSize: 20,
    },
    text: "Press on a year to see the forecast",
    left: "center",
    top: "center",
  },

  // ...

  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },

  legend: {
    top: "center",
    right: "right",
    textStyle: {
      fontSize: "0.75rem",
    },
  },
  xAxis: [
    {
      show: false,
      type: "time",
      axisTick: { show: true },
      axisLabel: {
        fontSize: "0.75rem",
        hideOverlap: true,
      },
    },
  ],
  yAxis: [
    {
      show: false,
      type: "value",
      axisLabel: {
        fontSize: "0.75rem",
      },
    },
  ],
  dataZoom: [
    {
      show: false,
      bottom: 20,
    },
    {
      type: "inside",
      show: true,
      bottom: 20,
    },
  ],
  animation: true,
  series: [] as LineSeriesOption[],
};
