//
import RoundedContainer from "@/components/rounded-container";
import { DistanceChart } from "./DistanceChart";
import { getTruckData, TrucksData } from "../api/getTruckData";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NTripsChart } from "./NTripsChart";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@/components/Tooltip";
import { useLocationStore } from "@/stores";

export interface TrucksTabProps {
  selectedSubstations: number[];
}

const TRUCK_TOOLTIP =
  "Truck forecasts and anayltics are based on current driving data of trucks. The data contains trips covered by individual trucks, including where they left from and where they went to. These datasets therefore cover potential charge points (where trips end), and charge demand (how much they are expected to charge).";

export function TrucksTab(props: TrucksTabProps) {
  const { selectedSubstations } = props;
  const [truckData, setTruckData] = useState<TrucksData>();
  const { getAccessTokenSilently, user } = useAuth0();
  const { location } = useLocationStore();
  useEffect(() => {
    async function af() {
      const token = await getAccessTokenSilently();
      const data = await getTruckData(token, location, selectedSubstations);
      setTruckData(data);
    }
    af();
  }, [selectedSubstations, location]);

  return (
    <>
      <RoundedContainer classNameOuter="h-30">
        <div className="text-sm font-thin w-3/4">
          Truck forecasts and anayltics are based on current driving data of
          trucks. The data contains trips covered by individual trucks,
          including where they left from and where they went to. These datasets
          therefore cover potential charge points (where trips end), and charge
          demand (how much they are expected to charge).
          <br />
          <br /> The categories are are defined as follows: Short (&lt;50km)
          Medium (50-200km), Long (&gt;200km)
        </div>
      </RoundedContainer>
      <RoundedContainer classNameOuter="grow">
        <div className="flex justify-between">
          <div className="text-sm font-thin w-3/4">
            The total distance traveled of all truck fleets in a specified area,
            per day.
          </div>
          <div
            id="tooltip-default"
            role="tooltip"
            className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-neutral-900 transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
          >
            Tooltip content
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
        </div>
        <DistanceChart data={truckData} />
      </RoundedContainer>
      <RoundedContainer classNameOuter="grow" className="">
        <div className="flex flex-col justify-between">
          <div className="text-sm font-thin w-3/4">
            The truck fleet size in a specified area, per day.
          </div>
        

        <NTripsChart data={truckData} />
        </div>
      </RoundedContainer>
    </>
  );
}
