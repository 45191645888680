import { Commercial } from "@/api/request/commercial";
import { Industry } from "@/api/request/industry";
import { Public } from "@/api/request/public";
import { Sector } from "@/api/request/request";
import { Residential } from "@/api/request/residential";
import { create } from "zustand";
import { ID, Local } from "../helper/api";

export const SectorTemplate: Local<Sector> = {
  id_: -1,
  request_id: undefined,
  name: "",
  subsector: "",
  created_ts: "",
  updated_ts: "",
  creator_id: "",
  updater_id: "",
  status_: "new",
};

interface IndustrySubsector {
  value: Industry["subsector"];
  name: string;
}

export const industrySubsectors: IndustrySubsector[] = [
  { value: "generic", name: "Generic Industry" },
  { value: "warehouse", name: "Warehouse" },
];

export const IndustryTemplate: Local<Industry> = {
  ...SectorTemplate,
  subsector: "generic",
  area: undefined,
  power_demand: undefined,
  use_demand: false,
  ev_stations: false,
};

interface ResidentialSubsector {
  value: Residential["subsector"];
  name: string;
}
export const residentialSubsectors: ResidentialSubsector[] = [
  { value: "single_family", name: "Single-family home" },
  { value: "multi_family", name: "Multifamily residential" },
];

export const ResidentialTemplate: Local<Residential> = {
  ...SectorTemplate,
  area: undefined,
  forecast_metric: "dwellings",
  electric_heating: false,
  dwelling_area: undefined,
  dwellings: undefined,
  energy_performance: 48 * 1000,
  solar_power: 9 * 1000,
  subsector: "single_family",
};

export const CommercialTemplate: Local<Commercial> = {
  area: undefined,
  forecast_metric: "area",
  energy_performance: 0,
  ...SectorTemplate,
};

export const PublicTemplate: Local<Public> = {
  area: undefined,
  forecast_metric: "area",
  energy_performance: 0,
  ...SectorTemplate,
};

interface SectorStoreProps {
  // Industry related functions
  industries: Local<Industry>[];
  industry: (i: number) => Local<Industry>;
  industryCount: () => number;
  setIndustries: (industries: Local<Industry>[]) => void;
  addIndustry: (industry: Local<Industry>) => void;
  addDefaultIndustry: () => ID;
  updateIndustry: (
    id: Local<Industry>["id_"],
    industry: Partial<Local<Industry>>
  ) => void;
  deleteIndustry: (id: Local<Industry>["id_"]) => void;
  resetIndustries: () => void;

  // Residential related functions
  residentials: Local<Residential>[];
  setResidentials: (residentials: Local<Residential>[]) => void;
  addResidential: (residential: Local<Residential>) => void;
  addDefaultResidential: () => ID;
  updateResidential: (
    id: Local<Residential>["id_"],
    residential: Partial<Local<Residential>>
  ) => void;
  deleteResidential: (id: Local<Residential>["id_"]) => void;
  resetResidentials: () => void;

  // Commercial related functions
  commercials: Local<Commercial>[];
  setCommercials: (commercials: Local<Commercial>[]) => void;
  addCommercial: (commercial: Local<Commercial>) => void;
  addDefaultCommercial: () => ID;
  updateCommercial: (
    id: Local<Commercial>["id_"],
    commercial: Partial<Local<Commercial>>
  ) => void;
  deleteCommercial: (id: Local<Commercial>["id_"]) => void;
  resetCommercials: () => void;

  // Public related functions
  publics: Local<Public>[];
  setPublics: (publics: Local<Public>[]) => void;
  addPublic: (public_: Local<Public>) => void;
  addDefaultPublic: () => ID;
  updatePublic: (
    id: Local<Public>["id_"],
    public_: Partial<Local<Public>>
  ) => void;
  deletePublic: (id: Local<Public>["id_"]) => void;
  resetPublics: () => void;
  resetSectors: () => void;
}

type SectorKey = "industry" | "residential" | "commercial" | "public";
let tempIdCounter = 0;

function updateSector<T extends Local<Sector>>(
  sectors: T[],
  id: ID,
  data: Partial<T>
) {
  return sectors.map((s) => (s.id_ === id ? { ...s, ...data } : s));
}

function deleteSector<T extends Local<Sector>>(sectors: T[], id: ID) {
  return sectors.filter((s) => {
    console.log(s.id_, id, s.id_ !== id);
    return s.id_ !== id;
  });
}

function addSector<T extends Local<Sector>>(newSector: T, sectors: T[]) {
  return [...sectors, newSector];
}

function addDefaultSector<T extends Local<Sector>>(
  sectors: T[],
  id: ID,
  template: T
) {
  return addSector({ ...template, id_: id }, sectors);
}

function resetSectors<T extends Local<Sector>>() {
  return [];
}

export const useSectorStore = create<SectorStoreProps>((set, get) => ({
  // Industry related functions
  industries: [],
  industry: (i: number) => get().industries[i],
  industryCount: () => get().industries.length,
  setIndustries: (industries) => {
    set({
      industries,
    });
  },
  addIndustry: (industry) => {
    set((state) => ({
      industries: addSector<Local<Industry>>(industry, state.industries),
    }));
  },
  addDefaultIndustry: () => {
    let id: ID = "temp_" + new Date().getTime().toString();
    set((state) => ({
      industries: addDefaultSector<Local<Industry>>(
        state.industries,
        id,
        IndustryTemplate
      ),
    }));
    return id;
  },

  updateIndustry: (id, data) =>
    set((state) => ({
      industries: updateSector<Local<Industry>>(state.industries, id, data),
    })),
  deleteIndustry: (id) => {
    console.log("deleted", id);
    set((state) => ({
      industries: deleteSector<Local<Industry>>(state.industries, id),
    }));
  },
  resetIndustries: () =>
    set((state) => ({
      industries: resetSectors<Local<Industry>>(),
    })),

  // Residential related functions
  residentials: [],
  setResidentials: (residentials) =>
    set((state) => ({
      residentials,
    })),
  addResidential: (residential) => {
    set((state) => ({
      residentials: addSector<Local<Residential>>(
        residential,
        state.residentials
      ),
    }));
  },
  addDefaultResidential: () => {
    let id: ID = "temp_" + new Date().getTime().toString();
    set((state) => ({
      residentials: addDefaultSector<Local<Residential>>(
        state.residentials,
        id,
        ResidentialTemplate
      ),
    }));
    return id;
  },
  updateResidential: (id, data) =>
    set((state) => ({
      residentials: updateSector<Local<Residential>>(
        state.residentials,
        id,
        data
      ),
    })),
  deleteResidential: (id) =>
    set((state) => ({
      residentials: deleteSector<Local<Residential>>(state.residentials, id),
    })),
  resetResidentials: () =>
    set((state) => ({
      residentials: resetSectors<Local<Residential>>(),
    })),
  // Commercial related functions
  commercials: [],
  setCommercials: (commercials) =>
    set((state) => ({
      commercials,
    })),
  addCommercial: (commercial) => {
    set((state) => ({
      commercials: addSector<Local<Commercial>>(commercial, state.commercials),
    }));
  },
  addDefaultCommercial: () => {
    let id: ID = "temp_" + new Date().getTime().toString();
    set((state) => ({
      commercials: addDefaultSector<Local<Commercial>>(
        state.commercials,
        id,
        CommercialTemplate
      ),
    }));
    return id;
  },
  updateCommercial: (id, data) =>
    set((state) => ({
      commercials: updateSector<Local<Commercial>>(state.commercials, id, data),
    })),
  deleteCommercial: (id) =>
    set((state) => ({
      commercials: deleteSector<Local<Commercial>>(state.commercials, id),
    })),
  resetCommercials: () =>
    set((state) => ({
      commercials: resetSectors<Local<Commercial>>(),
    })),
  // Public related functions
  publics: [],
  setPublics: (publics) =>
    set((state) => ({
      publics,
    })),
  addPublic: (public_) => {
    set((state) => ({
      publics: addSector<Local<Public>>(public_, state.publics),
    }));
  },
  addDefaultPublic: () => {
    let id: ID = "temp_" + new Date().getTime().toString();
    set((state) => ({
      publics: addDefaultSector<Local<Public>>(
        state.publics,
        id,
        PublicTemplate
      ),
    }));
    return id;
  },
  updatePublic: (id, data) =>
    set((state) => ({
      publics: updateSector<Local<Public>>(state.publics, id, data),
    })),
  deletePublic: (id) =>
    set((state) => ({
      publics: deleteSector<Local<Public>>(state.publics, id),
    })),
  resetPublics: () =>
    set((state) => ({
      publics: resetSectors<Local<Public>>(),
    })),
  resetSectors: () =>
    set((state) => ({
      industries: resetSectors<Local<Industry>>(),
      residentials: resetSectors<Local<Residential>>(),
      commercials: resetSectors<Local<Commercial>>(),
      publics: resetSectors<Local<Public>>(),
    })),
}));
