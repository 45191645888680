import { useGetGridInfo, useGetParents } from "@/api/location";
import { Request } from "@/api/request/request";
import { Combobox } from "@/components/ComboBox";
import { Input } from "@/components/Input";
import { Local } from "@/pages/requests/helper/api";
import { round } from "@/utils/round";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Map } from "./Map";

function splitStations(stations: string | string[]) {
  if (!stations) return "";
  if (typeof stations === "string") return stations;
  return stations.join(", ");
}

interface ISubstationComboboxProps {
  substation: Local<Request>["substation"];
  setSubstation: (value: Local<Request>["substation"]) => void;
}

function SubstationCombobox(props: ISubstationComboboxProps) {
  const { substation, setSubstation } = props;
  const { data } = useGetGridInfo();
  const { getParents } = useGetParents();
  const [parents, setParents] = useState<string | string[]>("");
  useEffect(() => {
    async function fetchParents() {
      if (!substation) return;
      setParents(await getParents(substation[0], false));
    }
    fetchParents();
  }, [substation]);
  return (
    <div className="">
      <div className="flex">
        <Combobox
          title="Substation"
          list={data ? data.station_id : []}
          selectedValue={substation ? substation[0] : ""}
          onChange={(value) => {
            setSubstation([value]);
          }}
        ></Combobox>
      </div>
      <div
        className={twMerge(
          "text-xs text-neutral-700",
          parents && parents.length > 0 ? "" : "hidden"
        )}
      >
        {" "}
        Affected: {splitStations(parents)}
      </div>
    </div>
  );
}

interface ILocationSettingsProps {
  request: Local<Request>;
  setRequest: (value: Local<Request>) => void;
  setIsNextDisabled?: (value: boolean) => void;
}

export function LocationSettings(props: ILocationSettingsProps) {
  const { data } = useGetGridInfo();
  const { request, setRequest, setIsNextDisabled } = props;

  return (
    <>
      <Divider className="my-4 text-sm text-neutral-700" textAlign="left">
        {" "}
        Location{" "}
      </Divider>
      <div className="my-3"></div>

      <div className="flex space-y-4 justify-between">
        <div className="space-y-4 overflow-visible isolation-auto">
          <Input
            className="w-40 "
            title="Address"
            size="small"
            statusMessage="This field is required in order to calculate hourly profile"
          ></Input>
          <div className="flex justify-between">
            <Input
              className="w-[4.5rem]"
              title="Longitude"
              size="small"
              statusMessage="This field is required in order to calculate hourly profile"
              value={request.longitude && round(request.longitude, 3)}
            ></Input>
            <Input
              className="w-20 "
              title="Latitude"
              size="small"
              statusMessage="This field is required in order to calculate hourly profile"
              value={request.latitude && round(request.latitude, 3)}
            ></Input>
          </div>
          <SubstationCombobox
            substation={request.substation}
            setSubstation={(value) => {
              setRequest({ ...request, substation: value });
            }}
          ></SubstationCombobox>
        </div>
        <div className="">
          <Map
            coordinates={{ lat: request.latitude!, lng: request.longitude! }}
            height="10rem"
            width="10rem"
          ></Map>
        </div>
      </div>
    </>
  );
}
