import { create } from "zustand";

interface LevelInfo {
  min: number;
  max: number;
}

interface LevelStore {
  level: number;
  minLevel: number;
  maxLevel: number;
  topStationsIds: string[];
  deep: boolean;
  setDeep: (deep: boolean) => void;
  setLevel: (level: number) => void;
  setMinMaxLevel: (obj: LevelInfo) => void;
  setTopStation: (topStationId: string[]) => void;
}

export const useHierarchyStore = create<LevelStore>((set) => ({
  level: 0,
  minLevel: 0,
  maxLevel: 1,
  topStationsIds: [],
  deep: false,
  setDeep: (deep) => set({ deep }),
  setLevel: (level) => set({ level }),
  setMinMaxLevel: (obj) => set({ minLevel: obj.min, maxLevel: obj.max }),
  setTopStation: (topStationId) => set({ topStationsIds: topStationId }),
}));
