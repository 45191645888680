import { Columns } from "../../types";

const BUTTONS = [
  {
    name: "Total",
    value: "Total",
  },
  {
    name: "Solar production",
    value: "Solar PV",
  },
  {
    name: "Heating",
    value: "Heating",
  },
  {
    name: "Base",
    value: "Base",
  },
  {
    name: "HV Loads",
    value: "HV Loads",
  },
  {
    name: "Cars",
    value: "Car",
  },
  {
    name: "Trucks",
    value: "Truck",
  },
  {
    name: "Buses",
    value: "Bus",
  },
];
interface RadioButtonProps {
  selectedValue: string;
  setSelectedValue: (value: keyof Columns) => void;
}
export default function RadioButtons(props: RadioButtonProps) {
  const handleChange = (e: any) => {
    props.setSelectedValue(e.target.value);
  };
  return (
    <form className="flex flex-row justify-between w-full px-5 ">
      {BUTTONS.map((button, index) => {
        const isSelected = props.selectedValue === button.value;
        return (
          <label
            key={index}
            className={`inline-flex items-center cursor-pointer px-3`}
          >
            <input
              type="radio"
              className="hidden" // Hide the default radio button
              name="radio-colors"
              value={button.value || ""} // Handle 'undefined' value properly
              checked={isSelected}
              onChange={handleChange}
            />
            <span
              className={`pb-2 ${
                isSelected ? "border-b-[3px] border-primary-500" : ""
              }`}
            >
              {button.name}
            </span>
          </label>
        );
      })}
    </form>
  );
}
