import StatusDot, { StatusColor } from "@/components/StatusDot";

import { Commercial } from "@/api/request/commercial";
import { Industry } from "@/api/request/industry";
import { Public } from "@/api/request/public";
import { Residential } from "@/api/request/residential";
import Tabs from "@/components/Tabs";
import { Local } from "@/pages/requests/helper/api";
import {} from "@/pages/requests/hooks/requests";
import React, { memo, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  CommercialEditor,
  IndustryEditor,
  PublicEditor,
  ResidentialEditor,
} from "../../SectorEditor";
import { Sectors } from "../types";
import {
  CommercialTemplate,
  IndustryTemplate,
  PublicTemplate,
  ResidentialTemplate,
} from "./Part2";
interface IStackedCardsProps {
  cards?: React.ReactNode[];
  stackOffset?: number;
}

const StackedCards = ({ cards, stackOffset = 0.2 }: IStackedCardsProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  if (!cards) return null;
  const nextCard = () => {
    setActiveIndex((prev) => (prev + 1) % cards.length);
  };

  return (
    <div className="h-full w-full relative">
      <div className="absolute top-[-7rem] left-[6.75rem]  inset-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="relative">
          {cards.map((card, index) => {
            const isActive = index === activeIndex;
            const offset =
              ((index - activeIndex + cards.length) % cards.length) *
              stackOffset;

            return (
              <div
                key={index}
                className="absolute w-full h-full bg-white rounded-lg shadow-lg cursor-pointer transition-all duration-300 ease-in-out"
                style={{
                  zIndex: cards.length - offset,
                  transform: `translate(${offset}rem, ${offset}rem) scale(${
                    isActive ? 1 : 0.95
                  })`,
                }}
                onClick={nextCard}
              >
                {card}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface SectorCardProps {
  sector: SectorName;
  active: boolean;
  onClick?: () => void;
  color: StatusColor;
}

function SectorCard(props: SectorCardProps) {
  const { sector, active, onClick, color } = props;
  return (
    <button
      className={twMerge(
        "text-neutral-300 text-sm bg-neutral-500-80 px-2 rounded-md relative",
        active ? "bg-primary-600-80" : "bg-neutral-500"
      )}
      onClick={onClick}
    >
      {color && (
        <StatusDot
          status={color}
          className="-top-0.5 right-0 absolute"
        ></StatusDot>
      )}
      {sector.charAt(0).toUpperCase() + sector.slice(1)}
    </button>
  );
}

interface HeaderProps {
  currentSector?: number;
  sectors: SectorName[];
  setCurrentSector?: (value: number) => void;
  sectorColors: { [key in SectorName]?: StatusColor };
}

function Header(props: HeaderProps) {
  const { sectors, currentSector, setCurrentSector, sectorColors } = props;
  return (
    <div className="flex space-x-5 justify-center top-[8rem] ">
      {sectors.map((sector, idx) => (
        <SectorCard
          key={sector}
          active={idx === currentSector}
          sector={sector}
          onClick={() => setCurrentSector && setCurrentSector(idx)}
          color={sectorColors[sector] || "red"}
        />
      ))}
    </div>
  );
}

function Hideable(props: { hidden: boolean; children: React.ReactNode }) {
  const { hidden, children } = props;
  return <div className={hidden ? "hidden absolute" : ""}>{children}</div>;
}
interface ISectorTabsProps<T> {
  SectorComponent: React.ComponentType<{
    className: string;
    sector: Local<T>;
    setSector: (value: Local<T>) => void;
    setStatus?: (value: boolean) => void;
  }>;
  sectors: Local<T>[];
  setSectors: (value: Local<T>[]) => void;
  setStatus?: (value: boolean) => void;
  template?: Local<T>;
}

const SectorTabs = memo(
  <T,>(props: ISectorTabsProps<T>) => {
    const { SectorComponent, sectors, setSectors, template, setStatus } = props;
    if (sectors.length === 0) return null;
    const [activeTab, setActiveTab] = useState(sectors[0].id_.toString());
    const [sectorComplete, setSectorComplete] = useState<boolean[]>([]);

    useEffect(() => {
      if (setStatus) {
        setStatus(sectorComplete.every((status) => status));
      }
    }, [sectorComplete, setStatus]);

    return (
      <Tabs
        className="w-60 mx-auto"
        activeTab={activeTab}
        onTabChange={(id) => setActiveTab(id)}
        onAddTab={() => {
          if (!template) return;
          const newId = new Date().getTime().toString();
          setSectors([...sectors, { ...template, id_: newId }]);
          setSectorComplete([...sectorComplete, false]);
          setActiveTab(newId);
        }}
        onRemoveTab={(id) => {
          setSectors(sectors.filter((sector) => sector.id_.toString() !== id));
          setSectorComplete(sectorComplete.slice(0, sectorComplete.length - 1));
          if (activeTab === id) {
            setActiveTab(sectors[0].id_.toString());
          }
        }}
      >
        <Tabs.List showAddButton={sectors.length < 5}>
          {sectors.map((sector, i) => (
            <Tabs.Tab id={sector.id_.toString()} closeable={sectors.length > 1}>
              {i + 1}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {sectors.map((sector, i) => (
          <Tabs.Panel id={sector.id_.toString()} className="p-0">
            <SectorComponent
              className="rounded-t-none"
              sector={sector}
              setSector={(value) => {
                // slice to update the correct residential sector
                const newResidential = [...sectors];
                newResidential[i] = value;
                setSectors(newResidential);
              }}
              setStatus={(value) => {
                setSectorComplete((prev) => {
                  const newStatus = [...prev];
                  if (newStatus.length < i + 1) {
                    newStatus.push(value);
                    return newStatus;
                  }
                  newStatus[i] = value;
                  return newStatus;
                });
              }}
            />
          </Tabs.Panel>
        ))}
      </Tabs>
    );
  },
  (prev, next) => {
    return prev.sectors === next.sectors;
  }
) as <T>(props: ISectorTabsProps<T>) => JSX.Element;

interface Part3Props {
  setIsNextDisabled: (value: boolean) => void;
  sectors: Sectors;
  setSectors: React.Dispatch<React.SetStateAction<Sectors>>;
}

type SectorName = "industry" | "residential" | "commercial" | "public";

export function Part3(props: Part3Props) {
  const { setIsNextDisabled, sectors, setSectors } = props;
  const existingSectors = [
    {
      name: "Residential",
      exists: sectors.residential.length,
    },
    {
      name: "Industry",
      exists: sectors.industry.length,
    },
    {
      name: "Commercial",
      exists: sectors.commercial.length,
    },
    {
      name: "Public",
      exists: sectors.public.length,
    },
  ];

  const [currentSector, setCurrentSector] = useState<number>(0);
  const [statuses, setStatuses] = useState<{ [key in SectorName]: boolean }>({
    industry: false,
    residential: false,
    commercial: false,
    public: false,
  });

  const sectorNames: SectorName[] = existingSectors
    .filter((sector) => sector.exists)
    .map((sector) => sector.name.toLowerCase() as SectorName);

  const sectorColors: { [key: string]: StatusColor } = {
    residential: statuses.residential ? "green" : "red",
    industry: statuses.industry ? "green" : "red",
    commercial: statuses.commercial ? "green" : "red",
    public: statuses.public ? "green" : "red",
  };

  const sectorsComplete = existingSectors
    .filter((sector) => sector.exists)
    .every((sector) => statuses[sector.name.toLowerCase() as SectorName]);

  useEffect(() => {
    setIsNextDisabled(!sectorsComplete);
  }, [sectorsComplete, setIsNextDisabled]);
  return (
    <div className="w-full space-y-4">
      <Header
        sectors={sectorNames}
        currentSector={currentSector}
        setCurrentSector={setCurrentSector}
        sectorColors={Object.keys(sectorColors).reduce(
          (acc, sector) => ({
            ...acc,
            [sector]: statuses[sector as SectorName] ? "green" : "red",
          }),
          {}
        )}
      />
      <Hideable hidden={sectorNames[currentSector] !== "residential"}>
        <SectorTabs<Residential>
          SectorComponent={ResidentialEditor}
          sectors={sectors.residential}
          setSectors={(newResidential) =>
            setSectors((prev) => ({ ...prev, residential: newResidential }))
          }
          template={ResidentialTemplate}
          setStatus={(value) => {
            setStatuses((prev) => ({ ...prev, residential: value }));
          }}
        />
      </Hideable>
      <Hideable hidden={sectorNames[currentSector] !== "industry"}>
        <SectorTabs<Industry>
          SectorComponent={IndustryEditor}
          sectors={sectors.industry}
          setSectors={(newIndustry) =>
            setSectors((prev) => ({ ...prev, industry: newIndustry }))
          }
          template={IndustryTemplate}
          setStatus={(value) => {
            setStatuses((prev) => ({ ...prev, industry: value }));
          }}
        />
      </Hideable>
      <Hideable hidden={sectorNames[currentSector] !== "commercial"}>
        <SectorTabs<Commercial>
          SectorComponent={CommercialEditor}
          sectors={sectors.commercial}
          setSectors={(newCommercial) =>
            setSectors((prev) => ({ ...prev, commercial: newCommercial }))
          }
          template={CommercialTemplate}
          setStatus={(value) => {
            setStatuses((prev) => ({ ...prev, commercial: value }));
          }}
        />
      </Hideable>
      <Hideable hidden={sectorNames[currentSector] !== "public"}>
        <SectorTabs<Public>
          SectorComponent={PublicEditor}
          sectors={sectors.public}
          setSectors={(newPublic) =>
            setSectors((prev) => ({ ...prev, public: newPublic }))
          }
          template={PublicTemplate}
          setStatus={(value) => {
            setStatuses((prev) => ({ ...prev, public: value }));
          }}
        />
      </Hideable>
    </div>
  );
}
