import { LeafletEventHandlerFnMap } from "leaflet";
import { useMemo, useRef, useState } from "react";
import { GeoJSON } from "react-leaflet";
import { substationStyle } from "../data/Color";
import { GridData } from "../hooks/layerHook";
import { useHierarchyStore } from "../stores/levelStore";
import { useTilesSettingsStore } from "../stores/tilesSettingsStore";
import { HoverInfo, HoverTooltip } from "./HoverTooltip";

interface TilesGeoJSONProps {
  gridData?: GridData;
  filteredTiles?: any;
  substationInfo?: any;
  tiles?: any;
  year: number;
  selectedSubstations: string[];
  setSelectedSubstations: React.Dispatch<React.SetStateAction<string[]>>;
  rightClickEvent?: (e: any) => void;
}

export function TilesGeoJSON(props: TilesGeoJSONProps) {
  const {
    gridData,
    filteredTiles,
    substationInfo,
    tiles,
    year,
    selectedSubstations,
    setSelectedSubstations,
    rightClickEvent,
  } = props;

  const category = useTilesSettingsStore((state) => state.category);
  const geoJsonLayer = useRef<any>(null);
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | undefined>(undefined);

  const {
    topStationsIds: topStationId,
    level,
    setLevel,
    setTopStation,
  } = useHierarchyStore();

  useMemo(() => {
    if (geoJsonLayer.current && filteredTiles) {
      geoJsonLayer.current.clearLayers().addData(filteredTiles);
    }
  }, [filteredTiles]);

  const geoJSONEvent: LeafletEventHandlerFnMap = {
    click: (e: any) => {
      const stationId =
        e["sourceTarget"]["feature"]["properties"]["station_id"];
      const shiftPressed = e.originalEvent.shiftKey;
      if (shiftPressed) {
        if (selectedSubstations.includes(stationId)) {
          // Remove the selected substation
          const newSelectedSubstations = selectedSubstations.filter(
            (value: string) => value !== stationId
          );
          setSelectedSubstations(newSelectedSubstations);
          return;
        }
        setSelectedSubstations([...selectedSubstations, stationId]);
        return;
      }
      if (selectedSubstations.includes(stationId)) {
        setSelectedSubstations([topStationId[topStationId.length - 1]]);
        return;
      }
      setSelectedSubstations([stationId]);
      if (level >= Object.keys(tiles!).length - 1) {
        return;
      }
    },
    mouseover: (e: any) => {
      const stationId: string =
        e["sourceTarget"]["feature"]["properties"]["station_id"];
      let energy;
      let power;
      let utilization;
      if (gridData && gridData.energy) {
        energy = gridData?.energy.find(
          (d) => d.station_id === stationId
        )?.energy;
      }
      if (gridData && gridData.power) {
        power = gridData?.power.find((d) => d.station_id === stationId)?.power;
      }
      if (gridData && gridData.utilization) {
        utilization = gridData?.utilization.find(
          (d) => d.station_id === stationId
        )?.utilization;
      }
      let indexOfStation: number;
      let substation: string | undefined;
      if (substationInfo && substationInfo.station_id && substationInfo.name) {
        indexOfStation = substationInfo?.station_id.indexOf(
          stationId
        ) as number;
        substation = substationInfo.name[indexOfStation];
      }

      if (!substation) {
        substation = "Unknown";
      }
      setHoverInfo({
        substation: substation,
        station_id: stationId,
        energy: energy,
        maxPower: power,
        utilization: utilization,
        year: year,
      });
    },
    contextmenu: rightClickEvent,
  };

  return (
    <>
      {filteredTiles && (
        <GeoJSON
          ref={geoJsonLayer}
          key={`geojson.${location}.${level}`}
          // @ts-ignore
          data={filteredTiles}
          style={(feature) => {
            return substationStyle(
              feature,
              selectedSubstations,
              category === "energy"
                ? gridData?.energy
                : category === "power"
                ? gridData?.power
                : gridData?.utilization
            );
          }}
          eventHandlers={geoJSONEvent}
        >
          <HoverTooltip hoverInfo={hoverInfo} />
        </GeoJSON>
      )}
    </>
  );
}

/*  */
