import { Request } from "@/api/request/request";
import { optionProps } from "../types";

export default function processData(
  data: Request[],
  xAxis: keyof Request,
  legend: keyof Request,
  value: keyof Request
) {
  // Your formatting logic here
  const result: optionProps = { xAxis: [], legend: [], series: [] };
  // get all unique years and sort them
  data.forEach((row) => {
    let rowAxis = row[xAxis]?.toString();
    if (!rowAxis) {
      rowAxis = "Unknown";
    }
    const existingItem = result.xAxis.find((item) => item === rowAxis);
    if (!existingItem) {
      result.xAxis.push(rowAxis);
    }
  });
  result.xAxis.sort();
  data.forEach((row) => {
    let rowLegend = row[legend]?.toString();
    if (!rowLegend) rowLegend = "Unknown";
    let existingSector = result.series.find(
      (sector) => sector.name === rowLegend
    );

    if (!existingSector) {
      existingSector = {
        name: rowLegend,
        type: "bar",
        stack: "total",
        barGap: 0,
        emphasis: { focus: "series" },
        data: new Array(result.xAxis.length).fill(0),
      };
      result.series.push(existingSector);
    }
    let rowAxis = row[xAxis]?.toString();
    if (!rowAxis) {
      rowAxis = "Unknown";
    }
    const idx = result.xAxis.indexOf(rowAxis.toString());
    let rowValue = Number(row[value]);
    if (!rowValue) rowValue = 0;
    existingSector.data![idx] += rowValue;
  });
  // Sort the series by name.
  // This is in order to ensure it can be referenced
  // easily with other charts
  result.series.sort((a, b) => {
    if (a.name! < b.name!) {
      return -1;
    }
    return 1;
  });
  return result;
}
