import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";

import { useGetCentroid } from "@/api/location";
import { LatLngExpression, LeafletMouseEvent } from "leaflet";
import { Profiler, memo, useEffect, useState } from "react";
import RequestMarkers from "./RequestMarkers";
import { Settings } from "./Settings";
import { Tiles } from "./Tiles";
import { TilesOptions } from "./TilesOptions";
import { TilesWOGridData } from "./TilesWOGridData";
interface MapEventProps {
  clickEvent?: (e: LeafletMouseEvent) => void;
}

function MapEvent(props: MapEventProps) {
  const { clickEvent } = props;
  if (!clickEvent) {
    return <></>;
  }
  useMapEvents({
    click(e) {
      clickEvent(e);
    },
  });
  return <></>;
}
const DEFAULT_YEAR = 2024;
interface MapProps {
  selectedSubstations: string[];
  setSelectedSubstations: any;
  className?: string;
  rightClickEvent?: (e: LeafletMouseEvent) => void;
  clickEvent?: (e: LeafletMouseEvent) => void;
  markers?: boolean;
  customMarker?: [number | undefined, number | undefined];
  columns?: string[];
  year?: number;
  tiles?: boolean;
  showSettings?: boolean;
  showGridData?: boolean;
}

export const Map = memo(
  (props: MapProps) => {
    let { showSettings } = props;
    let showGridData = true;

    const [map, setMap] = useState<any>(null);
    const { data: centroid } = useGetCentroid();
    const [showRequests, setShowRequests] = useState<boolean>(
      props.markers || false
    );
    if (showSettings === undefined) {
      showSettings = true;
    }

    useEffect(() => {
      if (map && centroid) {
        map.setView(centroid, 10, {
          animate: true, // animate the move
          duration: 1, // duration in seconds
        });
      }
    }, [centroid]);

    return (
      <Profiler id="Map" onRender={() => {}}>
        <MapContainer
          center={[60, 15]} // middle
          zoom={6}
          ref={setMap}
          className={` ${props.className}`}
          zoomControl={false}
        >
          {props.tiles &&
            (showGridData ? (
              <Tiles
                columns={props.columns || ["Total"]}
                selectedSubstations={props.selectedSubstations}
                setSelectedSubstations={props.setSelectedSubstations}
                rightClickEvent={props.rightClickEvent}
                year={props.year || DEFAULT_YEAR}
              />
            ) : (
              <TilesWOGridData
                columns={props.columns || ["Total"]}
                selectedSubstations={props.selectedSubstations}
                setSelectedSubstations={props.setSelectedSubstations}
                rightClickEvent={props.rightClickEvent}
                year={props.year || DEFAULT_YEAR}
              ></TilesWOGridData>
            ))}
          {showSettings ? (
            <Settings
              showRequests={showRequests}
              setShowRequests={setShowRequests}
            />
          ) : null}
          {showRequests ? <RequestMarkers /> : null}

          {
            // @ts-ignore
            props.customMarker &&
              props.customMarker[0] &&
              props.customMarker[1] && (
                <Marker position={props.customMarker as LatLngExpression} />
              )
          }
          <MapEvent clickEvent={props.clickEvent} />
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={19}
          />
          {props.tiles && <TilesOptions />}
        </MapContainer>
      </Profiler>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.selectedSubstations === nextProps.selectedSubstations &&
      prevProps.year === nextProps.year &&
      prevProps.markers === nextProps.markers &&
      prevProps.customMarker === nextProps.customMarker &&
      prevProps.columns === nextProps.columns &&
      prevProps.tiles === nextProps.tiles &&
      prevProps.showSettings === nextProps.showSettings &&
      prevProps.showGridData === nextProps.showGridData
    );
  }
);

export default Map;
