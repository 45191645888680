import { useGetRequests } from "@/api/request/request";
import useRequestTrigger from "@/hooks/requestTrigger";
import { suffixFormatter } from "@/utils/suffixFormatter";
import { EChartsOption } from "echarts";
import { useEffect, useState } from "react";
import { ColumnDict } from "../../../columns";
import processData from "../dataProcessing/ProcessHistChart";
import { optionProps } from "../types";
import { useAnalysisSettings } from "./../../../stores/analysisSettings";

function createOption(
  props: optionProps | null,
  legend: string,
  groupbyColumn: string,
  histXAxis: string
) {
  if (!props || !props.xAxis) {
    return {};
  }
  let factor: number | undefined;
  if (ColumnDict[groupbyColumn].serversideSuffix !== undefined) {
    factor = ColumnDict[groupbyColumn].serversideSuffix;
  } else {
    factor = 1;
  }
  if (factor === undefined) {
    factor = 1;
  }
  var option: EChartsOption = {
    grid: {
      top: "20%",
      bottom: "15%",
    },
    // @ts-ignore // tslint:disable-next-line
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (val: number) =>
        suffixFormatter({ value: val * 1000 }) + "W",
    },
    legend: {},
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar", "stack"] },
      },
    },
    xAxis: [
      {
        type: "category",
        nameLocation: "middle",
        nameGap: 30,
        name: ColumnDict[histXAxis].label,
        axisTick: { show: false },

        data: props.xAxis,
      },
    ],
    yAxis: [
      {
        type: "value",
        name: ColumnDict[groupbyColumn].label,
        nameLocation: "middle",
        nameGap: 70,
        axisLabel: {
          formatter: (val: number) =>
            // @ts-ignore
            suffixFormatter({ value: val * factor }) +
            ColumnDict[groupbyColumn].unit,
        },
      },
    ],
    series: props.series,
  };

  return option;
}

export default function useHistOption() {
  // Call api
  const [option, setOption] = useState<EChartsOption>({});
  const { settings } = useAnalysisSettings();
  const { data, refreshData, loading } = useGetRequests();
  const { requestTrigger } = useRequestTrigger();

  useEffect(() => {
    refreshData();
  }, [requestTrigger]);

  useEffect(() => {
    if (!loading) {
      const option = createOption(
        processData(
          JSON.parse(JSON.stringify(data)),
          settings.histXAxis,
          settings.legend,
          settings.groupbyColumn
        ),
        settings.legend,
        settings.groupbyColumn,
        settings.histXAxis
      );
      setOption(option);
    }
  }, [data, settings]);

  return { option, loading };
}
