import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { createContext, useContext, type ReactNode } from "react";
import { twMerge } from "tailwind-merge";
interface TabsContext {
  activeTab: string;
  onTabChange: (id: string) => void;
  onAddTab?: () => void;
  onRemoveTab?: (id: string) => void;
}

const TabsContext = createContext<TabsContext | null>(null);

const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("Tabs components must be used within a Tabs component");
  }
  return context;
};

interface TabsProps {
  className?: string;
  children: ReactNode;
  activeTab: string;
  onTabChange: (id: string) => void;
  onAddTab?: () => void;
  onRemoveTab?: (id: string) => void;
}

const Tabs = ({
  children,
  activeTab,
  onTabChange,
  onAddTab,
  onRemoveTab,
  className,
}: TabsProps) => {
  return (
    <TabsContext.Provider
      value={{
        activeTab,
        onTabChange,
        onAddTab,
        onRemoveTab,
      }}
    >
      <div className={twMerge("w-full", className)}>{children}</div>
    </TabsContext.Provider>
  );
};

interface TabListProps {
  className?: string;
  children: ReactNode;
  showAddButton?: boolean;
}

const TabList = ({
  className,
  children,
  showAddButton = false,
}: TabListProps) => {
  const { onAddTab } = useTabsContext();

  return (
    <div
      className={twMerge(
        "flex items-center gap-1 border-b ring-1 ring-neutral-500-80 rounded-t-md bg-neutral-400",
        className
      )}
    >
      {children}
      {showAddButton && onAddTab && (
        <button
          onClick={onAddTab}
          className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-t-md transition-colors"
        >
          <AddIcon></AddIcon>
        </button>
      )}
    </div>
  );
};

interface TabProps {
  className?: string;
  id: string;
  children: ReactNode;
  closeable?: boolean;
}

const Tab = ({ id, className, children, closeable = true }: TabProps) => {
  const { activeTab, onTabChange, onRemoveTab } = useTabsContext();

  return (
    <div className={twMerge("flex items-center relative group", activeTab)}>
      <button
        onClick={() => onTabChange(id)}
        className={`
          px-4 py-2 font-medium rounded-t-lg transition-all duration-200
          ${
            activeTab === id
              ? "bg-neutral-100 text-primary-600 border-b-2  border-primary-600"
              : "text-neutral-500 hover:text-neutral-700 border-b-2 border-neutral-400 hover:bg-neutral-100"
          }
        `}
      >
        {children}
      </button>
      {closeable && onRemoveTab && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemoveTab(id);
          }}
          className="w-3 h-3 flex items-center justify-center invisible hover:text-red-600 rounded-full absolute -top-1 -right-1  group-hover:text-neutral-600 group-hover:visible   "
        >
          <CloseIcon
            className=""
            fontSize="inherit"
            style={{ height: "1rem", width: "1rem" }}
          ></CloseIcon>
        </button>
      )}
    </div>
  );
};

interface TabPanelProps {
  id: string;
  className?: string;
  children: ReactNode;
}

const TabPanel = ({ id, className, children }: TabPanelProps) => {
  const { activeTab } = useTabsContext();
  if (activeTab !== id) return null;
  return (
    <div
      className={twMerge(
        "p-4 bg-white rounded-b-lg shadow-md ring-1 ring-neutral-500 overflow-auto h-full",
        className
      )}
    >
      {children}
    </div>
  );
};

Tabs.List = TabList;
Tabs.Tab = Tab;
Tabs.Panel = TabPanel;

export default Tabs;
