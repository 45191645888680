type APIDataType = number | string | Date;

export interface APIData {
  [key: string]: APIDataType[];
}
type CombinedDataItem = { [key: string]: APIDataType };

export function sortAPIData(data: APIData, sortKey: string): APIData {
  // Combine
  const keys = Object.keys(data);
  const combined = data[sortKey].reduce(
    (acc: CombinedDataItem[], _, index: number) => {
      const obj = keys.reduce((d: CombinedDataItem, key) => {
        d[key] = data[key][index];
        return d;
      }, {});
      acc.push(obj);
      return acc;
    },
    []
  );

  // Sort
  combined.sort((a, b) =>
    a[sortKey].toString().localeCompare(b[sortKey].toString())
  );

  // Separate the combined array back
  const sortedData: APIData = keys.reduce((acc: APIData, key) => {
    acc[key] = combined.map((obj) => obj[key]);
    return acc;
  }, {});
  return sortedData;
}
