import { Forecast } from "@/pages/forecast";
import { Requests } from "@/pages/requests/";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AI } from "./pages/AI";
import { Basis } from "./pages/Basis";
import { Admin } from "./pages/admin/Admin";
import { Home } from "./pages/home";
import SideBar from "./pages/sidebar";
import { useLocationStore } from "./stores";

function App() {
  const { user, isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const { setLocation } = useLocationStore();
  useEffect(() => {
    if (isAuthenticated && user) {
      if (user.locs && user.locs.length > 0) setLocation(user.locs[0]);
    }
  }, [user]);

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
  }
  return (
    isAuthenticated &&
    !isLoading && (
      <div className="flex flex-row w-screen">
        <SideBar />
        <div className="bg-neutral-300 w-svw h-svh p-2 text-black">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/forecast" element={<Forecast />}></Route>
            <Route path="/data-analytics" element={<Basis />}></Route>
            <Route path="/requests" element={<Requests />}></Route>
            {false ? <Route path="/AI" element={<AI />}></Route> : null}
            <Route path="/requests" element={<Requests />}></Route>
            {user && user.admin ? (
              <Route path="/admin" element={<Admin />}></Route>
            ) : null}
          </Routes>
        </div>
      </div>
    )
  );
}

export default App;
/*<Route path="/requests" element={<Requests />}></Route>*/
