import { useGetGridCapacity } from "@/api/grid";
import { useLoadZoomStore } from "@/pages/forecast/stores/LoadZoomStore";
import { Columns } from "@/pages/forecast/types";
import { useEffect, useMemo, useState } from "react";
import { createOption } from "../Option";
import { useGetLoadData } from "../api/getData";
import { addSubstation } from "../data/addSubstationLine";
import { processData } from "../data/processing";

function getTimeORder(time: string[]) {
  // get the indices of of unsorted time array in reference to the sorted time array, input is unsorted time array
  const sortedTime = time.slice().sort();
  const order = time.map((t) => sortedTime.indexOf(t));
  return order;
}

export function useGetOption(
  substations: string[],
  selectedYear: number,
  selectedDataColumn: keyof Columns,
  durVHour: string,
  selectedLegends: { [key: string]: boolean }
) {
  const [option, setOption] = useState<any>(
    createOption(null, [0, 100], durVHour, selectedDataColumn, selectedLegends)
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [powerData, setPowerData] = useState<any>(null);
  const { durZoom, hourZoom } = useLoadZoomStore();
  const { data: rawData, loading: loadLoading } = useGetLoadData(
    substations,
    selectedDataColumn,
    selectedYear
  );
  const { data: transformerData, refreshData } =
    useGetGridCapacity(substations);

  useEffect(() => {
    refreshData();
  }, [substations]);
  useEffect(() => {
    if (substations.length === 0 || !substations[0]) return;
    setIsLoading(true);
    let data = JSON.parse(JSON.stringify(rawData));

    const { processedData } = processData({
      rawData: data,
      durVHour,
      selectedDataColumn,
    });
    if (
      transformerData &&
      transformerData.length > 0 &&
      transformerData[0].transformer_capacity
    ) {
      processedData.series = addSubstation(
        processedData.series,
        transformerData[0].transformer_capacity
      );
    }
    setPowerData(processedData);
  }, [rawData, durVHour]);

  useMemo(() => {
    if (!powerData) {
      return;
    }
    setIsLoading(true);
    if (!selectedYear) {
      setOption(
        createOption(
          null,
          [0, 100],
          durVHour,
          selectedDataColumn,
          selectedLegends
        )
      );
      return;
    }
    let processedData = JSON.parse(JSON.stringify(powerData));

    // Check if processed data already

    const zoom = durVHour === "Duration" ? durZoom : hourZoom;
    setOption(
      createOption(
        processedData,
        zoom,
        durVHour,
        selectedDataColumn,
        selectedLegends
      )
    );
    setIsLoading(false);
  }, [powerData]);

  return { isLoading: isLoading || loadLoading, option };
}
