import { suffixFormatter } from "@/utils/suffixFormatter";
import { Divider } from "@mui/material";
import { LineSeriesOption } from "echarts";
type LineDataItemOption = { name: string; value: [number | string, number] }[];

export function calcBigNumbers(series: LineSeriesOption[]): {
  FlexDur: number;
  FlexEnergy: number;
  FlexPower: number;
} {
  const seriesCopy: LineSeriesOption[] = JSON.parse(JSON.stringify(series));
  if (seriesCopy.length === 0) {
    return { FlexDur: 68, FlexEnergy: 0, FlexPower: 0 };
  }
  let [FlexDur, FlexEnergy, FlexPower] = [68, 0, 0];

  let NetEnergy: number[] = new Array(FlexDur).fill(0);
  for (let i = 0; i < seriesCopy.length; i++) {
    if (seriesCopy[i].name === "Substation Capacity") {
      continue;
    }
    let data = seriesCopy[i].data as LineDataItemOption;
    for (let j = 0; j < FlexDur; j++) {
      NetEnergy[j] += data[j].value[1];
    }
  }
  const FlexBase = NetEnergy[NetEnergy.length - 1];
  for (let i = 0; i < NetEnergy.length; i++) {
    FlexEnergy += Math.abs(NetEnergy[i] - FlexBase);
  }
  FlexPower = NetEnergy[0] - FlexBase;

  return { FlexDur, FlexEnergy, FlexPower };
}
export interface BigNumbersProps {
  selectedValue: string;
  substation: string;
  year: number;
}

export function BigNumbers(props: BigNumbersProps) {
  const { selectedValue, substation, year } = props;
  return selectedValue === "Total" && undefined && undefined && undefined ? (
    <div className="flex flex-row py-4 transform transition-all duration-150 ease-out scale-100">
      <Divider orientation="vertical" />
      <div className="px-4">
        <div className="text-sm text-neutral-800 text-start">Flex Duration</div>
        <div className="text-4xl">
          {undefined}{" "}
          <span className="text-sm text-neutral-700 text-start">h</span>
        </div>
      </div>
      <Divider orientation="vertical" />
      <div className="px-4">
        <div className="text-sm text-neutral-800 text-start">Flex Energy</div>
        <div className="text-4xl font-normal">
          {suffixFormatter({ value: 0, type: "value" })}{" "}
          <span className="text-sm text-neutral-700 text-start">
            {suffixFormatter({ value: 0, type: "suffix" })}Wh
          </span>
        </div>
      </div>
      <Divider orientation="vertical" />
      <div className="px-4">
        <div className="text-sm text-neutral-800 text-start">Flex Power</div>

        <div className="text-4xl">
          {suffixFormatter({ value: 0, type: "value" })}{" "}
          <span className="text-sm text-neutral-700 text-start">
            {suffixFormatter({ value: 0, type: "suffix" })}W
          </span>
        </div>
      </div>
      <Divider orientation="vertical" />
    </div>
  ) : (
    <></>
  );
}
