import { RadioGroupProps } from "./types";

const selectedStyle =
  "bg-primary-100 ring-primary-600 text-neutral-800 fill-neutral-800";
const unselectedStyle =
  "bg-neutral-300 ring-neutral-500 text-neutral-700 fill-neutral-700";

// Custom radio button component
interface RadioButtonMatProps {
  title: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: string;

  svgImg?: React.ElementType;
}

function RadioButtonMat({
  title,
  value,
  onChange,
  selected,
  svgImg,
}: RadioButtonMatProps) {
  const isSelected = selected === value;
  return (
    <button
      className={`flex text-xs font-medium rounded-full ring-1 px-2 py-1 m-0 ${
        isSelected ? selectedStyle : unselectedStyle
      }`}
      name={title}
      value={value}
      onClick={(e) => {
        e.stopPropagation();
        onChange && onChange(e);
      }}
    >
      {svgImg ? <svgImg className="h-4 w-4" /> : null}
      {title}
    </button>
  );
}

export function RadioGroupMat(props: RadioGroupProps) {
  const { selectedValue, onChange, radioItems } = props;
  return (
    <div className="flex space-x-1">
      {radioItems.map(({ title, value }) => (
        <RadioButtonMat
          title={title}
          value={value}
          onChange={onChange}
          selected={selectedValue}
        />
      ))}
    </div>
  );
}
