import { Request } from "@/api/request/request";
import { Button } from "@/components/Button";
import Dropdown from "@/components/OldDropdown";
import { Local } from "@/pages/requests/helper/api";
import { useRequests } from "@/pages/requests/hooks/requests";
import { useSectors } from "@/pages/requests/hooks/sector";
import { useRequestStore } from "@/pages/requests/stores/requestsStore";
import { Alert, AlertProps, Divider, Modal, Snackbar } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { GeneralPage } from "./General";
import { OverviewPage } from "./Overview";
import { RequestProfile } from "./Profile";
import { CommercialPanel } from "./Sectors/Commercial";
import { IndustryPanel } from "./Sectors/Industry";
import { PublicPanel } from "./Sectors/Public";
import { ResidentialPanel } from "./Sectors/Residential";
import { SidePanel } from "./SidePanel";

interface RequestEditorProps {
  isOpen: boolean;
  onClose: () => void;
  selectedID?: number | null;
  setSelectedID?: (value: number | null) => void;
}

export const RequestEditor = memo(
  (props: RequestEditorProps) => {
    const { isOpen, onClose, selectedID, setSelectedID } = props;
    const { updateRequest } = useRequests();
    const { syncSectors, applyChanges } = useSectors();
    const requests = useRequestStore((state) => state.requests);
    const [snackbar, setSnackbar] = useState<Pick<
      AlertProps,
      "children" | "severity"
    > | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handleProcessRowUpdateError = useCallback((error: Error) => {
      setSnackbar({
        children: "Something went wrong. The issue has been reported to Endre.",
        severity: "error",
      });
    }, []);
    const dropdownList = requests
      ? requests
          .map((request) => ({
            name: request.name,
            value: request.id_!,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      : [];
    if (!requests) return null;

    const [currentOption, setCurrentOption] = useState<string>("Overview");

    const [editableRow, setEditableRow] = useState<Local<Request> | null>();

    useEffect(() => {
      const row = requests?.find((r) => r.id_ === selectedID)!;
      if (!row) return;
      const rowCopy = JSON.parse(JSON.stringify(row));
      setEditableRow(rowCopy);
    }, [selectedID, requests]);

    useEffect(() => {
      syncSectors(selectedID as number);
    }, [selectedID]);

    if (!editableRow) return null;
    let lastUpdated = editableRow.updated_at
      ? new Date(editableRow.updated_at)
      : null;

    const handleRowUpdate = async () => {
      let ok = await updateRequest(editableRow.id_, editableRow);
      ok = ok && (await applyChanges(selectedID as number));
      if (!ok) {
        handleProcessRowUpdateError(ok);
      } else {
        setSnackbar({
          children: "Request successfully updated",
          severity: "success",
        });
      }
    };

    return (
      <Modal
        open={isOpen}
        onClose={() => {
          onClose();
          setCurrentOption("Overview");
        }}
      >
        <>
          <div className="flex h-[40rem] w-[60rem] bg-neutral-300 absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg ">
            <SidePanel
              currentOption={currentOption}
              setCurrentOption={setCurrentOption}
            />
            <div className="p-4 w-[calc(100%-10rem)] text-neutral-900 flex-none">
              <div className="text-2xl flex justify-between w-full">
                <div className="flex">
                  <Dropdown
                    className="w-40"
                    list={dropdownList}
                    selectedValue={selectedID ? selectedID : ""}
                    onChange={(value) =>
                      setSelectedID && setSelectedID(parseInt(value))
                    }
                  />
                  <div className="ml-2 text-xs text-neutral-600">
                    Last updated <br />
                    {lastUpdated?.toLocaleDateString("SE")}{" "}
                  </div>
                </div>
                <Button size="medium" onClick={() => handleRowUpdate()}>
                  Apply Changes
                </Button>
              </div>
              <div className="my-4">
                <Divider />
              </div>
              <div className="py-4 mx-2 flex flex-col">
                <div className="h-full">
                  {currentOption === "Overview" && (
                    <OverviewPage
                      editableRow={editableRow}
                      setEditableRow={setEditableRow}
                    />
                  )}
                  {currentOption === "General" && (
                    <GeneralPage
                      editableRow={editableRow}
                      setEditableRow={setEditableRow}
                    />
                  )}
                  {currentOption === "Profile" && <RequestProfile />}
                  {currentOption === "Residential" && <ResidentialPanel />}

                  {currentOption === "Industry" && <IndustryPanel />}
                  {currentOption === "Public" && <PublicPanel />}
                  {currentOption === "Commercial" && <CommercialPanel />}
                </div>
              </div>
            </div>
          </div>
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </>
      </Modal>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isOpen === nextProps.isOpen &&
      prevProps.selectedID === nextProps.selectedID
    );
  }
);
