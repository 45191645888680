import RoundedContainer from "@/components/rounded-container";

interface FlexDemandProps {
  location: number;
  year: number;
}
interface FlexData {
  year: number[];
  value: number[];
}

function getValueYear(year: number, data: FlexData) {
  if (!data.year) {
    return 0;
  }
  if (data.year.indexOf(year) === -1) {
    return 0;
  }
  const index = data.year.indexOf(year);
  return data.value[index];
}

export function FlexDemand(props: FlexDemandProps) {
  const demand_items = [
    {
      name: "Hours",
      value: "-",
      bg: "linear-gradient(90deg, rgba(185, 212, 224, 1), rgba(185, 215, 224, 0.25))",
    },
    {
      name: "Energy",
      value: "-",
      bg: "linear-gradient(90deg, rgba(224, 199, 185, 1), rgba(224, 199, 185, 0.25))",
    },
    {
      name: "Power",
      value: "-",
      bg: "linear-gradient(90deg, rgba(209, 224, 185, 1), rgba(209, 224, 185, 0.25))",
    },
  ];

  return (
    <RoundedContainer className="grid grid-row-4 h-full">
      <div className="text-xl my-2 ">Flex Demand</div>
      {demand_items.map((item) => {
        return (
          <div
            key={`${item.name}-${"-"}`}
            className="rounded-lg text-xs my-1.5 p-1 px-4 items-center flex flex-row justify-between"
            style={{ background: item.bg }}
          >
            <div className="text-base font-medium">{item.name}</div>
            <div className="text-base ">{"-"}</div>
          </div>
        );
      })}
    </RoundedContainer>
  );
}
