import { LatLngExpression } from "leaflet";
import { Marker, Tooltip } from "react-leaflet";
import { useRequestMarkers } from "../hooks/useRequestMarkers";

export default function RequestMarkers() {
  const { markers } = useRequestMarkers();

  return (
    <>
      {markers &&
        markers.map(({ location, name }, index) => {
          return (
            <Marker key={index} position={location as LatLngExpression}>
              <Tooltip>
                <div className="text-lg font-bold">{name}</div>
              </Tooltip>
            </Marker>
          );
        })}
    </>
  );
}
