import { Request } from "@/api/request/request";
import { Input } from "@/components/Input";
import Dropdown from "@/components/OldDropdown";
import { Local } from "@/pages/requests/helper/api";
import { Divider } from "@mui/material";
import { useEffect } from "react";

interface ProjectSettingsProps {
  request: Local<Request>;
  setRequest: (value: Local<Request>) => void;
  setIsNextDisabled?: (value: boolean) => void;
}

export function ProjectSettings(props: ProjectSettingsProps) {
  const { request, setRequest, setIsNextDisabled } = props;

  let nameValid = request.name ? true : false;
  let responsibleValid = request.responsible ? true : false;
  let completionYearValid =
    request.completion_date && request.completion_date.length === 4
      ? true
      : false;
  let startYearValid =
    request.start_date && request.start_date.length === 4 ? true : false;

  useEffect(() => {
    if (!setIsNextDisabled) return;
    if (nameValid && completionYearValid && startYearValid) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(true);
    }
  }, [nameValid, completionYearValid, startYearValid]);
  return (
    <>
      <Divider className="my-4 text-sm text-neutral-700" textAlign="left">
        {" "}
        Project{" "}
      </Divider>
      <div className="grid grid-cols-2 gap-4 grid-flow-col grid-rows-3">
        <Input
          className="w-40"
          title="Project Name"
          size="small"
          value={request.name}
          onChange={(value) =>
            setRequest({ ...request, name: value as string })
          }
          statusColor={nameValid ? "green" : "red"}
        ></Input>
        <Input
          className="w-40"
          title="Responsible"
          size="small"
          value={request.responsible}
          onChange={(value) =>
            setRequest({ ...request, responsible: value as string })
          }
          statusColor={responsibleValid ? "green" : "yellow"}
        ></Input>
        <div className="flex">
          <Dropdown
            className="w-40"
            list={[
              { value: "not_started", name: "Not started" },
              { value: "ongoing_planning", name: "Planning in progress" },
              { value: "planning_completed", name: "Planing completed" },
              { value: "construction", name: "Under Construction" },
              { value: "paused", name: "Paused" },
            ]}
            selectedValue={request.status ? request.status : ""}
            onChange={(value) =>
              setRequest({ ...request, status: value as string })
            }
            size="small"
            title="Status"
          ></Dropdown>
        </div>
        <Input
          className="w-40"
          title="Start year"
          size="small"
          placeholder="YYYY"
          value={request.start_date}
          onChange={(value) =>
            setRequest({ ...request, start_date: value as string })
          }
          statusColor={startYearValid ? "green" : "red"}
        ></Input>
        <Input
          className="w-40"
          title="Completion year"
          size="small"
          placeholder="YYYY"
          value={request.completion_date}
          onChange={(value) =>
            setRequest({ ...request, completion_date: value as string })
          }
          statusColor={completionYearValid ? "green" : "red"}
        ></Input>

        <Input
          className="mb-4 w-40 "
          title="Plan last updated"
          size="small"
          placeholder="YYYY-MM-DD"
        ></Input>
        <div> </div>
      </div>
    </>
  );
}
