import { getColor } from "../../../Colors";
import SeriesSort from "../../SeriesSort";
import { inputData, optionProps } from "../types";

function opacity(year: number, selectedYear: number | undefined) {
  if (!selectedYear) {
    return 1;
  }
  return year === selectedYear ? 1 : 0.3;
}

export default function processData(data: inputData) {
  // Your formatting logic here
  const { selectedYear, selectedDataColumn } = data;
  let result: optionProps = { xAxis: [], legend: {}, series: [] };
  result.xAxis = data.dataItem.year;
  let totalSeriesData = new Array(data.dataItem.year.length).fill(0);
  for (let key_string of Object.keys(data.dataItem)) {
    // Iterate through each column of series that is not time
    const key = key_string as keyof typeof data.dataItem;
    if (key === "year") {
      continue;
    }
    // If it doesn't, create a new series
    result.series.push({
      name: `${key}`,
      type: "bar",
      sampling: "max",
      barGap: 0.1,
      stack: "stack",
      stackStrategy: "samesign",
      emphasis: {
        //disabled: true,
        focus: "series",
      },
      itemStyle: {
        color: getColor(key, selectedDataColumn),
      },
      data: data.dataItem[key].map((columnValue: number, i: number) => {
        totalSeriesData[i] += columnValue;
        return {
          value: columnValue,
          itemStyle: { opacity: opacity(data.dataItem.year[i], selectedYear) },
        };
      }),
    });
  }
  // Add the total series
  result.series.push({
    name: "Total",
    type: "line",
    stack: "self",
    emphasis: {
      //disabled: true,
      focus: "series",
    },
    symbolSize: 5,
    itemStyle: {
      color: "black",
    },
    lineStyle: {
      color: "black",
    },
    data: totalSeriesData,
  });
  // Sort the series by name.
  // This is in order to ensure it can be referenced
  // easily with other charts
  result.series.sort(SeriesSort);
  return result;
}
