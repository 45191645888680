import SubmenuStore from "@/hooks/submenu";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import { SyntheticEvent, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { BehaviourSettings } from "./BehaviourSettings";
import { ClimateSettings } from "./ClimateSettings";
import { GrowthSettings } from "./GrowthSettings";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

export function Settings() {
  const { hideSubmenu } = SubmenuStore((state) => state);
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <div className="h-full overflow-y-scroll max-h-[95vh]">
      <div className="flex justify-end">
        <button
          onClick={() => {
            hideSubmenu();
          }}
          className="p-2"
        >
          <CloseIcon />
        </button>
      </div>
      <h1 className="font-medium text-2xl text-left pl-4">Scenarios</h1>
      <div className="p-4">
        Here you can adjust the parameters to create unique scenarios. <br />
        <br />
        These settings does currently not affect map layers.
      </div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{ paddingX: "1rem" }}
        >
          <div className="text-neutral-800 font-medium text-xl">Climate</div>
        </AccordionSummary>
        <AccordionDetails>
          <ClimateSettings />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          sx={{ paddingX: "1rem" }}
        >
          <div className="text-neutral-800 font-medium text-xl">Growth</div>
        </AccordionSummary>
        <AccordionDetails>
          <GrowthSettings />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          sx={{ paddingX: "1rem" }}
        >
          <div className="text-neutral-800 font-medium text-xl">Behaviour</div>
        </AccordionSummary>
        <AccordionDetails>
          <BehaviourSettings />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
