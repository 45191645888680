/// <reference types="vite-plugin-svgr/client" />
import LeftArrow from "@/assets/Left arrow.svg?react";
import RightArrow from "@/assets/Right arrow.svg?react";
import car from "@/assets/car.png";
import city from "@/assets/city.png";
import RoundedContainer from "@/components/rounded-container";
import { useLocationStore } from "@/stores";
import { max, min } from "mathjs";
import { useState } from "react";
import { FlexDemand } from "./components/FlexDemand";
import { YearlyGrowth } from "./components/YearlyGrowth";
function City() {
  return (
    <RoundedContainer background_img={city}>
      <div></div>
    </RoundedContainer>
  );
}
function Car() {
  return (
    <RoundedContainer background_img={car}>
      <div></div>
    </RoundedContainer>
  );
}

export function Home() {
  const { location } = useLocationStore();
  const [year, setYear] = useState<number>(2025);
  return (
    <div className="text-neutral-800 flex flex-col h-full ">
      <div className="flex flex-col grow w-full h-1/4 p-2 m-2">
        <div className="text-3xl antialiased font-bold">Welcome! </div>
        <div className="flex justify-between">
          <div className="text-sm my-2 ">Here is an overview of KPIs</div>
          <div className="flex space-x-2 mr-8 mt-8">
            <button
              className="p-2 bg-neutral-400 rounded-full"
              onClick={() => setYear(max(year - 1, 2025))}
            >
              <LeftArrow className="h-4 fill-neutral-700" />
            </button>
            <div className="bg-neutral-400 rounded-full text-neutral-700 p-2">
              {year}
            </div>
            <button
              className="p-2 bg-neutral-400 rounded-full"
              onClick={() => setYear(min(year + 1, 2034))}
            >
              <RightArrow className="h-4 fill-neutral-700" />
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 mb-8 h-full">
        <City />
        <YearlyGrowth location={location} year={year} />
        <FlexDemand location={location} year={year} />
        <Car />
      </div>
    </div>
  );
}
