import { Commercial } from "@/api/request/commercial";
import { Industry } from "@/api/request/industry";
import { Public } from "@/api/request/public";
import { ProfileData, useGetProfile } from "@/api/request/request";
import { Residential } from "@/api/request/residential";
import { LoadingSymbol } from "@/components/LoadingSymbol";
import { Button } from "@/components/NewButton";
import { Local, omitLocal } from "@/pages/requests/helper/api";
import { memo, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Graph } from "./Graph";

function getColor<T>(key: keyof T) {
  if (key === "Industry") return "#3ba272";
  if (key === "Residential") return "#73c0de";
  if (key === "Commercial") return "#5470c6";
  if (key === "Public") return "#ee6666";
  return "grey";
}

interface Sectors {
  industry?: Local<Industry>[];
  residential?: Local<Residential>[];
  public?: Local<Public>[];
  commercial?: Local<Commercial>[];
}

interface RequestGraphProps {
  sectors?: Sectors;
  height?: string;
  hidden?: boolean;
  className?: string;
}

export const RequestGraph = memo(
  (props: RequestGraphProps) => {
    const { sectors, height, hidden, className } = props;
    const { fetchData, loading } = useGetProfile();
    const [data, setData] = useState<ProfileData | null>(null);
    const [duration, setDuration] = useState(false);
    if (!sectors) {
      return (
        <div className="flex justify-center w-full relative" style={{ height }}>
          <div className="absolute inset-y-2/4 -translate-y-1/2">
            <LoadingSymbol></LoadingSymbol>
          </div>
        </div>
      );
    }
    useEffect(() => {
      async function async_() {
        if (!sectors) {
          setData(null);
          return;
        }
        if (
          !sectors.industry &&
          !sectors.residential &&
          !sectors.public &&
          !sectors.commercial
        ) {
          setData(null);
          return;
        }
        let data_ = await fetchData({
          industry: sectors.industry?.map(omitLocal),
          residential: sectors.residential?.map(omitLocal),
          public: sectors.public?.map(omitLocal),
          commercial: sectors.commercial?.map(omitLocal),
        });
        setData(data_);
      }
      async_();
    }, [sectors]);

    if (loading) {
      return (
        <div className="flex justify-center w-full relative" style={{ height }}>
          <div className="absolute inset-y-2/4 -translate-y-1/2">
            <LoadingSymbol></LoadingSymbol>
          </div>
        </div>
      );
    }
    return (
      <div
        className={twMerge(
          "",
          hidden ? "invisible h-0 opacity-0" : "visible opacity-100"
        )}
      >
        <div
          className={twMerge(
            "bg-neutral-100 shadow-md rounded-md w-full ring-1 ring-neutral-400 m-1",
            className
          )}
        >
          <div className="flex justify-end pt-3 pr-3 space-x-2">
            <Button
              className="bg-neutral-300"
              selected={duration}
              onClick={() => setDuration(true)}
            >
              Duration
            </Button>
            <Button
              className="bg-neutral-300"
              selected={!duration}
              onClick={() => setDuration(false)}
            >
              Hourly Power
            </Button>
          </div>
          {data && (
            <Graph
              rawData={data}
              getColor={getColor}
              graphType={duration ? "Duration" : "Hourly"}
              height={height}
            ></Graph>
          )}
        </div>
      </div>
    );
  },
  (prev, next) => {
    return prev.sectors === next.sectors && prev.hidden === next.hidden;
  }
);
