import { Request } from "@/api/request/request";
import { Local } from "@/pages/requests/helper/api";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { Map } from "./Map";

interface Part1Props {
  request: Local<Request>;
  setRequest: (value: Local<Request>) => void;
  setIsNextDisabled: (value: boolean) => void;
}

export function Part1(props: Part1Props) {
  const { setIsNextDisabled, request, setRequest } = props;

  let coordinates =
    request?.longitude && request?.latitude
      ? { lat: request?.latitude, lng: request?.longitude }
      : null;
  let substation = request?.substation || "";
  useEffect(() => {
    if (substation === "" || !coordinates) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [substation]);
  return (
    <div>
      <div className="text-neutral-800 text-center mb-10 text-2xl">
        Select a location on map
      </div>

      <div className="">
        <div
          className={twMerge(
            "text-neutral-700 text-center text-sm font-light transition-all duration-500",
            substation === "" ? "opacity-0" : "opacity-100"
          )}
        >
          Substation: {substation}, Coordinates: {coordinates?.lat.toFixed(3)},{" "}
          {coordinates?.lng.toFixed(3)}
        </div>
        <Map
          setSubstation={(value) => {
            setRequest({ ...request, substation: [value] });
          }}
          coordinates={coordinates}
          setMarkers={(value) => {
            setRequest({
              ...request,
              latitude: value.lat,
              longitude: value.lng,
              substation: [value.substation],
            });
          }}
        ></Map>
      </div>
    </div>
  );
}
