import * as React from "react";
import { twMerge } from "tailwind-merge";
interface RoundedContainerProps {
  children?: React.ReactNode;
  className?: string;
  classNameOuter?: string;
  style?: string;
  background_img?: string;
}

export default function RoundedContainer(props: RoundedContainerProps) {
  const style: React.CSSProperties = {
    backgroundImage: props.background_img ? `url(${props.background_img})` : "",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div className={twMerge("p-2", props.classNameOuter)}>
      <div
        className={twMerge(
          "p-3 bg-neutral-100 h-full rounded-xl shadow-lg",
          props.className
        )}
        style={style}
      >
        {props.children}
      </div>
    </div>
  );
}
