import { Public } from "@/api/request/public";
import { Input } from "@/components/Input";
import { StatusColor } from "@/components/StatusDot";
import { Local } from "@/pages/requests/helper/api";
import { useEffect } from "react";
import { EditorProps } from "../types";
import { BorderWrap } from "./BorderWrap";
import { Container } from "./Container";

type PublicColor = Partial<{
  [key in keyof Local<Public>]: StatusColor | undefined;
}>;

function getColor(public_: Local<Public>): PublicColor {
  const publicArea = public_.area && public_.area > 0;
  const publicDemand = public_.power_demand && public_.power_demand > 0;
  const publicEnergyPerf =
    public_.energy_performance && public_.energy_performance > 0;

  const publicColors: { [key: string]: StatusColor } = {
    solar_panels:
      public_.solar_power && public_.solar_power > 0 ? "green" : "yellow",
    area:
      public_.forecast_metric === "area"
        ? publicArea
          ? "green"
          : "red"
        : undefined,
    power_demand:
      public_.forecast_metric === "demand"
        ? publicDemand
          ? "green"
          : "red"
        : undefined,
    energy_performance:
      public_.forecast_metric === "area"
        ? publicEnergyPerf
          ? "green"
          : "red"
        : undefined,
  };
  return publicColors;
}

function getStatus(publicColors: PublicColor) {
  const PublicComplete =
    Object.keys(publicColors).length > 0 &&
    (publicColors.area === "green" || publicColors.forecast_metric === "green");
  return PublicComplete;
}

interface PublicEditorProps extends EditorProps {
  sector?: Local<Public>;
  setSector?: (value: Local<Public>) => void;
  setStatus?: (value: boolean) => void;
  hidden?: boolean;
}

export function PublicEditor(props: PublicEditorProps) {
  const { sector, setSector, setStatus, className } = props;

  if (!sector || !setSector) return null;
  // logic for automatically changing forecast_metric

  const colors = getColor(sector);
  const status = getStatus(colors);

  useEffect(() => {
    if (setStatus) {
      setStatus(status);
    }
  }, [status]);

  return (
    <Container className={className}>
      <BorderWrap
        legend="Profile from area"
        selected={sector.forecast_metric === "area"}
        onClick={() => {
          setSector({ ...sector, forecast_metric: "area" });
        }}
      >
        <div className="space-y-4 pb-4">
          <Input
            title="Total area"
            className="w-full"
            statusColor={colors.area}
            value={sector.area}
            onChange={(value) => {
              setSector({ ...sector, area: Number(value) });
            }}
            unit="m²"
            type="number"
          />
          <Input
            title="Energy Performance"
            className="w-full"
            statusColor={colors.energy_performance}
            value={
              sector.energy_performance && sector.energy_performance / 1000
            }
            onChange={(value) => {
              setSector({
                ...sector,
                energy_performance: Number(value) * 1000,
              });
            }}
            unit="kwh/m²/year"
            type="number"
          />
        </div>
      </BorderWrap>
      <BorderWrap
        legend="Profile from power"
        selected={sector.forecast_metric === "demand"}
        onClick={() => {
          setSector({ ...sector, forecast_metric: "demand" });
        }}
      >
        <Input
          title="Requested power"
          className="w-full"
          statusColor={colors.power_demand}
          value={sector.power_demand && sector.power_demand / 1000}
          onChange={(value) => {
            setSector({ ...sector, power_demand: Number(value) * 1000 });
          }}
          type="number"
          unit="kW"
        />
      </BorderWrap>
      <Input
        title="Solar PV capacity"
        className="w-full"
        statusColor={colors.solar_power}
        value={sector.solar_power && sector.solar_power / 1000}
        onChange={(value) => {
          setSector({ ...sector, solar_power: Number(value) * 1000 });
        }}
        unit="kW"
        type="number"
      />
    </Container>
  );
}
