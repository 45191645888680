import Button from "@/components/Button";
import SliderSettings from "@/components/SliderSettings";
import { useSettingsStore } from "@/pages/forecast/stores/SettingsStore";
import { useGrowthSliders } from "../hooks/GrowthSliders";

export interface GrowthSettingsProps {
  children?: React.ReactNode;
}

export function GrowthSettings(props: GrowthSettingsProps) {
  const { growthSliders } = useGrowthSliders();
  const { resetGrowthNumbers } = useSettingsStore();
  return (
    <div className="mt-[-1rem]">
      <div className="flex justify-between mr-8">
        These settings regard the annual growth.
        <Button
          onClick={() => resetGrowthNumbers()}
          type="secondary"
          size="small"
          className="hover:bg-red-500-75"
        >
          Reset
        </Button>
      </div>
      <SliderSettings sliderSettings={growthSliders} />
    </div>
  );
}
