import { create } from "zustand";

export type Category = "energy" | "power" | "utilization";
interface tilesSettingsStore {
  category: Category;
  absolute: boolean;
  setCategory: (category: Category) => void;
  setAbsolute: (absolute: boolean) => void;
}

export const useTilesSettingsStore = create<tilesSettingsStore>((set) => ({
  category: "energy",
  absolute: true,
  setCategory: (category) => set({ category }),
  setAbsolute: (absolute) => set({ absolute }),
}));
