import { LoadingSymbol } from "@/components/LoadingSymbol";

interface TilesLoadingSymbolProps {
  loading?: boolean;
}

export function TilesLoadingSymbol(props: TilesLoadingSymbolProps) {
  const { loading } = props;
  return (
    <div className="leaflet-top leaflet-left bottom-1 left-[0.24rem] top-[5.5rem] m-0 p-1 px-2 py-1 mb-0  rounded-md flex w-fit">
      <LoadingSymbol hide={!loading}></LoadingSymbol>
    </div>
  );
}
