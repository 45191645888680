export const LowToHighMarks = [
  {
    value: 0,
    label: "Low",
  },
  {
    value: 1,
    label: "Base",
  },
  {
    value: 2,
    label: "High",
  },
];
