interface TooltipProps {
  message: React.ReactNode;
  children: React.ReactNode;
}

export function Tooltip(props: TooltipProps) {
  const { message, children } = props;
  if (!message) return <>{children}</>;
  return (
    <>
      <div className="group relative">
        {children}
        <span className="absolute top-10 -left-5 z-[500] rounded-md shadow-lg scale-0 transition-all bg-neutral-100 p-2 text-xs text-neutral-800 group-hover:scale-100 group-hover:visible invisible">
          {message}
        </span>
      </div>
    </>
  );
}
