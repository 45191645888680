import Dropdown from "@/components/OldDropdown";
import { useSettingsStore } from "@/pages/forecast/stores/SettingsStore";
import { number } from "mathjs";

export interface ClimateSettingsProps {
  children?: React.ReactNode;
}

const years = [
  { name: "2019", value: "2019" },
  { name: "2020", value: "2020" },
  { name: "2021", value: "2021" },
  { name: "2022", value: "2022" },
  { name: "2023", value: "2023" },
];

export function ClimateSettings(props: ClimateSettingsProps) {
  const { climateNumbers, setClimateNumbers } = useSettingsStore();
  return (
    <div className="p-2 w-60">
      Temperature year
      <Dropdown
        list={years}
        selectedValue={climateNumbers.heating.toString()}
        onChange={(value: string) =>
          setClimateNumbers({ heating: number(value) })
        }
      />
    </div>
  );
}
//<SliderSettings sliderSettings={ClimateSliders} />
