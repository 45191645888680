interface SuffixFormatterArgs {
  value: number;
  type?: 'both' | 'suffix' | 'value';
  precision?: number;
  forAxes?: boolean;
}

/* Returns the number of digits in a float excluding decimals*/
function numDigits(x: number) {
  return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;
}

export const suffixFormatter = ({ value, type = 'both', precision = 3, forAxes = false }: SuffixFormatterArgs) => {
  if (forAxes) precision = 0;
  let dividCount = 0;
  while (Math.abs(value) >= 1000) {
    value = value / 1000;
    dividCount++;
  }
  let suffix = "";
  switch (dividCount) {
    case 1:
      suffix = "k";
      break;
    case 2:
      suffix = "M";
      break;
    case 3:
      suffix = "G";
      break;
    case 4:
      suffix = "T";
      break;
    default:
      break;
  }
  if (type === 'suffix') {
    return suffix;
  }
  let valueStr = value.toFixed(Math.max(precision - numDigits(value), 0)).toString();
  if (forAxes) {
    if (Math.abs(value) > 10 && Math.abs(value) < 100) {
      valueStr = value.toFixed(0).toString();
    } else if (Math.abs(value) < 10 && Math.abs(value) >= 1) {
      valueStr = value.toFixed(1).toString();
    } else if (Math.abs(value) < 1) {
      valueStr = value.toFixed(2).toString();
    } else if (value == 0) {
      valueStr = "0";
    }
  }
  if (type === 'value') {
    return valueStr;
  }

  return `${valueStr} ${suffix}`;
};
