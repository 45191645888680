/// <reference types="vite-plugin-svgr/client" />
import Energy from "@/assets/Energy.svg?react";
import HourlyPower from "@/assets/Hourly power.svg?react";
import CustomRadio from "@/components/RadioButton";
import RoundedContainer from "@/components/rounded-container";
import { Columns } from "@/pages/forecast/types";
import ReactECharts from "echarts-for-react";
import { useCallback, useEffect, useState } from "react";
import { useOption } from "../hooks/Option";

export interface GrowthFigureProps {
  growthRef: React.MutableRefObject<ReactECharts | null>;
  onGrowthEvents: any;
  substations: string[];
  selected_values: keyof Columns;
  selectedYear: number;
}

export function GrowthFigure(props: GrowthFigureProps) {
  const {
    growthRef,
    onGrowthEvents,
    substations,
    selected_values,
    selectedYear,
  } = props;

  const [relative, setRelative] = useState<boolean>(false); // [object Object]

  const [energyVPower, setEnergyVPower] = useState<
    "energy" | "power" | "min power"
  >("power"); // ["energy", "power"]
  const [selectedLegends, setSelectedLegends] = useState<{
    [key: string]: boolean;
  }>({}); // [object Object

  useEffect(() => {
    setSelectedLegends({});
  }, [selected_values]);

  const { option, isLoading } = useOption(
    substations,
    selected_values,
    relative,
    energyVPower,
    selectedYear,
    selectedLegends
  );

  useEffect(() => {
    if (selected_values === "Solar PV" && energyVPower === "power") {
      setEnergyVPower("min power");
    }
  }, [selected_values]);

  function onValueChange(event: any) {
    // Updating the state with the selected radio button's value
    setEnergyVPower(event.target.value);
  }
  onGrowthEvents["legendselectchanged"] = useCallback((params: any) => {
    if (!params.selected) return;
    setSelectedLegends(params.selected);
  }, []);
  return (
    <RoundedContainer className="pb-0 flex flex-col" classNameOuter="h-2/5">
      <div className="flex justify-between">
        <div>
          <div className="text-neutral-800 text-xl mt-2 ">Demand</div>
          <div className="text-neutral-500 text-sm">
            Annual energy/power demand
          </div>
        </div>
        <div>
          <div className="flex px-3 py-1 space-x-2">
            <CustomRadio
              label={"Max Power"}
              value={"power"}
              name={"type_"}
              svgImg={HourlyPower}
              onChange={onValueChange}
              checkedValue={energyVPower}
            />
            <CustomRadio
              label={"Min Power"}
              value={"min power"}
              name={"type_"}
              svgImg={HourlyPower}
              onChange={onValueChange}
              checkedValue={energyVPower}
            />
            <CustomRadio
              label={"Energy"}
              value={"energy"}
              svgImg={Energy}
              name={"type_"}
              onChange={onValueChange}
              checkedValue={energyVPower}
            />
          </div>
          <div className="flex justify-end px-3 py-2 space-x-2">
            <CustomRadio
              label={"Growth"}
              value={1}
              name={"type_"}
              onChange={() => setRelative(true)}
              checkedValue={relative ? 1 : 0}
            />
            <CustomRadio
              label={"Absolute"}
              value={0}
              name={"type_"}
              onChange={() => setRelative(false)}
              checkedValue={relative ? 1 : 0}
            />
          </div>
        </div>
      </div>
      <ReactECharts
        option={option}
        ref={growthRef}
        onEvents={onGrowthEvents}
        notMerge={true}
        className="grow"
        style={{ height: "100%" }}
        showLoading={isLoading}
        onChartReady={(echarts: any) =>
          isLoading ? echarts.showLoading() : echarts.hideLoading()
        }
      />
    </RoundedContainer>
  );
}
