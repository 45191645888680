import { useGetEnergy, useGetMax } from "@/api/forecast";
import { useGetGridCapacity } from "@/api/grid";
import { useSettingsStore } from "@/pages/forecast/stores/SettingsStore";
import { mean } from "@/utils/SMaths";
import { max } from "mathjs";
import { useEffect, useState } from "react";

export function useHandleData(year: number) {
  const [utilizations, setUtilizations] = useState<number[] | null>(null);

  const settings = useSettingsStore((state) => state.getFormattedSettings());

  const {
    data: energyData,
    refreshData: resfreshEnergyData,
    loading: energyLoading,
  } = useGetEnergy([year], undefined, undefined, true, settings);

  const {
    data: maxPowerData,
    refreshData: refreshMaxPowerData,
    loading: maxPowerLoading,
  } = useGetMax([year], undefined, undefined, true, settings);

  const {
    data: gridCapacityData,
    refreshData: refreshGridCapacityData,
    loading: gridCapacityLoading,
  } = useGetGridCapacity();

  useEffect(() => {
    refreshGridCapacityData();
  }, []);

  useEffect(() => {
    if (!gridCapacityData) return;
    if (!maxPowerData) return;
    let sortedMaxPowerData = maxPowerData.sort((a, b) =>
      a.station_id.localeCompare(b.station_id)
    );
    let sortedGridCapacityData = gridCapacityData.sort((a, b) =>
      a.station_id.localeCompare(b.station_id)
    );
    // join
    let allUtilizations: number[] = [];
    sortedMaxPowerData.forEach((d, i) => {
      let mathingStation = sortedGridCapacityData.find(
        (value) => value.station_id === d.station_id
      );
      if (!mathingStation) return;
      if (mathingStation.transformer_capacity === 0) return;
      allUtilizations.push(
        (d.power / mathingStation.transformer_capacity) * 100
      );
    });
    setUtilizations(allUtilizations);
  }, [gridCapacityData, maxPowerData]);

  useEffect(() => {
    refreshMaxPowerData();
    resfreshEnergyData();
    setTimeout(() => {}, 500);
  }, [year]);

  return {
    energy: energyData ? max(energyData?.map((e) => e.energy)) : null,
    utilizationRate: utilizations ? mean(...utilizations) : null,
    overloads: utilizations ? utilizations.filter((u) => u > 100).length : null,
    loading: maxPowerLoading || gridCapacityLoading,
  };
}
