import { useApi } from "@/services/Api";
import { useGenericGet } from "./generic";
import { Sector } from "./request";

export interface Commercial extends Sector {
  power_demand?: number;
  area?: number;
  forecast_metric?: "demand" | "area";
  energy_performance: number;
}

export function useUpdateCommercial() {
  const { loading, fetchData } = useApi("PUT");
  async function updateCommercial(newRow: Commercial) {
    return fetchData("request/commercial/", newRow).then((data) => {
      return data;
    });
  }
  return { loading, updateCommercial };
}

export function useInsertCommercial() {
  const { loading, fetchData } = useApi("POST");
  async function insertCommercial(newRow: Commercial) {
    return fetchData("request/commercial/", newRow);
  }
  return { loading, insertCommercial };
}

export function useDeleteCommercial() {
  const { loading, fetchData } = useApi("DELETE");
  async function deleteCommercial(id: number) {
    return fetchData("request/commercial/" + id);
  }
  return { loading, deleteCommercial };
}

export function useGetIndustries(request_id: number) {
  let endpoint = "request/commercials/" + request_id;
  return useGenericGet<Commercial[]>(endpoint);
}
