import { useApi } from "@/services/Api";
import { useGenericGet } from "./generic";
import { Sector } from "./request";

export interface Residential extends Sector {
  subsector: "single_family" | "multi_family";
  area?: number;
  power_demand?: number;
  forecast_metric?: "demand" | "area" | "dwellings";
  dwellings?: number;
  dwelling_area?: number;
  electric_heating: boolean;
  energy_performance: number;
}

export function useUpdateResidential() {
  const { loading, fetchData } = useApi("PUT");
  async function updateResidential(newRow: Residential) {
    return fetchData("request/residential/", newRow).then((data) => {
      return data;
    });
  }
  return { loading, updateResidential };
}

export function useInsertResidential() {
  const { loading, fetchData } = useApi("POST");
  async function insertResidential(newRow: Residential) {
    return fetchData("request/residential/", newRow);
  }
  return { loading, insertResidential };
}

export function useDeleteResidential() {
  const { loading, fetchData } = useApi("DELETE");
  async function deleteResidential(id: number) {
    return fetchData("request/residential/" + id);
  }
  return { loading, deleteResidential };
}

export function useGetIndustries(request_id: number) {
  let endpoint = "request/residentials/" + request_id;
  return useGenericGet<Residential[]>(endpoint);
}
