import { GrowthColumns } from "@/pages/forecast/features/GrowthFigure/types";
import { IFormattedSettings } from "@/pages/forecast/stores/SettingsStore";
import { useLocationStore } from "@/stores";
import { useAuth0 } from "@auth0/auth0-react";
import { useGenericGet } from "./utils";

function addScenarioArgs(endpoint: string, args?: IFormattedSettings) {
  if (!args) {
    return endpoint;
  }
  args.growth.forEach((row) => {
    endpoint += "&scenario=" + row;
  });
  (Object.keys(args) as (keyof IFormattedSettings)[]).forEach((key) => {
    if (key === "growth") {
      return;
    }

    endpoint += "&" + key + "=" + args[key];
  });
  return endpoint;
}

export interface MaxDataRow {
  year: number;
  station_id: string;
  category: GrowthColumns;
  time: string;
  power: number;
}
export type MaxData = MaxDataRow[];

export function useGetMax(
  years: number[],
  station_ids?: string[],
  category?: string | null,
  total?: boolean,
  args?: IFormattedSettings
) {
  const { location } = useLocationStore();
  const { user } = useAuth0();
  let endpoint =
    "forecasts/max/" +
    user!.orgId +
    "/" +
    location +
    "?years=" +
    years.join("&years=");
  if (station_ids) {
    endpoint += "&station_ids=" + station_ids.join("&station_ids=");
  }
  if (category) {
    endpoint += "&category=" + category;
  }
  if (total) {
    endpoint += "&total=true";
  }
  if (args) {
    endpoint = addScenarioArgs(endpoint, args);
  }
  return useGenericGet<MaxData>(endpoint);
}

export function useGetMin(
  years: number[],
  station_ids?: string[],
  category?: string | null,
  total?: boolean,
  args?: IFormattedSettings
) {
  const { location } = useLocationStore();
  const { user } = useAuth0();
  let endpoint =
    "forecasts/min/" +
    user!.orgId +
    "/" +
    location +
    "?years=" +
    years.join("&years=");
  if (station_ids) {
    endpoint += "&station_ids=" + station_ids.join("&station_ids=");
  }
  if (category) {
    endpoint += "&category=" + category;
  }
  if (total) {
    endpoint += "&total=true";
  }
  if (args) {
    endpoint = addScenarioArgs(endpoint, args);
  }

  return useGenericGet<MaxData>(endpoint);
}

export function useGetEnergy(
  years: number[],
  station_ids?: string[],
  category?: string | null,
  total?: boolean,
  args?: IFormattedSettings
) {
  const { location } = useLocationStore();
  const { user } = useAuth0();
  let endpoint =
    "forecasts/energy/" +
    user!.orgId +
    "/" +
    location +
    "?years=" +
    years.join("&years=");
  if (station_ids) {
    endpoint += "&station_ids=" + station_ids.join("&station_ids=");
  }
  if (category) {
    endpoint += "&category=" + category;
  }
  if (total) {
    endpoint += "&total=true";
  }
  if (args) {
    endpoint = addScenarioArgs(endpoint, args);
  }

  return useGenericGet<MaxData>(endpoint);
}
