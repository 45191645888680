import { useCache } from "@/services/getCached";
import { useLocationStore } from "@/stores";
import { useHierarchyStore } from "../stores/levelStore";
import { GEOJSON } from "../types";

interface RawData {
  name?: string[] | null;
  station_id?: string[] | null;
}

export function useGetChildren(stationId: string) {
  const { location } = useLocationStore();
  const { level, deep } = useHierarchyStore();
  let endpoint =
    "grid/children/" + location + "/" + stationId + "/" + level + "?";

  if (deep) {
    endpoint += "deep=" + true + "&";
  }
  const { data, loading } = useCache<RawData>({
    endpoint: endpoint,
    cacheKey: endpoint,
  });

  return { data: data?.station_id, loading };
}

export function useGetTopStation() {
  const { location } = useLocationStore();
  const { data, loading } = useCache<RawData>({
    endpoint: "grid/topStation/" + location + "/",
    cacheKey: location.toString(),
  });
  return {
    topStationId: data && data?.station_id && data?.station_id[0],
    loading,
  };
}

export function useGetSubstationInfo() {
  const { location } = useLocationStore();
  const endpoint = "grid/info/" + location;
  const { data, loading } = useCache<RawData>({
    endpoint: endpoint,
    cacheKey: endpoint,
  });
  return { data: data, loading };
}

export function useGetTiles() {
  const { location } = useLocationStore();
  const { data, loading } = useCache<GEOJSON>({
    endpoint: "grid/" + location + "?",
    cacheKey: "grid/" + location + "?",
  });
  return { data: data, loading };
}

interface LevelInfo {
  min: number;
  max: number;
}
export function useGetLevels() {
  const { location } = useLocationStore();
  const { data, loading } = useCache<LevelInfo>({
    endpoint: "grid/getLevels/" + location + "/",
    cacheKey: "grid/getLevels/" + location + "?",
  });
  return { data, loading };
}
