import { create } from "zustand";

interface ApiStore {
  api: URL;
  setApi: (api: string) => void;
}

export const useApiStore = create<ApiStore>((set) => ({
  api: new URL(import.meta.env.VITE_API_URL as string),
  setApi: (api: string) => set({ api: new URL(api) }),
}));
