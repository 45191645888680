// ModernDropdown.tsx
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Fragment, createContext, useContext } from "react";
import { twMerge } from "tailwind-merge";

type Size = "small" | "medium" | "large";

interface IDropdownContext {
  size: Size;
}

const DropdownContext = createContext<IDropdownContext | undefined>(undefined);

// Types
export const useDropdown = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error("Dropdown components must be used within a Dropdown");
  }
  return context;
};

interface ListItem {
  value: string;
  name: string;
}

interface BaseDropdownProps {
  className?: string;
  disabled?: boolean;
  size?: Size;
}

interface RootProps extends BaseDropdownProps {
  value?: string;
  onChange?: (value: string) => void;
  title?: string;
  children: React.ReactNode;
}

// Constants
const SIZES = {
  small: "px-1 h-7 w-20 font-normal",
  medium: "px-1 h-7 w-30 font-normal",
  large: "px-1 h-9 w-40",
} as const;

const SIZES_TEXT = {
  small: "text-xs",
  medium: "text-sm",
  large: "text-base",
} as const;

// Components
export function Dropdown({
  children,
  value,
  onChange,
  title,
  size = "medium",
  className,
}: RootProps) {
  return (
    <DropdownContext.Provider value={{ size }}>
      <div className="">
        {title && (
          <div
            className={twMerge(
              "text-sm pb-1 text-neutral-700",
              SIZES_TEXT[size]
            )}
          >
            {title}
          </div>
        )}
        <Listbox value={value} onChange={onChange}>
          <div className={twMerge("relative w-full", className)}>
            {children}
          </div>
        </Listbox>
      </div>
    </DropdownContext.Provider>
  );
}

interface IDropdownButtonProps extends BaseDropdownProps {
  selectedItem?: ListItem;
}

export function DropdownButton({
  selectedItem,
  disabled,
}: IDropdownButtonProps) {
  const { size } = useDropdown();
  return (
    <ListboxButton
      className={twMerge(
        "relative w-full h-7 cursor-default rounded-md bg-neutral-100 ring-1 ring-neutral-200 py-1 px-2 pr-10 text-left shadow-sm",
        "focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300",
        SIZES_TEXT[size]
      )}
      aria-disabled={disabled}
    >
      <span className="block truncate text-neutral-800">
        {selectedItem?.name ?? ""}
      </span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
        <UnfoldMoreIcon style={{ color: "#949494", height: "1.2rem" }} />
      </span>
    </ListboxButton>
  );
}

interface IDropdownButtonProps extends BaseDropdownProps {
  children?: React.ReactNode;
}
export function DropdownButtonBare({ children }: IDropdownButtonProps) {
  return <ListboxButton>{children}</ListboxButton>;
}

interface IDropdownOptionsProps {
  children: React.ReactNode;
  className?: string;
}

export function DropdownOptions({
  children,
  className,
}: IDropdownOptionsProps) {
  const { size } = useDropdown();
  return (
    <Transition
      as={Fragment}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <ListboxOptions
        className={twMerge(
          "absolute mt-1 z-20 max-h-60 w-full overflow-auto rounded-md bg-white py-0 shadow-lg ring-1 ring-black/5",
          "focus:outline-none",
          SIZES_TEXT[size],
          className
        )}
      >
        {children}
      </ListboxOptions>
    </Transition>
  );
}

interface IDropdownOptionProps<T> {
  value: T;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

export function DropdownOption<T>({
  value,
  onClick,
  children,
  disabled,
  className,
}: IDropdownOptionProps<T>) {
  const { size } = useDropdown();
  return (
    <ListboxOption
      onClick={() => onClick?.()}
      value={value}
      disabled={disabled}
      className={({ focus }) =>
        twMerge(
          "relative cursor-default select-none py-2 pl-2 pr-4",
          focus ? "bg-primary-100 text-primary-600" : "text-gray-900",
          SIZES_TEXT[size],
          className
        )
      }
    >
      {children}
    </ListboxOption>
  );
}
