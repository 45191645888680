import { MaxData, useGetEnergy, useGetMax, useGetMin } from "@/api/forecast";
import { useSettingsStore } from "@/pages/forecast/stores/SettingsStore";
import { debugLog } from "@/utils/debugLog";
import { useEffect, useRef, useState } from "react";

interface Result {
  station_id?: string[];
  year: number[];
  time?: string[];
  [key: string]: number[] | string[] | undefined;
}

const YEARS = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034];

function makeRelative(data: Result) {
  let result = { ...data };
  Object.keys(result).forEach((key) => {
    if (key === "year") return;
    // @ts-ignore
    let first = result[key][0];
    // @ts-ignore
    result[key] = result[key].map((d) => d - first);
  });
  return result;
}

export function formatToApiData(data?: MaxData | null) {
  if (!data) return { station_id: [], year: [], time: [] };
  // sort the data by station_id, year, time

  let tempTest: { [key: number]: number } = {};
  data.forEach((d) => {
    if (!tempTest[d.year]) {
      tempTest[d.year] = 0;
    }
    tempTest[d.year] += d.power;
  });
  data.sort((a, b) => {
    if (a.station_id < b.station_id) return -1;
    if (a.station_id > b.station_id) return 1;
    if (a.year < b.year) return -1;
    if (a.year > b.year) return 1;
    if (a.time < b.time) return -1;
    if (a.time > b.time) return 1;
    return 0;
  });

  // create the data object
  let result: Result = { station_id: [], year: [], time: [] };
  let prevIndex = { station_id: "", year: 0, time: "" };
  data.forEach((d) => {
    if (!Object.keys(result).includes(d.category)) {
      result[d.category] = [] as number[];
    }

    if (!result[d.category]) {
      result[d.category] = [];
    }
    if (typeof d.power === "number") {
      // @ts-ignore
      result[d.category].push(d.power);
    }
    // @ts-ignore
    if (typeof d.energy === "number") {
      // @ts-ignore
      result[d.category].push(d.energy);
    }
    if (
      d.station_id === prevIndex.station_id &&
      d.year === prevIndex.year &&
      d.time === prevIndex.time
    ) {
      return;
    }
    prevIndex = { station_id: d.station_id, year: d.year, time: d.time };
    // @ts-ignore
    result.station_id.push(d.station_id);
    result.year.push(d.year);
    // @ts-ignore
    result.time.push(d.time);
  });
  delete result.station_id;
  delete result.time;
  if (Object.keys(result).includes("Requests")) {
    // Request can begin at later year than the first year
    // this will cause the length of the requests to be less than the year
    // fill the requests with 0 to match the year length
    if (result["Requests"]!.length !== result.year!.length) {
      let reqLength = result["Requests"]!.length;
      let expectedLength = result.year!.length;
      let diff = expectedLength - reqLength;
      result["Requests"] = new Array(diff).fill(0).concat(result["Requests"]!);
    }
  }
  return result;
}
export function useGetDemandData(
  energyVPower: "energy" | "power" | "min power",
  substations: string[],
  selectedDataColumn: string,
  relative: boolean
) {
  const settings = useSettingsStore((state) => state.getFormattedSettings());

  const {
    data: minPowerData,
    loading: loadingMinPowerData,
    refreshData: refreshMinPowerData,
  } = useGetMin(
    YEARS,
    substations,
    selectedDataColumn === "Total" ? null : selectedDataColumn,
    false,
    settings
  );

  const {
    data: maxPowerData,
    loading: loadingMaxPowerData,
    refreshData: refreshMaxPowerData,
  } = useGetMax(
    YEARS,
    substations,
    selectedDataColumn === "Total" ? null : selectedDataColumn,
    false,
    settings
  );

  const {
    data: energyData,
    loading: loadingEnergyData,
    refreshData: refreshEnergyData,
  } = useGetEnergy(
    YEARS,
    substations,
    selectedDataColumn === "Total" ? null : selectedDataColumn,
    false,
    settings
  );

  const [data, setData] = useState<any>(null);
  const [unprocessedData, setUnprocessedData] = useState<any>(null);
  let prevValues = useRef({ unprocessedData, relative });

  useEffect(() => {
    if (
      prevValues.current.unprocessedData === unprocessedData &&
      prevValues.current.relative === relative
    )
      return;
    let unformatedTempData = unprocessedData;
    if (!unformatedTempData) return;
    let tempData = formatToApiData(unformatedTempData);
    if (relative) {
      tempData = makeRelative(tempData);
    }
    setData(tempData);
    prevValues.current = { unprocessedData, relative };
  }, [relative, unprocessedData]);

  useEffect(() => {
    const unformatedTempData =
      energyVPower === "energy"
        ? energyData
        : energyVPower === "power"
        ? maxPowerData
        : minPowerData;
    setUnprocessedData(unformatedTempData);
  }, [maxPowerData, minPowerData, energyData, energyVPower]);

  let prevValuesRefresh = useRef({
    substations,
    selectedDataColumn,
    settings,
  });
  useEffect(() => {
    // refresh the data, start according to the energyVPower
    if (!substations || substations.length === 0) return;

    const settingsEqual =
      JSON.stringify(prevValuesRefresh.current.settings) ===
      JSON.stringify(settings);

    if (
      prevValuesRefresh.current.substations === substations &&
      prevValuesRefresh.current.selectedDataColumn === selectedDataColumn &&
      settingsEqual
    )
      return;

    debugLog("GET: yearly data. Growth figure");
    const functionsToRun = {
      energy: refreshEnergyData,
      power: refreshMaxPowerData,
      "min power": refreshMinPowerData,
    };
    functionsToRun[energyVPower]();
    for (let key of Object.keys(functionsToRun)) {
      if (key === energyVPower) continue;
      // wait 200 ms
      setTimeout(() => {
        functionsToRun[key as typeof energyVPower]();
      }, 500);
    }
    prevValuesRefresh.current = {
      substations,
      selectedDataColumn,
      settings,
    };
  }, [substations, selectedDataColumn, settings]);
  return {
    data,
    loading: loadingMinPowerData || loadingEnergyData || loadingMaxPowerData,
  };
}
