import { Request } from "@/api/request/request";
import { Local } from "@/pages/requests/helper/api";
import { LocationSettings } from "../../RequestSettings/components/Location";
import { ProjectSettings } from "../../RequestSettings/components/Project";

interface Part4Props {
  setIsNextDisabled: (value: boolean) => void;
  request: Local<Request>;
  setRequest: (value: Local<Request>) => void;
}

export function Part4(props: Part4Props) {
  const { setIsNextDisabled, request, setRequest } = props;
  return (
    <div className=" space-y-4">
      <ProjectSettings
        request={request}
        setRequest={setRequest}
        setIsNextDisabled={setIsNextDisabled}
      />
      <LocationSettings
        request={request}
        setRequest={setRequest}
        setIsNextDisabled={setIsNextDisabled}
      />
    </div>
  );
}
