import { Request } from "@/api/request/request";
import { create } from "zustand";
import { ID, Local } from "../helper/api";

interface RequestStoreProps {
  requests: Local<Request>[];
  request: (id: ID) => Local<Request> | undefined;
  setRequests: (requests: Local<Request>[]) => void;
  updateRequest: (id: ID, request: Partial<Local<Request>>) => void;
  deleteRequest: (id: ID) => void;
  addRequest: (request: Local<Request>) => ID;
  resetRequests: () => void;
}
let tempIdCounter = 0;

export const useRequestStore = create<RequestStoreProps>((set, get) => ({
  requests: [],
  request: (id) => get().requests.find((r) => r.id_ === id),
  setRequests: (requests) => set({ requests: requests }),
  updateRequest: (id, request) =>
    set((state) => ({
      requests: state.requests.map((r) =>
        r.id_ === id ? { ...r, ...request } : r
      ),
    })),
  deleteRequest: (id) =>
    set((state) => ({
      requests: state.requests.filter((r) => r.id_ !== id),
    })),
  addRequest: (request) => {
    let id: ID = `temp_${tempIdCounter++}`;
    set((state) => ({
      requests: [...state.requests, { ...request, status_: "new", id: id }],
    }));
    return id;
  },
  resetRequests: () => set({ requests: [] }),
}));
