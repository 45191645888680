import { EChartsOption } from "echarts";
import EChartsReact from "echarts-for-react";
import { useEffect, useState } from "react";
import { TrucksData } from "../api/getTruckData";
import { sortAPIData } from "@/utils/sortAPIData";

const DEFAULT_OPTION: EChartsOption = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
    valueFormatter: (value: any) => {
      return value.toFixed(0);
    },
  },
  xAxis: {
    type: "category",
    axisLabel: {
      fontSize: "0.75rem",
    },
    data: ["Winter", "Spring", "Summer", "Fall"],
  },
  yAxis: {
    type: "value",
    name: "Number of trips",
    nameLocation: "middle",
    nameGap: 70,
    nameTextStyle: {
      fontSize: "1rem",
    },
    axisLabel: {
      fontSize: "0.75rem",
    },
  },
  series: [],
};

function updateOption(option: EChartsOption, data: TrucksData, setOption: any) {
  const sortedData = sortAPIData(data, "season");
  const newOption = JSON.parse(JSON.stringify(option));
  const uniqueTripLengths = Array.from(new Set(data.trip_len));
  const series = uniqueTripLengths.map((tripLen) => {
    return {
      name: tripLen,
      type: "bar",
      stack: "all",
      data: sortedData.n_trips.filter(
        (_, index) => sortedData.trip_len[index] === tripLen
      ),
    };
  });
  newOption.series = series;
  const documentWidth = document.documentElement.clientWidth;
  newOption.grid = {
    left: Math.round(documentWidth * 0.06) + 25,
    right: Math.round(documentWidth * 0.05),
    bottom: Math.round(documentWidth * 0.02) + 20,
    top: Math.round(documentWidth * 0.02),
  };
  setOption(newOption);
}
export interface NTripsChartProps {
  data: TrucksData | undefined;
}
export function NTripsChart(props: NTripsChartProps) {
  const { data } = props;
  const [option, setOption] = useState<EChartsOption>(DEFAULT_OPTION);
  useEffect(() => {
    if (data) {
      updateOption(option, data, setOption);
    }
  }, [data]);

  return <EChartsReact option={option} className="grow h-full"
  style={{ height: "15rem" }}/>;
}
