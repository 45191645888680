import Checkbox from "@/components/Checkbox";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
export interface SettingsProps {
  showRequests: boolean;
  setShowRequests: any;
}

export function Settings(props: SettingsProps) {
  const { setShowRequests, showRequests } = props;
  const [selected, setSelected] = useState(false);
  return (
    <div className="leaflet-top leaflet-right top-8">
      <div className="leaflet-control pointer-events-auto">
        <div className="group">
          <div className="flex justify-end ">
            <button
              className="w-6 h-6 fill-neutral-600 p-0.5 shadow-lg rounded-md bg-neutral-100-80 backdrop-blur text-neutral-800 text-xs"
              onClick={() => setSelected(!selected)}
            >
              <SettingsIcon fontSize="inherit" />
            </button>
          </div>
          <div
            className={twMerge(
              " mt-2 transition-opacity ease-in-out duration-200 fill-neutral-600 p-0.5 shadow-lg rounded-md bg-neutral-100-80 backdrop-blur px-1 py-1 text-neutral-800 text-xs",
              selected ? "opacity-100" : "opacity-0"
            )}
          >
            <Checkbox
              checked={showRequests}
              onChange={() => setShowRequests(!showRequests)}
            >
              Requests markers
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
}
