import fetchData from "@/utils/fetch";

export interface CarData {
  distance: number[];
  n_trips: number[];
  season: string[];
  trip_len: string[];
  [key: string]: number[] | string[];
}

export async function getCarData(
  token: string,
  location: number,
  selectedSubstations?: number[]
): Promise<CarData> {
  let query = "basis/cars/" + location + "?";
  if (selectedSubstations && selectedSubstations.length > 0) {
    query += `station_id=${selectedSubstations.join("&station_id=")}&`;
  }
  const data = await fetchData(query, token);
  return data;
}
