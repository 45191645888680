import { sortAPIData } from "@/utils/sortAPIData";
import { EChartsOption } from "echarts";
import EChartsReact from "echarts-for-react";
import { useEffect, useState } from "react";
import { CarData } from "../api/getCarData";
import SeriesSort from "@/pages/forecast/features/SeriesSort";

const DEFAULT_OPTION: EChartsOption = {
  grid: {
    left: "200rem",
    bottom: "100rem",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
    valueFormatter: (value: any) => {
      return (value / 1000).toFixed(0) + " km";
    },
  },
  xAxis: {
    type: "category",
    axisLabel: {
      fontSize: "0.75rem",
    },
    data: ["Winter", "Spring", "Summer", "Fall"],
  },
  yAxis: {
    type: "value",
    name: "Total Distance per day (km)",
    nameLocation: "middle",
    nameGap: 120,
    nameTextStyle: {
      fontSize: "1rem",
    },
    axisLabel: {
      fontSize: "0.75rem",
      formatter: (value: any) => {
        return (value / 1000).toFixed(0) + " km";
      },
    },
  },
  series: [],
};

function updateOption(option: EChartsOption, data: CarData, setOption: any) {
  const sortedData = sortAPIData(data, "season");
  const newOption = JSON.parse(JSON.stringify(option));
  const uniqueTripLengths = Array.from(new Set(data.trip_len));
  const series = uniqueTripLengths.map((tripLen) => {
    return {
      name: tripLen,
      type: "bar",
      stack: "all",
      data: sortedData.distance.filter(
        (_, index) => sortedData.trip_len[index] === tripLen
      ),
    };
  });
  const documentWidth = document.documentElement.clientWidth;
  newOption.grid = {
    left: Math.round(documentWidth * 0.06) + 25,
    right: Math.round(documentWidth * 0.05),
    bottom: Math.round(documentWidth * 0.02) + 20,
    top: Math.round(documentWidth * 0.02),
  };
  series.sort(SeriesSort);
  newOption.series = series;
  setOption(newOption);
}
export interface TrucksChartProps {
  data: CarData | undefined;
}
export function DistanceChart(props: TrucksChartProps) {
  const { data } = props;
  const [option, setOption] = useState<EChartsOption>(DEFAULT_OPTION);
  useEffect(() => {
    if (data) {
      updateOption(option, data, setOption);
    }
  }, [data]);

  return <EChartsReact option={option} className="grow"
    style={{ height: "100%" }} />;
}
