import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { AddRequestModal } from "../../AddRequestModal";
interface GridToolbarAddRequestProps {}

const disabledColor = "text-[#b6b6b6]";
const enabledColor = "text-[#1976d2]";

export function GridToolbarAddRequest(props: GridToolbarAddRequestProps) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        className="flex mb-0.5 text-[#1976d2] font-medium"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <AddIcon className="pt-1 " fontSize="small" />{" "}
        <div className="pt-0.5">ADD REQUEST</div>
      </button>
      <AddRequestModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      ></AddRequestModal>
    </>
  );
}
