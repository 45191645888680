import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const baseUrl = import.meta.env.VITE_API_URL as string;

import { useDebounce } from "@/hooks/debounce";
import { useApiStore } from "@/stores/apiStore";
import { useAuth0 } from "@auth0/auth0-react";

interface ApiError extends Error {
  response?: any;
}

function preprocessUrl(api: URL, request: string) {
  request = request.replaceAll("Solar production", "PV_mean");
  return api + request;
}

export function useApi(method: "GET" | "POST" | "PUT" | "DELETE" = "GET") {
  const [loading, setLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const { api } = useApiStore();

  const fetchData = async (url: string, body?: any) => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    const headers = {
      "Content-Type": "application/json; charset=UTF-8",
      "Cache-Control": "max-age=0",
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios({
        method: method,
        url: preprocessUrl(api, url),
        headers: headers,
        data: body,
      });

      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      if (axios.isAxiosError(error)) {
        console.error(error.response?.data || error.message);
      }
      throw error;
    }
  };
  return { loading, fetchData };
}

export function useDebouncedApi<T>(
  endpoint: string,
  method: "GET" | "POST" | "DELETE" = "GET",
  debounceTime: number = 500
) {
  const { loading, fetchData } = useApi(method);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<T | undefined>(undefined);
  const [debouncedLoading, setDebouncedLoading] = useState(false);
  const fetchResults = (endpoint: string) => {
    if (!endpoint) return;
    setLoadingData(true);
    fetchData(endpoint)
      .then((data) => {
        setData(data);
      })
      .catch((error: ApiError) => {
        console.error(error);
      });
  };
  const debouncedFetch = useCallback(useDebounce(fetchResults, debounceTime), [
    fetchResults,
  ]);
  useEffect(() => {
    debouncedFetch(endpoint);
  }, [endpoint]);

  useEffect(() => {
    setDebouncedLoading(true);
  }, [endpoint]);

  useEffect(() => {
    if (!loadingData) {
      setDebouncedLoading(false);
    }
  }, [loadingData]);
  return { data, loading: loading || debouncedLoading };
}
