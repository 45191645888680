import { suffixFormatter } from "@/utils/suffixFormatter";
import { colorFromStationIdDiscrete } from "../data/Color";
import { GridData } from "../hooks/layerHook";

interface TilesLegendProps {
  gridData?: GridData;
  category: string;
  useDiscrete?: boolean;
}

const UNIT: { [key: string]: string } = {
  energy: "Wh",
  power: "W",
  utilization: "%",
  flex_energy: "Wh",
  flex_power: "W",
  flex_hours: "h",
};

function format(val: number, category: string) {
  let oup;
  if (category == "utilization") {
    return val.toFixed(0);
  }
  if (category == "flex_hours") {
    return val.toFixed(0);
  }
  if (val == 0) {
    return "0 ";
  }
  oup = suffixFormatter({ value: val });

  return oup;
}
function TilesLegendDiscrete() {
  const values = [[70], [70, 85], [85, 100], [100]];
  return (
    <div className="leaflet-bottom leaflet-left bottom-1 left-2 m-0 p-1 px-2 py-1 mb-0 bg-neutral-100-80 rounded-md shadow-lg backdrop-blur w-fit">
      <div className="">
        Transformer <br />
        Peak Utilization
      </div>
      {values.map((val, i) => {
        let colorValue = val[0];
        let first = false;
        let last = false;
        if (val.length == 1 && val[0] == 70) {
          colorValue = 69;
          first = true;
        } else if (val.length == 1 && val[0] == 100) {
          colorValue = 101;
          last = true;
        }
        return (
          <div className="flex text-center justify-end">
            {first ? (
              <>&lt; {val[0]}%</>
            ) : last ? (
              <>&gt; {val[0]}%</>
            ) : (
              <>
                {val[0]}-{val[1]}%
              </>
            )}
            <div
              className="w-6 h-5 my-0.5 ml-1"
              style={{
                background: colorFromStationIdDiscrete(colorValue),
              }}
            ></div>
          </div>
        );
      })}
      <div></div>
    </div>
  );
}
export function TilesLegend(props: TilesLegendProps) {
  const { gridData, category, useDiscrete } = props;
  if (!gridData) {
    return <></>;
  }
  let data = category === "energy" ? gridData?.energy : gridData?.power;
  if (!data) {
    return <></>;
  }
  if (useDiscrete) {
    return TilesLegendDiscrete();
  }
  let valueColumn: "energy" | "power" =
    category === "energy" ? "energy" : "power";
  let max = Math.max(...data.map((d) => d[valueColumn] as number));
  let min = Math.min(...data.map((d) => d[valueColumn] as number));
  if (data.length === 1) {
    min = 0;
  }
  let bg = "linear-gradient(to right, hsl(0, 100%, 100%), hsl(0, 100%, 50%))";
  if (min < 0) {
    bg =
      "linear-gradient(to right, hsl(120, 100%, 50%), hsl(0, 100%, 100%), hsl(0, 100%, 50%))";
  }
  return (
    <div className="leaflet-bottom leaflet-right bottom-1 left-[8rem] m-0 p-1 px-2 py-1 mb-0 bg-neutral-100-80 rounded-md shadow-lg backdrop-blur flex w-fit">
      <div className="">{format(min, category) + UNIT[category]}</div>
      <div
        className="py-0.5 px-12 mx-2 max-h-3 mt-1"
        style={{
          background: bg,
        }}
      ></div>
      <div>{format(max, category) + UNIT[category]}</div>
    </div>
  );
}
