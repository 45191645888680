import { twMerge } from "tailwind-merge";

interface borderWrapProps {
  legend: string;
  children: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

export function BorderWrap(props: borderWrapProps) {
  const { legend, children, selected, onClick } = props;
  return (
    <div className="w-full">
      <button className="w-full" onClick={onClick}>
        <fieldset
          className={twMerge(
            "border-2 border-neutral-200 rounded-md p-2 -m-2 h-full",
            selected ? "border-primary-600-50" : ""
          )}
        >
          {legend && (
            <legend
              className={twMerge(
                "text-neutral-700 text-sm px-1",
                selected ? "text-primary-600-80" : ""
              )}
            >
              {legend}
            </legend>
          )}
          <div className="text-left">{children}</div>
        </fieldset>
      </button>
    </div>
  );
}
