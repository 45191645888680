import { GEOJSON } from "@/features/Map/types";
import { useApi } from "@/services/Api";
import { useCache } from "@/services/getCached";
import { useLocationStore } from "@/stores";
import { useEffect, useState } from "react";

type Location = [number, number];

interface GridInfo {
  name: string[];
  station_id: string[];
  level: number[];
}

export function useGetLocation() {
  const { location } = useLocationStore();
  let endpoint = "grid/centroid/" + location + "/";
  return useGenericGet<Location>(endpoint);
}

export function useGetGridInfo() {
  const { location } = useLocationStore();
  let endpoint = "grid/info/" + location + "";
  return useGenericGet<GridInfo>(endpoint);
}

export function useGetTiles() {
  const { location } = useLocationStore();
  const { data, loading } = useCache<GEOJSON>({
    endpoint: "grid/" + location + "?",
    cacheKey: "grid/" + location + "?",
  });
  return { data: data, loading };
}

export function useGetCentroid() {
  const { location } = useLocationStore();
  let endpoint = "grid/centroid/" + location + "/";
  return useGenericGet<Location>(endpoint, location < 0);
}

export function useGetParents() {
  const { loading, fetchData } = useApi();
  const { location } = useLocationStore();

  async function getParents(substation: string, include_self?: boolean) {
    let endpoint =
      "grid/getParents/" +
      location +
      "/" +
      substation +
      "?include_self=" +
      (include_self ? "true" : "false");
    const data = await fetchData(endpoint)
      .then((r) => {
        return r;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }
  return { getParents, loading };
}

function useGenericGet<T>(endpoint: string, reject: boolean = false) {
  const { loading, fetchData } = useApi();
  const [data, setData] = useState<T | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (reject) {
      return;
    }
    fetchData(endpoint)
      .then((r) => {
        setData(r);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [refresh, endpoint]);

  const refreshData = () => {
    setRefresh(!refresh);
  };

  return { loading, data, refreshData };
}
