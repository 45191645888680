import { useLocationStore } from "@/stores";
import { useAuth0 } from "@auth0/auth0-react";
import * as echarts from "echarts";
import { EChartsOption } from "echarts";
import EChartsReact from "echarts-for-react";
import { unit } from "mathjs";
import { useEffect, useState } from "react";
import { BuildingAreaData, getBuildingAreaData } from "../api/getAreaData";
const DEFAULT_OPTION: EChartsOption = {
  grid: {
    left: "300rem",
    bottom: "100rem",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
    valueFormatter: (value: any) => {
      return unit(Math.round(value), "m^2").format({ precision: 4 }).toString();
    },
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: "value",
    name: "Total Building Area (m²)",
    nameLocation: "middle",
    nameGap: 40,
    nameTextStyle: {
      fontSize: "1rem",
    },
    axisLabel: {
      fontSize: "0.75rem",
    },
  },
  yAxis: {
    type: "category",

    axisLabel: {
      fontSize: "0.75rem",
    },
  },
  series: [],
};

function sortBuildingAreaData(data: BuildingAreaData): BuildingAreaData {
  // Combine building_type and area into an array of objects
  const combined = data.building_type.map((type, index) => ({
    building_type: type,
    area: data.area[index],
  }));

  // Sort the combined array based on building_type
  combined.sort((a, b) => b.building_type.localeCompare(a.building_type, "sv"));

  // Separate the combined array back into building_type and area arrays
  const sortedData: BuildingAreaData = {
    building_type: combined.map((obj) => obj.building_type),
    area: combined.map((obj) => obj.area),
  };

  return sortedData;
}
function formatType(type: string) {
  return type
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function updateOption(
  option: EChartsOption,
  apiData: BuildingAreaData,
  setOption: (option: EChartsOption) => void
) {
  const newOption = JSON.parse(JSON.stringify(option));
  // sort both building_type and area after building type in the structure
  const sortedData = sortBuildingAreaData(apiData);
  newOption.yAxis = {
    type: "category",
    data: sortedData.building_type.map((type) => formatType(type)),
  };
  const documentWidth = document.documentElement.clientWidth;
  newOption.grid = {
    left: Math.round(documentWidth * 0.1) + 40,
    right: Math.round(documentWidth * 0.03),
    bottom: Math.round(documentWidth * 0.04) + 20,
    top: Math.round(documentWidth * 0.02),
  };
  newOption.series[0] = {
    name: "Area",
    type: "bar",
    color: new echarts.graphic.LinearGradient(0, 0, 0, 0.4, [
      { offset: 0, color: "#ffdf96" },
      { offset: 0.2, color: "#ffdf96" },
      { offset: 1, color: "#fac858" },
    ]),
    data: sortedData.area,
  };
  setOption(newOption);
}

export interface BuildingsChartProps {
  selectedSubstations: number[];
}

export function BuildingsChart(props: BuildingsChartProps) {
  const { selectedSubstations } = props;
  const [option, setOption] = useState<EChartsOption>(DEFAULT_OPTION);
  const { getAccessTokenSilently, user } = useAuth0();
  const { location } = useLocationStore();
  useEffect(() => {
    async function asyncfunc() {
      if (selectedSubstations.length === 0 || !selectedSubstations) return;
      const token = await getAccessTokenSilently();
      const data = await getBuildingAreaData(
        token,
        location,
        selectedSubstations
      );
      updateOption(option, data, setOption);
    }
    asyncfunc();
  }, [selectedSubstations, location]);

  return (
    <EChartsReact
      option={option}
      className="grow h-full"
      style={{ height: "100%" }}
    />
  );
}
