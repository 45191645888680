import { Button } from "@/components/Button";
import SliderSettings from "@/components/SliderSettings";
import { useSettingsStore } from "@/pages/forecast/stores/SettingsStore";
import { useState } from "react";
import { useAdvBehaviourSliders } from "../hooks/AdvancedBehaviourSliders";
import { useBehaviourSliders } from "../hooks/BehaviourSliders";

export interface BehaviourSettingsProps {
  children?: React.ReactNode;
}

export function BehaviourSettings(props: BehaviourSettingsProps) {
  const { behaviourSliders } = useBehaviourSliders();
  const { resetBehaviourNumbers } = useSettingsStore();
  const { advBehaviourSliders } = useAdvBehaviourSliders();
  const [buttonSelected, setButtonSelected] = useState<boolean>(false);
  return (
    <>
      <div className="flex justify-between mr-8">
        These settings regard the hour by hour behaviour.
        <Button
          onClick={() => resetBehaviourNumbers()}
          type="secondary"
          size="small"
          className="hover:bg-red-500-75"
        >
          Reset
        </Button>
      </div>
      <SliderSettings sliderSettings={behaviourSliders} />
      <SliderSettings sliderSettings={advBehaviourSliders} />
    </>
  );
}
