import { create } from "zustand";

interface AuthProps {
  bearerToken: string | null;
  setBearerToken: (token: string) => void;
}

const useAuth = create<AuthProps>((set) => ({
  bearerToken: null,
  setBearerToken: (token: string) => set({ bearerToken: token }),
}));

export default useAuth;
