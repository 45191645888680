import { useState } from "react";
import { twMerge } from "tailwind-merge";
import StatusDot from "./StatusDot";
import { Tooltip } from "./Tooltip";

const SIZES = {
  small: "px-1 h-7 w-20 font-normal",
  medium: "px-1 h-7 w-30 font-normal",
  large: "px-1 h-9 w-40",
};

const SIZES_TITLE = {
  small: "text-xs",
  medium: "text-sm",
  large: "text-base",
};

const SIZES_INPUT = {
  small: "text-xs h-7",
  medium: "text-sm h-7",
  large: "text-base h-9 ",
};

export interface InputProps {
  children?: any;
  title?: string;
  className?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: number | string) => void;
  type?: "text" | "number" | "password" | "email" | "tel" | "url";
  size?: "small" | "medium" | "large";
  statusColor?: "red" | "yellow" | "green" | undefined;
  statusMessage?: string;
  tooltip?: string;
  unit?: string;
}

export function Input(props: InputProps) {
  const {
    className,
    title,
    placeholder,
    value,
    onChange,
    children,
    statusColor,
    statusMessage,
    size,
    tooltip,
    unit,
  } = props;
  let { type } = props;
  if (!type) type = "text";
  let size_: InputProps["size"] = "medium";
  if (size) size_ = size;

  const [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      <div
        className={twMerge("text-sm pb-1 text-neutral-700", SIZES_TITLE[size_])}
      >
        {title}
      </div>
      <div className={twMerge("flex space-x-4 relative", SIZES_INPUT[size_])}>
        <div
          className={twMerge(
            "relative  cursor-default rounded-md bg-neutral-100 ring-1 ring-neutral-200 text-left shadow-sm",
            SIZES[size_],
            className
          )}
        >
          {statusColor ? (
            <Tooltip message={statusMessage}>
              <StatusDot
                status={statusColor}
                className="absolute -right-[0.2rem] -top-[0.2rem] z-10 "
              />
            </Tooltip>
          ) : null}
          <div className="flex justify-between items-center">
            <input
              className={twMerge(
                "block focus:ring-0 focus:outline-none w-full h-full bg-transparent text-sm text-neutral-800",
                SIZES_INPUT[size_]
              )}
              //type="number"
              value={value}
              onChange={(e) => {
                if (!onChange) return;
                if (type === "number" && !/^\d*$/.test(e.target.value)) return;
                if (type === "number") {
                  onChange(e.target.value === "" ? "" : Number(e.target.value));
                  return;
                }
                onChange(e.target.value);
              }}
              placeholder={placeholder}
              onFocus={() => setIsEditing(true)}
              onBlur={() => setIsEditing(false)}
            />

            <p className="text-xs text-neutral-700-50 whitespace-nowrap absolute align-middle right-0 pr-2 pointer-events-none">
              {unit}
            </p>
          </div>
          {isEditing && tooltip && (
            <div className="absolute left-0 top-9 w-40 bg-neutral-100 shadow-md rounded-md z-10 p-2 text-xs ring-1 ring-neutral-600">
              {tooltip}
            </div>
          )}
        </div>
        {children && (
          <span className="text-xs  text-neutral-700 max-w-52 align-middle">
            {children}
          </span>
        )}
      </div>
    </div>
  );
}

export default Input;
