import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 100, editable: true },
  {
    field: "responsible",
    headerName: "Responsible",
    width: 180,
    editable: true,
    type: "string",
  },
  {
    field: "substation",
    headerName: "Substation",
    width: 110,
    editable: true,
    type: "string",
  },
  {
    field: "start_date",
    headerName: "Start Date",
    width: 120,
    editable: true,
    type: "string",
  },
  {
    field: "completion_date",
    headerName: "Completion Date",
    width: 210,
    editable: true,
    type: "string",
  },
  {
    field: "latitude",
    headerName: "Latitude",
    width: 120,
    editable: true,
    type: "number",
  },
  {
    field: "longitude",
    headerName: "Longitude",
    width: 120,
    editable: true,
    type: "number",
  },

  {
    field: "updated_at",
    headerName: "Last Updated",
    width: 180,
    editable: true,
    type: "string",
    valueFormatter(params) {
      return new Date(params.value as string).toLocaleString().split(",")[0];
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 90,
    editable: true,
    type: "string",
  },
];
