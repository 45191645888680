import { Industry } from "@/api/request/industry";
import { Input } from "@/components/Input";
import Dropdown from "@/components/OldDropdown";
import RadioButton from "@/components/RadioButtonSecondary";
import { StatusColor } from "@/components/StatusDot";
import { Local } from "@/pages/requests/helper/api";
import { industrySubsectors } from "@/pages/requests/stores/sectorStore";
import { memo, useEffect } from "react";
import { EditorProps } from "../types";
import { BorderWrap } from "./BorderWrap";
import { Container } from "./Container";

type IndustryColors = Partial<{
  [key in keyof Local<Industry>]: StatusColor | undefined;
}>;

function getColor(industry: Local<Industry>): IndustryColors {
  const indArea = industry.area && industry.area > 0;
  const indDemand = industry.power_demand && industry.power_demand > 0;

  return {
    area: !industry.use_demand ? (indArea ? "green" : "red") : undefined,
    power_demand: industry.use_demand
      ? indDemand
        ? "green"
        : "red"
      : undefined,
    solar_power:
      industry.solar_power && industry.solar_power > 0 ? "green" : "yellow",
  };
}

function getStatus(industryColors: IndustryColors) {
  const IndustryComplete =
    Object.keys(industryColors).length > 0 &&
    (industryColors.area === "green" ||
      industryColors.power_demand === "green");
  return IndustryComplete;
}

interface IndustryEditorProps extends EditorProps {
  sector: Local<Industry>;
  setSector: (value: Local<Industry>) => void;
  deleteIndustry?: () => void;
}

export const IndustryEditor = memo(
  (props: IndustryEditorProps) => {
    const { sector, setSector, setStatus, className } = props;

    if (!sector || !setSector) return null;
    const industryColors = getColor(sector);

    const status = getStatus(industryColors);
    useEffect(() => {
      if (setStatus) {
        setStatus(status);
      }
    }, [status, setStatus]);

    return (
      <Container className={className}>
        <Dropdown
          className=""
          title="Industry type"
          list={industrySubsectors}
          selectedValue={sector.subsector}
          onChange={(value) => {
            setSector({
              ...sector,
              subsector: value as Industry["subsector"],
            });
          }}
        ></Dropdown>
        <BorderWrap
          legend="Profile from area"
          selected={!sector.use_demand}
          onClick={() => {
            setSector({ ...sector, use_demand: false });
          }}
        >
          <Input
            className="w-full"
            statusColor={industryColors.area}
            value={sector.area}
            onChange={(value) => {
              setSector({ ...sector, area: Number(value) });
            }}
            unit="m²"
            type="number"
          />
        </BorderWrap>
        <BorderWrap
          legend="Profile from requested power"
          selected={sector.use_demand}
          onClick={() => {
            setSector({ ...sector, use_demand: true });
          }}
        >
          <Input
            className="w-full"
            statusColor={industryColors.power_demand}
            value={sector.power_demand && sector.power_demand / 1000}
            onChange={(value) => {
              setSector({
                ...sector,
                power_demand: Number(value) * 1000,
              });
            }}
            type="number"
            unit="kW"
          />
        </BorderWrap>
        <Input
          title="Solar PV capacity [kW]"
          className="w-52"
          statusColor={industryColors.solar_power}
          value={sector.solar_power && sector.solar_power / 1000}
          onChange={(value) => {
            setSector({ ...sector, solar_power: Number(value) * 1000 });
          }}
          unit="kW"
          type="number"
        ></Input>
        <div className="text-sm text-neutral-800 text-clip">
          Will there be EV charging stations?
          <div className="flex space-x-6 justify-center mt-2">
            <RadioButton
              selected={sector.ev_stations}
              onClick={() => {
                setSector({ ...sector, ev_stations: true });
              }}
            >
              Yes
            </RadioButton>
            <RadioButton
              selected={!sector.ev_stations}
              onClick={() => {
                setSector({ ...sector, ev_stations: false });
              }}
            >
              No
            </RadioButton>
          </div>
        </div>
      </Container>
    );
  },
  (prev, next) => {
    return prev.sector === next.sector;
  }
);
