/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useState } from "react";

import HourlyPower from "@/assets/Hourly power.svg?react";
import CustomRadio from "@/components/RadioButton";
import RoundedContainer from "@/components/rounded-container";
import { useDebounce } from "@/hooks/debounce";
import { Columns } from "@/pages/forecast/types";
import { EChartsInstance } from "echarts-for-react";
import { selectLegend as selectLoadLegend, setZoom } from "..";
import { useLoadZoomStore } from "../../../stores/LoadZoomStore";
import { BigNumbers } from "./BigNumbers";
import { LoadChart } from "./LoadChart";

interface LoadFigureProps {
  loadRef: React.MutableRefObject<EChartsInstance | null>;
  selectedYear: number;
  selectedSubstations: string[];
  selectedValue: keyof Columns;
}

export function LoadFigure(props: LoadFigureProps) {
  const { loadRef, selectedYear, selectedSubstations, selectedValue } = props;
  const [selectLoadTypes, setSelectLoadTypes] = useState<string>("netto");
  const [durVHour, setDurVHour] = useState("Duration"); // ["Duration", "Hourly"
  function onDurVHourChange(event: any) {
    // Updating the state with the selected radio button's value
    setDurVHour(event.target.value);
  }

  useEffect(() => {
    selectLoadLegend(selectLoadTypes, loadRef);
  }, [selectLoadTypes, selectedValue, loadRef]);

  const { setDurZoom, setHourZoom } = useLoadZoomStore();
  type dataZoomEvent = (params: {
    start: number;
    end: number;
    startValue?: number;
    endValue?: number;
  }) => void;
  const debouncedSetZoom = useCallback(useDebounce(setZoom, 100), []);
  const onLoadEvents = {
    dataZoom: useCallback<dataZoomEvent>(
      ({ start, end, startValue, endValue }) => {
        debouncedSetZoom(
          start,
          end,
          setDurZoom,
          setHourZoom,
          durVHour,
          loadRef
        );
      },
      [durVHour, setDurZoom, setHourZoom]
    ),
    // Following is ugly solution to ensure zoom is preserved when zooming using inside dataZoom
    highlight: useCallback(() => {
      if (
        !loadRef ||
        !loadRef.current ||
        !loadRef.current.getEchartsInstance() ||
        !loadRef.current.getEchartsInstance().getOption()
      ) {
        return;
      }
      const dataZoom: any = loadRef.current.getEchartsInstance().getOption()
        .dataZoom[0];
      let start = dataZoom.start;
      let end = dataZoom.end;
      debouncedSetZoom(start, end, setDurZoom, setHourZoom, durVHour, loadRef);
    }, [durVHour, setDurZoom, setHourZoom]),
  };

  return (
    <RoundedContainer classNameOuter="grow" className="flex flex-col">
      <div className="flex justify-between">
        <div>
          <div className="text-neutral-800 text-xl mt-2 ">Load</div>
          <div className="text-neutral-500 text-sm">
            Showing forecast for year {selectedYear}
          </div>
        </div>
        <BigNumbers
          selectedValue={selectedValue}
          substation={selectedSubstations[0]}
          year={selectedYear}
        />
        <div>
          <div className="flex justify-end px-3 py-1 space-x-2">
            <CustomRadio
              label={"Duration Curve"}
              value={"Duration"}
              name={"type_"}
              onChange={(value) => {
                onDurVHourChange(value);
                setSelectLoadTypes("netto");
              }}
              checkedValue={durVHour}
            />
            <CustomRadio
              label={"Hourly power"}
              value={"Hourly power"}
              name={"type_"}
              svgImg={HourlyPower}
              onChange={(value) => {
                onDurVHourChange(value);
                setSelectLoadTypes("netto");
              }}
              checkedValue={durVHour}
            />
          </div>
          <div className="flex px-3 py-2 space-x-2">
            <CustomRadio
              label={"Netto"}
              value={"netto"}
              name={"type_"}
              onChange={(e: any) => setSelectLoadTypes(e.target.value)}
              checkedValue={selectLoadTypes}
            />
            <CustomRadio
              label={"Production"}
              value={"production"}
              name={"type_"}
              onChange={(e: any) => setSelectLoadTypes(e.target.value)}
              checkedValue={selectLoadTypes}
            />
            <CustomRadio
              label={"Consumption"}
              value={"consumption"}
              name={"type_"}
              onChange={(e: any) => setSelectLoadTypes(e.target.value)}
              checkedValue={selectLoadTypes}
            />
          </div>
        </div>
      </div>
      <LoadChart
        loadRef={loadRef}
        onLoadEvents={onLoadEvents}
        selectedYear={selectedYear}
        selectedDataColumn={selectedValue}
        substations={selectedSubstations}
        durVHour={durVHour}
      />
    </RoundedContainer>
  );
}
