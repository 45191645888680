import React from "react";
import { twMerge } from "tailwind-merge";

export type StatusColor = "red" | "green" | "yellow" | undefined;

interface StatusDotProps {
  status: StatusColor;
  className?: string;
}

export const StatusDot: React.FC<StatusDotProps> = ({ status, className }) => {
  const colors = {
    red: "#ff4136",
    yellow: "#ffdc00",
    green: "#2ecc40",
  };

  return (
    <div
      className={twMerge("relative inline-block w-1.5 h-1.5 z-0", className)}
    >
      <div
        className="absolute inset-0 rounded-full animate-ping"
        style={{
          backgroundColor: status && colors[status],
          opacity: 0.6,
        }}
      />
      <div
        className="absolute inset-0 rounded-full"
        style={{
          backgroundColor: status && colors[status],
        }}
      />
    </div>
  );
};

export function StatusDotInfo() {
  return (
    <div className="text-neutral-700 text-xs whitespace-nowrap space-y-0 ">
      <div className="flex gap-x-2 items-center ">
        <StatusDot status="red" />
        Necessary
      </div>
      <div className="flex gap-x-2 items-center ">
        <StatusDot status="yellow" />
        Optional
      </div>
      <div className="flex gap-x-2 items-center ">
        <StatusDot status="green" />
        Valid
      </div>
    </div>
  );
}

export default StatusDot;
