import { Columns } from "@/pages/forecast/types";
import ReactECharts from "echarts-for-react";
import { useCallback, useEffect, useState } from "react";
import { useGetOption } from "../hooks/Option";
export interface LoadChartProps {
  loadRef: React.MutableRefObject<ReactECharts | null>;
  selectedYear: number;
  substations: string[];
  durVHour: string;
  selectedDataColumn: keyof Columns;
  onLoadEvents: any;
}

export function LoadChart(props: LoadChartProps) {
  const {
    loadRef,
    onLoadEvents,
    selectedYear,
    selectedDataColumn,
    substations,
    durVHour,
  } = props;
  const [selectedLegends, setSelectedLegends] = useState<{
    [key: string]: boolean;
  }>({}); // [object Object
  useEffect(() => {
    setSelectedLegends({});
  }, [selectedDataColumn]);
  
  const { option, isLoading } = useGetOption(
    substations,
    selectedYear,
    selectedDataColumn,
    durVHour,
    selectedLegends
  );
  onLoadEvents["legendselectchanged"] = useCallback((params: any) => {
    if (!params.selected) return;
    setSelectedLegends(params.selected);
  }, []);
  return (
    <ReactECharts
      option={option}
      ref={loadRef}
      className="grow"
      style={{ height: "100%" }}
      onEvents={onLoadEvents}
      notMerge={true}
      lazyUpdate={false}
      onChartReady={(echarts: any) => {
        isLoading ? echarts.showLoading() : echarts.hideLoading();
      }}
      showLoading={isLoading}
      opts={{ renderer: "svg" }}
    />
  );
}
