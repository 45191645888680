import RoundedContainer from "@/components/rounded-container";
import { useRef } from "react";
import { HistChart } from "./HistChart";
import { PieChart } from "./PieChart";

type Entry = {
  label: string;
  value: string;
};

interface SelectDropdownProps {
  label: string;
  value: string;
  entries: Entry[];
  onChange?: (e: any) => void;
}

export function DataAnalysisTab() {
  const histChartRef = useRef(null);

  return (
    <div className="m-2 flex flex-col flex-auto">
      <RoundedContainer classNameOuter="h-1/3">
        <PieChart />
      </RoundedContainer>
      <RoundedContainer classNameOuter="grow">
        <HistChart ref={histChartRef} />
      </RoundedContainer>
    </div>
  );
}
/*

*/
