import { LineSeriesOption } from "echarts";

export function addSubstation(
  series: LineSeriesOption[],
  transformerCapacity: number
): LineSeriesOption[] {
  const seriesCopy: LineSeriesOption[] = JSON.parse(JSON.stringify(series));
  if (!seriesCopy || seriesCopy.length === 0) {
    return seriesCopy;
  }
  const data = seriesCopy[0].data?.map((d) => [
    // @ts-ignore d.value is not detected to exist in the data object, probably bug in echarts types
    d.value[0],
    transformerCapacity,
  ]);
  seriesCopy.push({
    name: "Substation Capacity",
    stack: "alone",
    type: "line",
    smooth: true,
    showSymbol: false,
    symbol: undefined,
    silent: true,
    lineStyle: {
      color: "red",

      width: 2,
      type: "solid",
    },
    emphasis: {
      focus: "series",
    },
    data: data,
  });
  return seriesCopy;
}
