import { useApi } from "@/services/Api";
import { Commercial } from "./commercial";
import { Industry } from "./industry";
import { Public } from "./public";
import { Residential } from "./residential";

interface Sector {
  industry: Industry[];
  residential: Residential[];
  public_: Public[];
  commercial: Commercial[];
}

export function useGetSectors() {
  const { loading, fetchData } = useApi();

  async function getSectors(requestId: number): Promise<Sector | undefined> {
    if (!requestId) {
      return undefined;
    }
    let endpoint = "request/industries/" + requestId;
    let industry = (await fetchData(endpoint)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      })) as Industry[];
    let residential = (await fetchData("request/residentials/" + requestId)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      })) as Residential[];
    let public_ = (await fetchData("request/public/" + requestId)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      })) as Public[];
    let commercial = (await fetchData("request/commercials/" + requestId)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      })) as Commercial[];
    return { industry, residential, public_, commercial };
  }
  return { getSectors, loading };
}
