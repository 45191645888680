import ReactECharts from "echarts-for-react";
import usePieOption from "../hooks/pieOptions";

export function PieChart() {
  const { option } = usePieOption();

  return (
    <ReactECharts
      option={option}
      style={{ height: "100%", width: "60%", overflow: "hidden" }}
    />
  );
}
