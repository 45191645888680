import { useGetRequests } from "@/api/request/request";
import useRequestTrigger from "@/hooks/requestTrigger";
import { suffixFormatter } from "@/utils/suffixFormatter";
import * as echarts from "echarts";
import { useEffect, useState } from "react";
import { ColumnDict } from "../../../columns";
import processData from "../dataProcessing/ProcessPieChart";
import { useAnalysisSettings } from "./../../../stores/analysisSettings";

declare type OptionDataValue = string | number | Date;

function createOption(data: any | null, legend: string, groupbyColumn: string) {
  if (!data) {
    return {};
  }

  let factor: number;
  if (ColumnDict[groupbyColumn].serversideSuffix !== undefined) {
    factor = ColumnDict[groupbyColumn].serversideSuffix;
  } else {
    factor = 1;
  }
  var option: echarts.EChartsOption = {
    grid: {
      top: "20%",
    },
    title: {
      text: ColumnDict[groupbyColumn].label,
      left: "center",
      top: 0,
      padding: [0, 0],
      textStyle: {
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "item",
      valueFormatter: (val: OptionDataValue | OptionDataValue[]): string => {
        if (Array.isArray(val)) {
          return val[0] + " : " + val[1];
        }
        if (typeof val === "string") {
          return val;
        }
        // check if val is a date
        if (val instanceof Date) {
          return val.toDateString();
        }
        if (typeof val === "number") {
          return (
            suffixFormatter({ value: val * factor }) +
            ColumnDict[groupbyColumn].unit
          );
        }
        return (
          suffixFormatter({ value: val * factor }) +
          ColumnDict[groupbyColumn].unit
        );
      },
    },
    series: [
      {
        name: ColumnDict[legend].label,
        type: "pie",
        radius: ["0%", "50%"],
        avoidLabelOverlap: false,
        label: {
          fontSize: 10,
          formatter: (d) =>
            `${d.name}\n ${suffixFormatter({
              value: Number(d.value) * factor,
            })}${ColumnDict[groupbyColumn].unit} \n ${d.percent}%`,
        },
        emphasis: {
          focus: "self",
        },
        data: data,
      },
    ],
  };
  return option;
}

export default function usePieOption() {
  const [option, setOption] = useState<echarts.EChartsOption>({});
  const { settings } = useAnalysisSettings();
  const { data, refreshData, loading } = useGetRequests();
  const { requestTrigger } = useRequestTrigger();

  useEffect(() => {
    refreshData();
  }, [requestTrigger]);

  useEffect(() => {
    if (!loading) {
      setOption(
        createOption(
          processData(data, settings.legend, settings.groupbyColumn),
          settings.legend,
          settings.groupbyColumn
        )
      );
    }
  }, [data, settings]);
  return { option, loading };
}
