import { create } from "zustand";

export interface IFormattedSettings {
  growth: string[];
  truck_direct: number;
  truck_night: number;
  bus_direct: number;
  bus_night: number;
  car_direct: number;
  car_po: number;
  car_gf: number;
  heating: number;
}

export interface GrowthNumbers {
  car: number;
  truck: number;
  solar: number;
  bus: number;
}

export interface ClimateNumbers {
  heating: number;
}
export interface BehaviorNumbers {
  car_home_direct: number;
  car_home_po: number;
  car_home_gf: number;
  car: number;
  truck: number;
  bus: number;
}

const DEFAULT_GROWTH_NUMBERS: GrowthNumbers = {
  car: 1,
  truck: 1,
  solar: 1,
  bus: 1,
};

const DEFAULT_CLIMATE_NUMBERS: ClimateNumbers = {
  heating: 2023,
};

const DEFAULT_BEHAVIOR_NUMBERS: BehaviorNumbers = {
  car_home_direct: 0.3,
  car_home_po: 0.3,
  car_home_gf: 0.3,
  car: 0.5, // car_direct
  truck: 0.5, // truck_direct
  bus: 0.0, // bus_direct
};
export interface SettingsStore {
  // Your store properties here
  growthNumbers: GrowthNumbers;
  climateNumbers: ClimateNumbers;
  behaviorNumbers: BehaviorNumbers;
  setGrowthNumbers: (growthNumbers: GrowthNumbers) => void;
  setClimateNumbers: (climateNumbers: ClimateNumbers) => void;
  setBehaviourNumbers: (behaviorNumbers: BehaviorNumbers) => void;
  resetGrowthNumbers: () => void;
  resetBehaviourNumbers: () => void;
  getFormattedSettings: () => IFormattedSettings;
}

export const useSettingsStore = create<SettingsStore>((set, get) => ({
  // Your store initial state here
  growthNumbers: DEFAULT_GROWTH_NUMBERS,
  climateNumbers: DEFAULT_CLIMATE_NUMBERS,
  behaviorNumbers: DEFAULT_BEHAVIOR_NUMBERS,
  setGrowthNumbers: (growthNumbers: GrowthNumbers) => set({ growthNumbers }),
  setClimateNumbers: (climateNumbers: ClimateNumbers) =>
    set({ climateNumbers }),
  setBehaviourNumbers: (behaviorNumbers: BehaviorNumbers) =>
    set({ behaviorNumbers }),
  resetGrowthNumbers: () => set({ growthNumbers: DEFAULT_GROWTH_NUMBERS }),
  resetBehaviourNumbers: () =>
    set({ behaviorNumbers: DEFAULT_BEHAVIOR_NUMBERS }),
  getFormattedSettings: () => {
    const { growthNumbers, climateNumbers, behaviorNumbers } = get();
    return {
      growth: getGrowthSettings(growthNumbers),
      truck_direct: behaviorNumbers.truck,
      truck_night: 1 - behaviorNumbers.truck,
      bus_direct: behaviorNumbers.bus,
      bus_night: 1 - behaviorNumbers.bus,
      car_direct: behaviorNumbers.car_home_direct,
      car_po: behaviorNumbers.car_home_po,
      car_gf: behaviorNumbers.car_home_gf,
      heating: climateNumbers.heating,
    };
  },
}));

export function getGrowthSettings(numbers: GrowthNumbers) {
  return Object.keys(numbers).reduce((acc: string[], key) => {
    const value = numbers[key as keyof GrowthNumbers];
    const setting = value === 0 ? "low" : "high";
    if (value === 1) return acc;

    return [...acc, `${key}_${setting}`];
  }, []);
}
