import { useGetEnergy, useGetMax } from "@/api/forecast";
import { useGetGridCapacity } from "@/api/grid";
import { useSettingsStore } from "@/pages/forecast/stores/SettingsStore";
import { debugLog } from "@/utils/debugLog";
import { useEffect } from "react";

type PeakLoadingData = {
  station_id: string;
  utilization: number;
}[];

function calculatePeakLoadingData(
  gridCapacityData: ReturnType<typeof useGetGridCapacity>["data"],
  maxPowerData: ReturnType<typeof useGetMax>["data"]
) {
  if (!gridCapacityData) return;
  if (!maxPowerData) return;
  let sortedMaxPowerData = maxPowerData.sort((a, b) =>
    a.station_id.localeCompare(b.station_id)
  );
  let sortedGridCapacityData = gridCapacityData.sort((a, b) =>
    a.station_id.localeCompare(b.station_id)
  );
  // join
  let peakLoading = sortedMaxPowerData.map((d, i) => {
    let mathingStation = sortedGridCapacityData.find(
      (value) => value.station_id === d.station_id
    );
    if (!mathingStation) return { station_id: d.station_id, utilization: -1 };
    if (
      mathingStation.transformer_capacity === undefined ||
      mathingStation.transformer_capacity === 0
    )
      return { station_id: d.station_id, utilization: -1 };
    return {
      station_id: d.station_id,
      utilization: (d.power / mathingStation.transformer_capacity) * 100,
    };
  });
  return peakLoading;
}

export function useGetLayerData(
  year: number,
  category?: string,
  selectedLayer?: string
) {
  const settings = useSettingsStore((state) => state.getFormattedSettings());
  const {
    data: maxPowerData,
    refreshData: refreshMaxPowerData,
    loading: maxPowerLoading,
  } = useGetMax([year], undefined, category, true, settings);
  const {
    data: energyData,
    refreshData: resfreshEnergyData,
    loading: energyLoading,
  } = useGetEnergy([year], undefined, category, true, settings);
  // flex will be implemented later

  const {
    data: gridCapacityData,
    refreshData: refreshGridCapacityData,
    loading: gridCapacityLoading,
  } = useGetGridCapacity();

  useEffect(() => {
    refreshGridCapacityData();
  }, []);

  useEffect(() => {
    debugLog("GET: Layer Data. Map. ", selectedLayer);
    if (selectedLayer === "energy") {
      resfreshEnergyData();
      setTimeout(() => {
        refreshMaxPowerData();
      }, 500);
      return;
    }
    refreshMaxPowerData();
    setTimeout(() => {
      resfreshEnergyData();
    }, 500);
  }, [year, category, JSON.stringify(settings)]);

  let peakLoadingData = calculatePeakLoadingData(
    gridCapacityData,
    maxPowerData
  );
  return {
    maxPowerData,
    energyData,
    peakLoadingData,
    maxPowerLoading,
    energyLoading,
    gridCapacityLoading,
  };
}
