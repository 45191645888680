import { SliderSettingsProps } from "@/components/SliderSettings";
import { round } from "mathjs";
import { useSettingsStore } from "../../../stores/SettingsStore";

const percentageMarks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 0.25,
    label: "25%",
  },
  {
    value: 0.5,
    label: "50%",
  },
  {
    value: 0.75,
    label: "75%",
  },
  {
    value: 1,
    label: "100%",
  },
];

interface IndexNumber {
  key: string;
  value: number;
  locked: boolean;
}

export function useAdvBehaviourSliders() {
  const { behaviorNumbers, setBehaviourNumbers } = useSettingsStore();

  const values: number[] = [
    behaviorNumbers.car_home_direct,
    behaviorNumbers.car_home_gf + behaviorNumbers.car_home_direct,
  ];
  const setValues = (value: number[]) => {
    setBehaviourNumbers({
      ...behaviorNumbers,
      car_home_direct: round(value[0], 2),
      car_home_gf: round(value[1] - value[0], 2),
      car_home_po: round(1 - value[1], 2),
    });
  };
  const advBehaviourSliders: SliderSettingsProps[] = [
    {
      title: "Car",
      value: values,
      onChange: (event: any, value: number | number[]) => {
        if (typeof value !== "number") {
          setValues(value);
        }
      },
      marks: percentageMarks,
      min: 0,
      max: 1,
      step: 0.01,
      disabled: false,
      track: false,
      subtext:
        "When are cars charged at home? Directly after usage, during low grid usage or at lowest charging prices.",
      valueLabelDisplay: "auto",
      valueLabelFormat: (value: number) => `${round(value * 100)}%`,
      percentageText: ["Direct", "Grid Friendly", "Price Optimized"],
    },
  ];
  return { advBehaviourSliders };
}
