import { HeaderRadioButtons } from "@/components/HeaderRadioButton";
import { Map } from "@/features/Map";
import { SolarTab } from "./features/solar_tab";
import { TrucksTab } from "./features/trucks_tab";
import { CarsTab } from "./features/cars_tab";
import { ChangeEvent, useState } from "react";

type tab = "trucks" | "solar" | "car";
const TABS = [
  {
    name: "Trucks",
    value: "trucks",
  },
  {
    name: "Car",
    value: "car",
  },
  {
    name: "Solar Production",
    value: "solar",
  },
];

export function Basis() {
  const [selectedTab, setSelectedTab] = useState<tab>("trucks");
  const [selectedSubstations, setSelectedSubstations] = useState<number[]>([]);
  return (
    <div className="grid grid-flow-row grid-cols-6 h-full">
      <div className="col-span-4 h-full flex flex-col">
        <HeaderRadioButtons
          radioItems={TABS}
          selected={selectedTab}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSelectedTab(e.target.value as tab);
          }}
        />
        {selectedTab === "solar" && (
          <SolarTab selectedSubstations={selectedSubstations} />
        )}
        {selectedTab === "trucks" && (
          <TrucksTab selectedSubstations={selectedSubstations} />
        )}
        {selectedTab === "car" && (
          <CarsTab selectedSubstations={selectedSubstations} />
        )}
      </div>
      <div className="col-span-2">
        <div className="grid grid-flow-row grid-rows-3 h-full">
          <Map
            selectedSubstations={selectedSubstations}
            setSelectedSubstations={setSelectedSubstations}
            className="m-2 row-span-3 rounded-lg border-2 border-neutral-300 shadow-md"
            tiles={true}
          />
        </div>
      </div>
    </div>
  );
}
