import { useApi } from "@/services/Api";
import { useLocationStore } from "@/stores";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Commercial } from "./commercial";
import { Industry } from "./industry";
import { Public } from "./public";
import { Residential } from "./residential";

export interface Sector {
  id: number;
  request_id?: number;
  name?: string;
  created_ts?: string;
  updated_ts?: string;
  creator_id?: string;
  updater_id?: string;
  subsector?: string;
  solar_power?: number;
}

export interface Request {
  id: number;
  name: string;
  org_id?: string;
  location?: number;
  address?: string;
  latitude?: number;
  longitude?: number;
  substation?: string[];
  ba?: number;
  completion_date?: string; // changed to string to handle year intervals
  start_date?: string; // changed to string to handle year intervals
  requested_power?: number;
  estimated_power?: number;
  estimated_flexibility?: number;
  responsible?: string;
  plan_last_updated?: string;
  status?: string;
  quantity?: number;
  gross_area?: number;
  district_heating?: boolean;
  charging_stations?: number;
  solar_panels?: number;
  property_designation?: string;
  created_at?: string;
  updated_at?: string;
}

export function useGetRequests() {
  const { user } = useAuth0();
  const { location } = useLocationStore();

  return useGenericGet<Request[]>(
    "requests/?" + "org_id=" + user!.orgId + "&location=" + location
  );
}

function convertSubstation(substation: string | string[] | undefined) {
  if (typeof substation === "string") {
    return substation.split(",");
  } else {
    return substation;
  }
}
export function useUpdateRequest() {
  const { loading, fetchData } = useApi("PUT");
  async function updateRequest(newRow: Request) {
    return fetchData("request/", newRow).then((data) => {
      return data;
    });
  }
  return { loading, updateRequest };
}
export function useInsertRequest() {
  const { loading, fetchData } = useApi("POST");
  const { user } = useAuth0();
  const { location } = useLocationStore();
  async function insertRequest(newRow: Request) {
    newRow["org_id"] = user!.orgId;
    newRow["location"] = location;
    newRow["substation"] = convertSubstation(newRow["substation"]);

    return fetchData("request/", newRow);
  }
  return { loading, insertRequest };
}

export function useDeleteRequest() {
  const { loading, fetchData } = useApi("DELETE");
  async function deleteRequest(id: number) {
    return fetchData("request/" + id);
  }
  return { loading, deleteRequest };
}

interface useGetProfileInput {
  industry?: Industry[];
  residential?: Residential[];
  public?: Public[];
  commercial?: Commercial[];
}

export interface ProfileData {
  time: string[];
  Industry?: number[];
  Residential?: number[];
  Commercial?: number[];
  Public?: number[];
}

export function useGetProfile() {
  const { loading, fetchData } = useApi("POST");
  async function fetchData_(input: useGetProfileInput) {
    let body = {
      industry: input.industry,
      residential: input.residential,
      public: input.public,
      commercial: input.commercial,
    };
    let data: ProfileData = await fetchData("request/getProfile/", body)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }

  return { fetchData: fetchData_, loading };
}

function useGenericGet<T>(endpoint: string) {
  const { loading, fetchData } = useApi();
  const [data, setData] = useState<T | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    fetchData(endpoint)
      .then((r) => {
        setData(r);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [refresh, endpoint]);

  const refreshData = () => {
    setRefresh(!refresh);
  };

  return { loading, data, refreshData };
}
