import { LatLngLiteral } from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

interface IMapProps {
  setSubstation?: (value: string) => void;
  coordinates?: LatLngLiteral | null;
  height?: string;
  width?: string;
}

// set default props
Map.defaultProps = {
  height: "20rem",
  width: "18rem",
};

export function Map(props: IMapProps) {
  const { coordinates, height, width } = props;
  if (!coordinates || !coordinates.lat || !coordinates.lng) return null;
  return (
    <div>
      <MapContainer
        center={[coordinates?.lat, coordinates.lng]} // middle
        zoom={14}
        zoomControl={false}
        style={{ height, width }}
        className="rounded-md"
      >
        {coordinates && <Marker position={coordinates}></Marker>}
        <TileLayer
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={19}
        />
      </MapContainer>
    </div>
  );
}
