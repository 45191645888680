import { Request } from "@/api/request/request";
import { Input } from "@/components/Input";
import { Local } from "@/pages/requests/helper/api";
import { Divider } from "@mui/material";

function Project(props: LocationProps) {
  const { editableRow, setEditableRow } = props;
  return (
    <div>
      <Divider className="my-4 text-sm text-neutral-700" textAlign="left">
        {" "}
        Project{" "}
      </Divider>
      <div className="grid grid-cols-2 grid-flow-col grid-rows-3">
        <div className="flex">
          <Input
            className="mb-4 w-40 "
            title="Status"
            size="small"
            statusMessage="This field is required in order to calculate hourly profile"
            value={editableRow.status}
            onChange={(e) => {
              // Check if is number
              if (!/^\d*$/.test(String(e))) return;
              // @ts-ignore
              setEditableRow((prev: Request) => ({
                ...prev!,
                status: e,
              }));
            }}
          ></Input>
        </div>
        <Input
          className="mb-4 w-40 "
          title="Plan last updated"
          size="small"
          value={editableRow.plan_last_updated}
          onChange={(e) => {
            // Check if is number
            if (!/^\d*$/.test(String(e))) return;
            // @ts-ignore
            setEditableRow((prev: Request) => ({
              ...prev!,
              plan_last_updated: e,
            }));
          }}
        ></Input>
        <Input
          className="mb-4 w-40 "
          title="Solar Panels"
          size="small"
          statusMessage="This field is required in order to calculate hourly profile"
          value={editableRow.solar_panels}
          onChange={(e) => {
            // Check if is number
            if (!/^\d*$/.test(String(e))) return;
            // @ts-ignore
            setEditableRow((prev: Request) => ({
              ...prev!,
              solar_panels: e,
            }));
          }}
        ></Input>
        <Input
          className="mb-4 w-40 "
          title="Charging Stations"
          size="small"
          statusMessage="This field is required in order to calculate hourly profile"
          value={editableRow.charging_stations}
          onChange={(e) => {
            // Check if is number
            if (!/^\d*$/.test(String(e))) return;
            // @ts-ignore
            setEditableRow((prev: Request) => ({
              ...prev!,
              charging_stations: e,
            }));
          }}
        ></Input>
        <Input
          className="w-40"
          title="Start year"
          size="small"
          placeholder="YYYY"
          value={editableRow.start_date}
          onChange={(value) =>
            setEditableRow({ ...editableRow, start_date: value as string })
          }
          //statusColor={startYearValid ? "green" : "red"}
        ></Input>
        <Input
          className="w-40"
          title="Completion year"
          size="small"
          placeholder="YYYY"
          value={editableRow.completion_date}
          onChange={(value) =>
            setEditableRow({ ...editableRow, completion_date: value as string })
          }
          //statusColor={completionYearValid ? "green" : "red"}
        ></Input>
        <div> </div>
      </div>
    </div>
  );
}

interface LocationProps {
  editableRow: Local<Request>;
  setEditableRow: (e: Local<Request>) => void;
}

function Location(props: LocationProps) {
  const { editableRow, setEditableRow } = props;
  return (
    <>
      <Divider className="my-4 text-sm text-neutral-700" textAlign="left">
        {" "}
        Location{" "}
      </Divider>
      <div className="my-3"></div>
      <div className="grid grid-cols-2 grid-rows-3 grid-flow-col">
        <Input
          className="mb-4 w-40 "
          title="Address"
          size="small"
          statusMessage="This field is required in order to calculate hourly profile"
          value={editableRow.address}
          onChange={(e) => {
            // Check if is number
            // @ts-ignore
            setEditableRow((prev: Request) => ({
              ...prev!,
              address: e,
            }));
          }}
        ></Input>

        <Input
          className="mb-1 w-40 "
          title="Coordinates"
          size="small"
          statusMessage="This field is required in order to calculate hourly profile"
          value={editableRow.latitude}
          onChange={(e) => {
            // Check if is number
            if (!/^\d*$/.test(String(e))) return;
            // @ts-ignore
            setEditableRow((prev: Request) => ({
              ...prev!,
              latitude: e,
            }));
          }}
        ></Input>
        <Input
          className="mb-4 w-40 "
          size="small"
          statusMessage="This field is required in order to calculate hourly profile"
          value={editableRow.longitude}
          onChange={(e) => {
            // Check if is number
            if (!/^\d*$/.test(String(e))) return;
            // @ts-ignore
            setEditableRow((prev: Request) => ({
              ...prev!,
              longitude: e,
            }));
          }}
        ></Input>
        <span className="mt-2 flex flex-col justify-center row-span-3 h-40 bg-primary-600-10 text-center align-middle rounded-md border-2 border-neutral-500 shadow-md ">
          {" "}
          Map coming soon
        </span>
      </div>
    </>
  );
}

interface GeneralPageProps {
  editableRow?: Local<Request> | null;
  setEditableRow: (e: Local<Request>) => void;
}

export function GeneralPage(props: GeneralPageProps) {
  const { editableRow, setEditableRow } = props;
  if (!editableRow) return null;
  const areaStatus = editableRow.ba ? "green" : "red";
  return (
    <div className="overflow-y-scroll h-[32rem]">
      <div className="flex">
        <Input
          className="mb-4 w-40 "
          title="Total area [m²]"
          size="small"
          statusColor={areaStatus}
          statusMessage="This field is required in order to calculate hourly profile"
          value={editableRow.ba}
          onChange={(e) => {
            // Check if is number
            if (!/^\d*$/.test(String(e))) return;
            // @ts-ignore
            setEditableRow((prev: Request) => ({
              ...prev!,
              ba: e,
            }));
          }}
        ></Input>
        <div className="ml-4 mt-4 h-full text-xs text-neutral-700">
          Total area concerning the request.
        </div>
      </div>
      <Project editableRow={editableRow} setEditableRow={setEditableRow} />
      <Location editableRow={editableRow} setEditableRow={setEditableRow} />
    </div>
  );
}
