import ReactECharts from "echarts-for-react";
import useHistOption from "../hooks/histOptions";
export interface histChartProps {
  ref: any;
}

export function HistChart(props: histChartProps) {
  const { ref } = props;

  const { option, loading } = useHistOption();

  return (
    <ReactECharts
      option={option}
      ref={ref}
      className="grow"
      notMerge={true}
      lazyUpdate={false}
      style={{ height: "100%" }}
    />
  );
}
