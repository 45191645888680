import { suffixFormatter } from "@/utils/suffixFormatter";
import { EChartsOption } from "echarts";
import { TooltipFormatterParams } from "../../types";
import { optionProps } from "./types";

type OptionDataValue = number | string | Date;

function tooltipFormatter(params: TooltipFormatterParams[]) {
  if (!params || params.length === 0) {
    return "";
  }
  const subParam = params.find(
    (param) => param.seriesName === "Substation Capacity"
  );
  return `
    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${
      params[0].name
    }</span>
    <br>
    ${
      subParam
        ? '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' +
          subParam.seriesName +
          "</span>" +
          '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
          suffixFormatter({ value: subParam.value[1] }) +
          "Wh/h</span>"
        : ""
    }
    <br>
    <br>

    ${params
      .map((param) => {
        if (
          param.seriesName === "Substation Capacity"
          //param.seriesName === "Total"
        ) {
          return;
        }
        if (param.value === undefined || Array.isArray(param.value)) {
          return;
        }
        return `${param.marker}
      <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${
        param.seriesName
      }</span>
      <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${
        suffixFormatter({ value: param.value === null ? 0 : param.value }) +
        "Wh/h"
      }</span>`;
      })
      .join("<br>")}
      `;
}

/*<br><br>
    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">
    Total consumption
    </span>
    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
    </span>
    <br>
    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">
    Total production
    </span>
    <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
    ${
      suffixFormatter({
        value: params.reduce((n, { value, seriesName }) => {
          if (seriesName == "Substation Capacity") return n;
          return n + Math.min(value[1], 0);
        }, 0),
      }) + "Wh/h"
    }
    </span>`;
}
*/
export function createOption(
  props: optionProps | null,
  energyVPower: string,
  selectedLegends: { [key: string]: boolean } | undefined
) {
  if (!props || !props.xAxis) {
    return {};
  }
  let documentWidth = document.documentElement.clientWidth;
  let unit: string;
  if (energyVPower === "energy") {
    unit = "Wh";
  } else {
    unit = "Wh/h";
  }
  // Substation capacity should not be visible when a data column is selected
  if (props.series.length < 6) {
    if (!selectedLegends) selectedLegends = {};
    selectedLegends["Substation Capacity"] = false;
  }
  const option: EChartsOption = {
    grid: {
      left: Math.round(documentWidth * 0.06),
      right: Math.round(documentWidth * 0.08) + 40,
      bottom: Math.round(documentWidth * 0.03),
      top: Math.round(documentWidth * 0.02),
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: tooltipFormatter,
    },
    legend: {
      top: "center",
      right: "right",
      itemGap: 5,
      textStyle: {
        fontSize: "0.75rem",
      },
      selected: selectedLegends && selectedLegends,
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: props.xAxis,
        axisLabel: {
          fontSize: "0.75rem",
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          fontSize: "0.75rem",
          formatter: (val: number) =>
            suffixFormatter({ value: val, forAxes: false, precision: 2 }) +
            unit,
        },
      },
    ],
    series: props.series,
  };

  return option;
}
