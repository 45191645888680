import { Button } from "@mui/material";
import { GridRowId, GridValidRowModel } from "@mui/x-data-grid";
import { useState } from "react";

import useRequestTrigger from "@/hooks/requestTrigger";
import { useRequests } from "@/pages/requests/hooks/requests";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

export function GridToolbarDeleteButton() {
  const { setRequestTrigger } = useRequestTrigger();
  const { deleteRequest } = useRequests();
  const apiRef = useGridApiContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState<
    Map<GridRowId, GridValidRowModel>
  >(new Map());
  const handleOpen = () => {
    setSelectedIDs(apiRef.current.getSelectedRows());
    if (selectedIDs.size > 0) setModalOpen(true);
  };
  const handleDelete = async () => {
    // Assuming you have the rows' data managed in the state, you could filter out the selected rows like this:
    // setRows((prevRows) => prevRows.filter((row) => !selectedIDs.has(row.id)));
    // After deletion, you may want to clear the selection
    for (const [id_, object] of selectedIDs) {
      const id: number = object.id_;
      const response = await deleteRequest(id).catch((error) => {
        console.error(error);
      });
      apiRef.current.selectRow(id, false);
    }
    setRequestTrigger();
    setModalOpen(false);
  };
  return (
    <>
      <button className="text-red-500 flex mb-0.5" onClick={handleOpen}>
        <DeleteIcon className="pt-1" fontSize="small" />{" "}
        <div className="pt-0.5">DELETE SELECTED</div>
      </button>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="absolute container-sm top-1/4 left-1/4 bg-neutral-100 text-neutral-800 p-8 rounded-md w-1/4 text-lg">
          <div className="flex text-3xl pb-2">
            Delete {selectedIDs.size} selected rows?
          </div>
          <p className="text-neutral-700 py-4">
            Are you sure you want to delete the selected rows? <br />
            It will delete the selected rows for{" "}
            <u className="text-neutral-800">Everyone</u> in your team. <br />
            This action cannot be undone.
          </p>
          <div className="flex justify-end">
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
