import { twMerge } from "tailwind-merge";

interface LoadingSymbolProps {
  className?: string;
  hide?: boolean;
}

export function LoadingSymbol(props: LoadingSymbolProps) {
  const { className, hide } = props;
  return (
    <div
      className={twMerge(
        "flex justify-center items-center h-6 w-6 animate-spin transition-all duration-100",
        hide ? "opacity-0" : "opacity-100 ",
        className
      )}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <defs>
          <mask id="cutoffMask">
            <rect x="0" y="0" width="100" height="100" fill="white" />
            <path d="M50,50 L100,50 A50,50 0 0,1 75,93.3 Z" fill="black" />
          </mask>
        </defs>
        <circle
          cx="50"
          cy="50"
          r="40"
          fill="none"
          stroke="#0090CF"
          stroke-width="15"
          stroke-linecap="round"
          mask="url(#cutoffMask)"
        />
      </svg>
    </div>
  );
}
