import { useDebounce } from "@/hooks/debounce";
import {
  getGrowthSettings,
  useSettingsStore,
} from "@/pages/forecast/stores/SettingsStore";
import { ApiData } from "@/pages/forecast/types";
import { useApi } from "@/services/Api";
import { useLocationStore } from "@/stores";
import { debugLog } from "@/utils/debugLog";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { LoadColumns } from "../types";

export function useGetLoadData(
  substations: string[],
  selectedDataColumn: string,
  selectedYear: number
) {
  const { location } = useLocationStore();
  const { user } = useAuth0();
  const { loading: loadingData, fetchData } = useApi("GET");
  const { growthNumbers, behaviorNumbers, climateNumbers } = useSettingsStore();
  const [data, setData] = useState<ApiData<LoadColumns> | null>(null);

  let endpoint: string = "forecasts/hourly/";
  endpoint += user!.orgId + "/" + location + "?";
  if (substations && substations.length > 0) {
    endpoint += `substations=${substations.join("&substations=")}&`;
  }
  endpoint += `year=${selectedYear}&`;

  if (selectedDataColumn !== "Total") {
    endpoint += `column=${selectedDataColumn}&`;
  }
  if (Object.keys(growthNumbers).length > 0) {
    endpoint += `gs=${getGrowthSettings(growthNumbers).join("&gs=")}&`;
  }
  endpoint += `truck_direct=${behaviorNumbers.truck}&truck_night=${
    1 - behaviorNumbers.truck
  }&`;
  endpoint += `bus_direct=${behaviorNumbers.bus}&bus_night=${
    1 - behaviorNumbers.bus
  }&`;
  endpoint += `car_direct=${behaviorNumbers.car_home_direct}&car_po=${behaviorNumbers.car_home_po}&car_gf=${behaviorNumbers.car_home_gf}`;
  endpoint += "&heating=" + climateNumbers.heating;
  function fetchResults(endpoint: string) {
    if (!endpoint.includes("substations")) return;
    debugLog("GET:  hourly data. Load figure");
    fetchData(endpoint).then((r) => {
      setData(r);
    });
  }
  const [debouncedLoading, setDebouncedLoading] = useState(false);
  const debouncedFetch = useCallback(
    useDebounce(fetchResults, import.meta.env.VITE_DEBOUNCE_TIME - 200),
    []
  );
  useEffect(() => {
    debouncedFetch(endpoint);
  }, [endpoint, debouncedFetch]);

  useEffect(() => {
    setDebouncedLoading(true);
  }, [endpoint]);

  useEffect(() => {
    if (!loadingData) {
      setDebouncedLoading(false);
    }
  }, [loadingData]);
  return { data, loading: debouncedLoading || loadingData };
}
