import { create } from "zustand";

interface LocationStore {
  location: number;
  setLocation: (location: number) => void;
}

export const useLocationStore = create<LocationStore>((set) => ({
  location: -1,
  setLocation: (location) => set({ location }),
}));
