import { createOption } from "@/helper/echarts/hourlyOption";
import { BaseColumns } from "@/helper/echarts/types";
import ReactECharts from "echarts-for-react";
import { useRef } from "react";
import { twMerge } from "tailwind-merge";

interface GraphProps<T extends BaseColumns> {
  className?: string;
  rawData?: T;
  getColor?: (key: keyof T) => string;
  graphType?: "Duration" | "Hourly";
  height?: string;
}

Graph.defaultProps = {
  graphType: "Hourly",
  height: "14rem",
};

export function Graph<T extends BaseColumns>(props: GraphProps<T>) {
  const { className, rawData, getColor, graphType, height } = props;
  const ref = useRef(null);
  let option = createOption(rawData, getColor, graphType);
  if (!height) return;
  return (
    <div>
      <ReactECharts
        ref={ref}
        option={option}
        className={twMerge(
          "w-full transition-opacity duration-500 delay-500",
          className
        )}
        style={{ height }}
      ></ReactECharts>
    </div>
  );
}
