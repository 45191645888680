import { twMerge } from "tailwind-merge";

interface SideBarButtonProps {
  children?: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
  className?: string;
}

const selectedColor =
  "bg-primary-100 text-neutral-800 rounded-full px-2 py-1 my-2 fill-neutral-800";
const neutralColor =
  "px-2 py-1 my-2 fill-neutral-100 hover:bg-primary-500 rounded-full";

export function SideBarButton(props: SideBarButtonProps) {
  const { children, selected, onClick, className } = props;
  return (
    <button
      className={twMerge(
        "rounded-full text-base px-2 py-1 w-20 text-left",
        selected ? selectedColor : neutralColor,
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
