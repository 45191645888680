import { useRequestStore } from "@/pages/requests/stores/requestsStore";
import EditIcon from "@mui/icons-material/Edit";
import {
  GridRowId,
  GridValidRowModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { RequestEditor } from "../../RequestEditor";

interface GridToolbarNewRequestProps {}

const disabledColor = "text-[#b6b6b6]";
const enabledColor = "text-[#1976d2]";

export function GridToolbarNewRequest(props: GridToolbarNewRequestProps) {
  const { requests } = useRequestStore();
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedID, setSelectedID] = useState<number | null>(null);
  const apiRef = useGridApiContext();
  const selectedRows: Map<GridRowId, GridValidRowModel> =
    apiRef.current.getSelectedRows();
  let disabled = !requests || requests === null || selectedRows.size < 1;
  const handleOpen = () => {
    setSelectedID(
      selectedRows.size > 0
        ? Array.from(selectedRows.keys()).map((id) => id as number)[0]
        : null
    );
  };
  return (
    <>
      <button
        disabled={disabled}
        className={twMerge(
          "flex mb-0.5",
          disabled ? disabledColor : enabledColor
        )}
        onClick={() => {
          setModalOpen(true);
          handleOpen();
        }}
      >
        <EditIcon className="pt-1 " fontSize="small" />{" "}
        <div className="pt-0.5">EDIT REQUEST</div>
      </button>
      <RequestEditor
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
      />
    </>
  );
}
