/// <reference types="vite-plugin-svgr/client" />

import RoundedContainer from "@/components/rounded-container";
import { Map } from "@/features/Map";
import { useEffect, useRef, useState } from "react";
import { selectLegend, setSelectedYearEvent } from "./features/Events";
import { Settings } from "./features/Settings";
import { RadioButtons } from "./features/category-radiobuttons";

import { useCallback } from "react";

import SubmenuStore from "@/hooks/submenu";

import { GrowthFigure } from "./features/GrowthFigure";
import { LoadFigure } from "./features/LoadFigure/components/LoadFigure";
import { Columns } from "./types";

export default function Forecast() {
  const { submenu, isSubmenuOpen } = SubmenuStore((state) => state);

  const [selectedYear, setSelectedYear] = useState<number>(2025);
  const [selectedSubstations, setSelectedSubstations] = useState<string[]>([]);

  const [selectedValue, setSelectedValue] = useState<keyof Columns>("Total");

  const growthRef = useRef(null);
  const loadRef = useRef(null);

  const onGrowthEvents = {
    click: useCallback(
      (params: any) => {
        setSelectedYearEvent(params, setSelectedYear, growthRef);
      },
      [setSelectedYear]
    ),
  };

  useEffect(() => {
    selectLegend(selectedValue, growthRef);
    selectLegend(selectedValue, loadRef);
  }, [selectedValue]);

  return (
    <div className="grid grid-flow-row grid-cols-6 h-full">
      <div className="col-span-4 h-full flex flex-col">
        <div className=" bg-neutral-100 pt-3 rounded-md border-b-[1px] m-2 border-neutral-400 shadow-lg">
          <RadioButtons
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        </div>
        <GrowthFigure
          growthRef={growthRef}
          onGrowthEvents={onGrowthEvents}
          substations={selectedSubstations}
          selected_values={selectedValue}
          selectedYear={selectedYear}
        />
        <LoadFigure
          loadRef={loadRef}
          selectedYear={selectedYear}
          selectedSubstations={selectedSubstations}
          selectedValue={selectedValue}
        />
      </div>
      <div className="col-span-2">
        <div className="grid grid-flow-row grid-rows-3 h-full">
          {submenu === "forecast-settings" && isSubmenuOpen ? (
            <RoundedContainer classNameOuter="row-span-3" className="p-0">
              <Settings />
            </RoundedContainer>
          ) : (
            <Map
              className="m-2 row-span-3 rounded-lg border-2 border-neutral-300 shadow-md"
              selectedSubstations={selectedSubstations}
              setSelectedSubstations={setSelectedSubstations}
              columns={selectedValue === "Total" ? [] : [selectedValue]}
              year={selectedYear}
              tiles={true}
              markers={false}
            />
          )}
          {/*
        <RoundedContainer className="row-span-1">
          <Settings />
        </RoundedContainer>
        */}
        </div>
      </div>
    </div>
  );
}
