import { Industry } from "@/api/request/industry";
import { useSectorStore } from "@/pages/requests/stores/sectorStore";
import { IndustryEditor } from "../../../SectorEditor";
import { SectorTabs } from "../Generic/SectorWrapper";

export function IndustryPanel() {
  const [addDefaultIndustry, industries, updateIndustry, deleteIndustry] =
    useSectorStore((state) => [
      state.addDefaultIndustry,
      state.industries,
      state.updateIndustry,
      state.deleteIndustry,
    ]);

  return (
    <>
      <SectorTabs<Industry>
        sectorName="industry"
        sectors={industries}
        setSectors={updateIndustry}
        addSector={addDefaultIndustry}
        deleteSector={deleteIndustry}
        SectorComponent={IndustryEditor}
      />
    </>
  );
}
