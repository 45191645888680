export default (a: any, b: any) => {
  // sort by PV_mean first
  if (a.name === "Solar PV") {
    return -1;
  }
  if (b.name === "Solar PV") {
    return 1;
  }
  // Sort Total to be last
  if (a.name === "Total") {
    return 1;
  }
  if (b.name === "Total") {
    return -1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
};
