import EChartsReact from "echarts-for-react";
import { useEffect, useState } from "react";
import { getSolarData, SolarData } from "../api/getData";
import { EChartsOption } from "echarts";
import { max, unit } from "mathjs";
import * as echarts from "echarts";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocationStore } from "@/stores";

const SORTED_DIRECTIONS = [
  "N",
  "NNE",
  "NE",
  "ENE",
  "E",
  "ESE",
  "SE",
  "SSE",
  "S",
  "SSW",
  "SW",
  "WSW",
  "W",
  "WNW",
  "NW",
  "NNW",
];
const DEFAULT_OPTION: EChartsOption = {
  grid: {
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
  },
  tooltip: {
    trigger: "axis",
    valueFormatter: (value: any) => {
      return unit(value, "m^2").toString();
    },
  },
  legend: {
    show: false,
  },
  polar: {
    radius: ["60%", "80%"],
  },
  angleAxis: {
    type: "category",
    data: SORTED_DIRECTIONS,
    nameLocation: "middle",
    startAngle: 90 + 11.25,
    axisLabel: {
      fontSize: "0.75rem",
    },
  },
  radiusAxis: {
    show: false,
    polarIndex: 0,
    min: 0,
  },
  series: [
    {
      name: "Roof area",
      type: "bar",
      coordinateSystem: "polar",
      color: new echarts.graphic.LinearGradient(0, 0, 0, 0.4, [
        { offset: 0, color: "#ffdf96" },
        { offset: 0.2, color: "#ffdf96" },
        { offset: 1, color: "#fac858" },
      ]),
      data: [],
    },
  ],
};

function updateOption(
  Option: EChartsOption,
  data: SolarData,
  setOption: (option: EChartsOption) => void
) {
  let newOption = JSON.parse(JSON.stringify(Option));
  // set the data in correct order using the SORTED_DIRECTIONS
  newOption.series[0].data = SORTED_DIRECTIONS.map((direction) => {
    const indexOf = data.direction.indexOf(direction.toLowerCase());
    return data.sum[indexOf];
  });
  // divide flat by 16 and set that sum to the second series
  const indexOfFlat = data.direction.indexOf("flat");
  const flatDistributed = data.sum[indexOfFlat] / 16;
  // max of all values excpet flat
  const maxOfAll = max(data.sum.filter((_, index) => index !== indexOfFlat));
  const innerRing = flatDistributed / maxOfAll;
  newOption.polar.radius[0] = "30%"; //`${innerRing * 100}%`;
  newOption.radiusAxis.min = 0;
  setOption(newOption);
}

export interface SolarChartProps {
  selectedSubstations: number[];
}
export function SolarChart(props: SolarChartProps) {
  const { selectedSubstations } = props;
  const [option, setOption] = useState<EChartsOption>(DEFAULT_OPTION);
  const { getAccessTokenSilently } = useAuth0();
  const { location } = useLocationStore();
  useEffect(() => {
    async function asyncFunc() {
      const token = await getAccessTokenSilently();
      const data = await getSolarData(token, location, selectedSubstations);
      updateOption(option, data, setOption);
    }
    asyncFunc();
  }, [selectedSubstations, location]);
  return (
    <EChartsReact
      option={option}
      className="grow h-full"
      style={{ height: "100%" }}
    />
  );
}
