import RoundedContainer from "@/components/rounded-container";
import { SolarChart } from "./SolarChart";
import { BuildingsChart } from "./BuildingsChart";

export interface SolarTabProps {
  selectedSubstations: number[];
}

export function SolarTab(props: SolarTabProps) {
  const { selectedSubstations } = props;
  return (
    <>
      {" "}
      <RoundedContainer classNameOuter="h-1/3">
        <div className="flex h-full">
          <div className="text-sm font-thin w-1/2">
            Solar production forecasts are based on a 3D model of all buildings
            in Sweden. The 3D model includes all rooftops, including their size,
            orientation and shading. From these 3D models endre can estimate
            which rooftops are more, or less, suitable for solar PV use and what
            their production patterns are. Using additional data from the
            buildings we can also analyze the building type, and its associated
            production. <br /> <br />
            Press the map to select a substation.
          </div>
          <SolarChart selectedSubstations={selectedSubstations} />
        </div>
      </RoundedContainer>
      <RoundedContainer classNameOuter="grow">
        <BuildingsChart selectedSubstations={selectedSubstations} />
      </RoundedContainer>
    </>
  );
}

/*
The calculation of the solar production is based on how the total
            roof area is distributed in different directions and what types
            categories of buildings are present. <br /> <br />*/
