import { url } from "./url";

function preprocessRequest(request: string, token?: string) {
  request = request.replaceAll("Solar production", "PV_mean");

  let headers: { [header: string]: string } = {
    "Content-type": "application/json; charset=UTF-8",
    "Cache-Control": "max-age=60",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return { processedUrl: url + request, headers: headers };
}

export default async function fetchData(request: string, token?: string) {
  const { processedUrl, headers } = preprocessRequest(request, token);
  const response = await fetch(processedUrl, {
    method: "GET",
    headers: headers,
  });
  if (!response.ok) {
    throw new Error(
      `Server returned ${response.status}: ${response.statusText}`
    );
  }
  const text = await response.text();
  const data = await JSON.parse(text);
  return data;
}
