import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import { GridToolbarAddRequest } from "./GridToolbarAddRequest";
import { GridToolbarNewRequest } from "./GridToolbarRequestEditor";
import { GridToolbarDeleteButton } from "./HandleDeleteModal";

export function CustomGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
      <GridToolbarFilterButton />

      <GridToolbarDeleteButton />
      <GridToolbarExport />
      <GridToolbarNewRequest />
      <GridToolbarAddRequest />
    </GridToolbarContainer>
  );
}

// <GridToolbarColumnsButton />
