import { RequestGraph } from "@/features/RequestGraph";
import { Sectors } from "../types";

interface Part5Props {
  sectors: Sectors;
}

export function Part5(props: Part5Props) {
  const { sectors } = props;
  return (
    <div className="w-full">
      <div className="text-2xl text-neutral-800">Profile </div>
      {sectors && (
        <RequestGraph
          hidden={false}
          sectors={sectors}
          height="20rem"
        ></RequestGraph>
      )}
    </div>
  );
}
