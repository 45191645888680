import {
  useDeleteCommercial,
  useInsertCommercial,
  useUpdateCommercial,
} from "@/api/request/commercial";
import {
  useDeleteIndustry,
  useInsertIndustry,
  useUpdateIndustry,
} from "@/api/request/industry";
import {
  useDeletePublic,
  useInsertPublic,
  useUpdatePublic,
} from "@/api/request/public";
import {
  useDeleteResidential,
  useInsertResidential,
  useUpdateResidential,
} from "@/api/request/residential";
import { useGetSectors } from "@/api/request/sectors";
import { omitLocal, toLocalArray } from "../helper/api";
import { useSectorStore } from "../stores/sectorStore";

function useApplyChangesIndustry() {
  const [industries_, updateIndustryLocal] = useSectorStore((state) => [
    state.industries,
    state.updateIndustry,
  ]);
  const { updateIndustry } = useUpdateIndustry();
  const { insertIndustry } = useInsertIndustry();
  const { deleteIndustry } = useDeleteIndustry();

  async function applyChanges(requestId: number) {
    const industries = industries_.filter((s) => s.status_ !== "synced");
    if (industries.length === 0) return true;
    let ok = true;
    for (const ind of industries) {
      let newRequestId = ind.id_;
      if (ind.status_ === "new") {
        newRequestId = await insertIndustry(
          omitLocal({ ...ind, request_id: requestId })
        );
        if (!newRequestId) {
          ok = false;
          continue;
        }
        updateIndustryLocal(ind.id_, { ...ind, id_: newRequestId });
      } else if (ind.status_ === "modified") {
        ok = ok && (await updateIndustry(omitLocal(ind)));
      } else if (ind.status_ === "deleted" && typeof ind.id_ === "number") {
        ok = ok && (await deleteIndustry(ind.id_));
      } else {
        continue;
      }
    }
    return ok;
  }
  return { applyChanges };
}

function useApplyChangesResidential() {
  const sectorStore = useSectorStore();
  const { updateResidential } = useUpdateResidential();
  const { insertResidential } = useInsertResidential();
  const { deleteResidential } = useDeleteResidential();

  async function applyChanges(requestId: number) {
    const residentials = sectorStore.residentials.filter(
      (s) => s.status_ !== "synced"
    );
    if (residentials.length === 0) return true;
    let ok = true;
    for (const res of residentials) {
      let newRequestId = res.id_;

      if (res.status_ === "new") {
        newRequestId = await insertResidential(
          omitLocal({ ...res, request_id: requestId })
        );
        if (!newRequestId) {
          ok = false;
          continue;
        }
        sectorStore.updateResidential(res.id_, { ...res, id_: newRequestId });
      } else if (res.status_ === "modified") {
        ok = ok && (await updateResidential(omitLocal(res)));
      } else if (res.status_ === "deleted" && typeof res.id_ === "number") {
        ok = ok && (await deleteResidential(res.id_));
      } else {
        continue;
      }
    }
    return ok;
  }
  return { applyChanges };
}

function useApplyChangesCommercial() {
  const sectorStore = useSectorStore();
  const { updateCommercial } = useUpdateCommercial();
  const { insertCommercial } = useInsertCommercial();
  const { deleteCommercial } = useDeleteCommercial();

  async function applyChanges(requestId: number) {
    const commercials = sectorStore.commercials.filter(
      (s) => s.status_ !== "synced"
    );
    if (commercials.length === 0) return true;
    let ok = true;
    for (const com of commercials) {
      let newRequestId = com.id_;

      if (com.status_ === "new") {
        newRequestId = await insertCommercial(
          omitLocal({ ...com, request_id: requestId })
        );
        if (!newRequestId) {
          ok = false;
          continue;
        }
        sectorStore.updateCommercial(com.id_, { ...com, id_: newRequestId });
      } else if (com.status_ === "modified") {
        ok = ok && (await updateCommercial(omitLocal(com)));
      } else if (com.status_ === "deleted" && typeof com.id_ === "number") {
        ok = ok && (await deleteCommercial(com.id_));
      } else {
        continue;
      }
    }
    return ok;
  }
  return { applyChanges };
}

function useApplyChangesPublic() {
  const sectorStore = useSectorStore();
  const { updatePublic } = useUpdatePublic();
  const { insertPublic } = useInsertPublic();
  const { deletePublic } = useDeletePublic();

  async function applyChanges(requestId: number) {
    const publics = sectorStore.publics.filter((s) => s.status_ !== "synced");
    if (publics.length === 0) return true;
    let ok = true;
    for (const pub of publics) {
      let newRequestId = pub.id_;

      if (pub.status_ === "new") {
        newRequestId = await insertPublic(
          omitLocal({ ...pub, request_id: requestId })
        );
        if (!newRequestId) {
          ok = false;
          continue;
        }
        sectorStore.updatePublic(pub.id_, { ...pub, id_: newRequestId });
      } else if (pub.status_ === "modified") {
        ok = ok && (await updatePublic(omitLocal(pub)));
      } else if (pub.status_ === "deleted" && typeof pub.id_ === "number") {
        ok = ok && (await deletePublic(pub.id_));
      } else {
        continue;
      }
    }
    return ok;
  }
  return { applyChanges };
}

function useApplyChanges() {
  const { applyChanges: applyChangesIndustry } = useApplyChangesIndustry();
  const { applyChanges: applyChangesResidential } =
    useApplyChangesResidential();
  const { applyChanges: applyChangesCommercial } = useApplyChangesCommercial();
  const { applyChanges: applyChangesPublic } = useApplyChangesPublic();

  async function applyChanges(requestId: number) {
    let ok = (await applyChangesIndustry(requestId)) && true;
    ok = ok && (await applyChangesResidential(requestId));
    ok = ok && (await applyChangesCommercial(requestId));
    ok = ok && (await applyChangesPublic(requestId));
    return ok;
  }

  return { applyChanges };
}

export function useSectors() {
  const [
    setIndustries,
    setResidentials,
    setCommercials,
    setPublics,
    resetSectors,
  ] = useSectorStore((state) => [
    state.setIndustries,
    state.setResidentials,
    state.setCommercials,
    state.setPublics,
    state.resetSectors,
  ]);
  const { applyChanges } = useApplyChanges();
  const { getSectors } = useGetSectors();

  async function syncSectors(requestId: number) {
    resetSectors();
    let data = await getSectors(requestId).then((data) => {
      return data;
    });
    if (!data) return;
    setIndustries(toLocalArray(data.industry));
    setResidentials(toLocalArray(data.residential));
    setCommercials(toLocalArray(data.commercial));
    setPublics(toLocalArray(data.public_));
  }

  return {
    refreshData: () => {},
    syncSectors,
    applyChanges,
  };
}
