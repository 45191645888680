import { Combobox } from "@/components/ComboBox";
import { LatLngLiteral } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import { GeoJSON, MapContainer, Marker, TileLayer } from "react-leaflet";
import { useMap } from "../hooks/MapHook";

interface TooltipInfoProps {
  markerLocation: LatLngLiteral;
  substation: string;
  setSubstation: (value: string) => void;
  stationIds: string[];
}

function TooltipInfo(props: TooltipInfoProps) {
  const { markerLocation, substation, setSubstation, stationIds } = props;
  return (
    <div className="p-2 bg-neutral-100">
      Coordinates: <br />
      {markerLocation.lat}, <br /> {markerLocation.lng}
      <div>substation</div>
      <Combobox
        selectedValue={substation}
        list={stationIds}
        onChange={(value) => {
          setSubstation(value);
        }}
        size="small"
      ></Combobox>
    </div>
  );
}

interface MapProps {
  setSubstation?: (value: string) => void;
  coordinates?: LatLngLiteral | null;
  setMarkers?: (value: {
    lat: number;
    lng: number;
    substation: string;
  }) => void;
  height?: string;
  width?: string;
}

// set default props
Map.defaultProps = {
  height: "20rem",
  width: "18rem",
};

export function Map(props: MapProps) {
  const { setMarkers, coordinates, height, width } = props;
  const { filteredTiles, centroid, stationIds } = useMap();
  const [map, setMap] = useState<any>(null);
  const geoJsonLayer = useRef<any>(null);

  useEffect(() => {
    if (map && centroid) {
      map.setView(centroid, 10, {
        animate: true, // animate the move
        duration: 1, // duration in seconds
      });
    }
  }, [centroid]);

  useMemo(() => {
    if (geoJsonLayer.current && filteredTiles) {
      geoJsonLayer.current.clearLayers().addData(filteredTiles);
    }
  }, [filteredTiles]);
  if (!height || !width) {
    return;
  }
  return (
    <div>
      <MapContainer
        center={[60, 15]} // middle
        zoom={6}
        zoomControl={false}
        style={{ height, width }}
        ref={setMap}
        className="rounded-md"
      >
        <GeoJSON
          ref={geoJsonLayer}
          // @ts-ignore
          data={filteredTiles}
          style={{ color: "#949494" }}
          eventHandlers={{
            click: (e) => {
              setMarkers &&
                setMarkers({
                  lat: e.latlng.lat,
                  lng: e.latlng.lng,
                  substation: e.sourceTarget.feature.properties.station_id,
                });
            },
          }}
        >
          {coordinates && <Marker position={coordinates}></Marker>}
        </GeoJSON>
        <TileLayer
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={19}
        />
      </MapContainer>
    </div>
  );
}
