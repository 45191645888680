import { Commercial } from "@/api/request/commercial";
import { Industry } from "@/api/request/industry";
import { Public } from "@/api/request/public";
import { Residential } from "@/api/request/residential";
import Checkbox from "@/components/Checkbox";
import { Local } from "@/pages/requests/helper/api";
import { useEffect } from "react";
import { Sectors } from "../types";

const SectorTemplate: Local<any> = {
  id_: 0,
  status_: "new",
  request_id: undefined,
  name: "",
  subsector: "",
  created_ts: "",
  updated_ts: "",
  creator_id: "",
  updater_id: "",
};

export const IndustryTemplate: Local<Industry> = {
  ...SectorTemplate,
  subsector: "generic",
  area: undefined,
  power_demand: undefined,
  use_demand: false,
  ev_stations: false,
};

export const ResidentialTemplate: Local<Residential> = {
  ...SectorTemplate,
  area: undefined,
  forecast_metric: "dwellings",
  electric_heating: false,
  dwelling_area: 20,
  dwellings: undefined,
  energy_performance: 48 * 1000,
  solar_power: 9 * 1000,
  subsector: "single_family",
};

export const CommercialTemplate: Local<Commercial> = {
  area: undefined,
  forecast_metric: "area",
  energy_performance: 0,
  ...SectorTemplate,
};

export const PublicTemplate: Local<Public> = {
  area: undefined,
  forecast_metric: "area",
  energy_performance: 0,
  ...SectorTemplate,
};

interface Part2Props {
  setIsNextDisabled: (value: boolean) => void;
  sectors: Sectors;
  setSectors: (value: Sectors) => void;
}

export function Part2(props: Part2Props) {
  const { setIsNextDisabled, sectors, setSectors } = props;

  let commercial = sectors.commercial.length > 0;
  let residential = sectors.residential.length > 0;
  let industrial = sectors.industry.length > 0;
  let public_ = sectors.public.length > 0;

  useEffect(() => {
    // if all sectors are false, disable next button
    if (!commercial && !residential && !industrial && !public_) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [commercial, residential, industrial, public_]);
  return (
    <div>
      <div className="text-2xl text-neutral-800">
        Which sectors will be included?
      </div>
      <div className="flex mt-8 space-x-5 justify-between">
        <Checkbox
          checked={commercial}
          onChange={(value) => {
            if (value) {
              setSectors({ ...sectors, commercial: [CommercialTemplate] });
            } else {
              setSectors({ ...sectors, commercial: [] });
            }
          }}
        >
          <div className="text-neutral-800 absolute -ml-8 mt-5 text-center">
            Commercial
          </div>
        </Checkbox>
        <Checkbox
          checked={residential}
          onChange={(value) => {
            if (value) {
              setSectors({ ...sectors, residential: [ResidentialTemplate] });
            } else {
              setSectors({ ...sectors, residential: [] });
            }
          }}
        >
          <div className="text-neutral-800 absolute -ml-8 mt-5 text-center">
            Residential
          </div>
        </Checkbox>
        <Checkbox
          checked={industrial}
          onChange={(value) => {
            if (value) {
              setSectors({ ...sectors, industry: [IndustryTemplate] });
            } else {
              setSectors({ ...sectors, industry: [] });
            }
          }}
        >
          <div className="text-neutral-800 absolute -ml-6 mt-5 text-center">
            Industrial
          </div>
        </Checkbox>
        <Checkbox
          checked={public_}
          onChange={(value) => {
            if (value) {
              setSectors({ ...sectors, public: [PublicTemplate] });
            } else {
              setSectors({ ...sectors, public: [] });
            }
          }}
        >
          <div className="text-neutral-800 absolute -ml-3 mt-5 text-center">
            Public
          </div>
        </Checkbox>
      </div>
    </div>
  );
}
