import { suffixFormatter } from "@/utils/suffixFormatter";
import { LineSeriesOption } from "echarts";
import { EChartsOption } from "echarts-for-react";
import { formatOptionData } from "./hourlySeries";
import { BaseColumns } from "./types";

const UNIT = "Wh/h";
const formatter = (value: number) => {
  return suffixFormatter({ value: value }) + UNIT;
};

export function createOption<T extends BaseColumns>(
  rawData?: T,
  getColor?: (key: keyof T) => string,
  graphType?: "Duration" | "Hourly",
  selectedLegends?: { [key: string]: boolean } | undefined,
  zoom?: [number, number]
) {
  if (!rawData || Object.keys(rawData).length === 0) {
    return DEFAULT_OPTION;
  }

  // creates the series
  const { series, xAxis } = formatOptionData({
    rawData,
    graphType: graphType!,
    getColor: getColor,
  });

  if (!xAxis || xAxis.length === 0) {
    return DEFAULT_OPTION;
  }

  const newOption = JSON.parse(JSON.stringify(DEFAULT_OPTION));

  newOption.xAxis[0].show = xAxis.length > 0;
  newOption.yAxis[0].show = xAxis.length > 0;
  newOption.title.show = series.length === 0;
  newOption.yAxis[0].axisLabel.formatter = formatter;
  newOption.tooltip.valueFormatter = formatter;

  newOption.series = series;
  newOption.xAxis[0].data = xAxis;
  newOption.color = series.map(
    (series: LineSeriesOption) => series.areaStyle?.color
  );

  // updating formatters to ensure they work correctly
  //newOption.yAxis[0].axisLabel.formatter = yAxisFormatter;
  //newOption.tooltip.formatter = tooltipFormatter;

  const documentWidth = document.documentElement.clientWidth;
  newOption.grid = {
    left: Math.round(documentWidth * 0.03) + 50,
    right: Math.round(documentWidth * 0.02),
    bottom: Math.round(documentWidth * 0.02),
    top: Math.round(documentWidth * 0.02),
  };
  newOption.dataZoom[0].start = zoom ? zoom[0] : 0;
  newOption.dataZoom[0].end = zoom ? zoom[1] : 100;

  newOption.legend.data = series.map((series: LineSeriesOption) => {
    return series.name;
  });
  if (graphType === "Duration") {
    newOption.xAxis[0].type = "category";
  }
  return newOption;
}
const DEFAULT_OPTION: EChartsOption = {
  title: {
    show: true,
    textStyle: {
      color: "grey",
      fontSize: 20,
    },
    text: "No data",
    left: "center",
    top: "center",
  },

  // ...

  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    valueFormatter: (value: number) => formatter(value),
  },

  legend: {
    show: false,
    top: "center",
    right: "right",
    textStyle: {
      fontSize: "0.75rem",
    },
  },
  xAxis: [
    {
      show: false,
      type: "time",
      axisTick: { show: true },
      axisLabel: {
        fontSize: "0.75rem",
        hideOverlap: true,
      },
    },
  ],
  yAxis: [
    {
      show: false,
      type: "value",
      axisLabel: {
        fontSize: "0.75rem",
      },
      valueFormatter: (value: number) => formatter(value),
      axisLine: {
        lineStyle: {
          color: "grey",
        },
      },
    },
  ],
  dataZoom: [
    {
      type: "inside",
      show: true,
    },
  ],
  animation: true,
  series: [] as LineSeriesOption[],
};
