import {
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions,
  Combobox as Combobox_,
} from "@headlessui/react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import ComponentTitle from "./ComponentTitle";

const people = [
  "Durward Reynolds",
  "Kenton Towne",
  "Therese Wunsch",
  "Benedict Kessler",
  "Katelyn Rohan",
];

const SIZES = {
  small: "px-1 h-7 w-20 font-normal",
  medium: "px-1 h-7 w-30 font-normal",
  large: "px-1 h-9 w-40",
};

const SIZES_TEXT = {
  small: "text-xs",
  medium: "text-sm",
  large: "text-base",
};

interface ComboboxProps {
  list: string[];
  selectedValue: string;
  onChange: (value: string) => void;
  size?: "small" | "medium" | "large";
  placeholder?: string;
  title?: string;
}

export function Combobox(props: ComboboxProps) {
  const { size, list, selectedValue, onChange, placeholder, title } = props;
  const [query, setQuery] = useState("");

  const filteredList =
    query === undefined || query === ""
      ? list
      : list.filter((item) => {
          return item.toLowerCase().includes(query.toLowerCase());
        });

  let size_: ComboboxProps["size"] = "medium";
  if (size) size_ = size;
  return (
    <ComponentTitle title={title} size="small">
      <Combobox_ value={selectedValue} onChange={onChange}>
        <div className={twMerge("relative w-40")}>
          <Combobox_.Input
            placeholder={placeholder}
            className={twMerge(
              "text-neutral-800",
              "relative w-full h-7 cursor-default rounded-md bg-neutral-100 ring-1 ring-neutral-200 py-1 px-2 pr-10 text-left shadow-sm ",
              "focus:outline-none focus-visible:border-indigo-500",
              SIZES_TEXT[size_]
            )}
            onChange={(event) => setQuery(event.target.value)}
          ></Combobox_.Input>
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
            <span className=" absolute inset-y-0 right-0 flex items-center pr-1 ">
              <UnfoldMoreIcon style={{ color: "#949494", height: "1.2rem" }} />
            </span>
          </ComboboxButton>
          <ComboboxOptions
            className={twMerge(
              "absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-0 shadow-lg ring-1 ring-black/5",
              "focus:outline-none",
              SIZES_TEXT[size_]
            )}
          >
            {filteredList.map((item) => (
              <ComboboxOption
                className={({ active }) =>
                  twMerge(
                    "relative cursor-default select-none py-2 pl-2 pr-4",
                    active
                      ? "bg-primary-100 text-primary-600"
                      : "text-gray-900",
                    // @ts-ignore
                    SIZES_TEXT[size_]
                  )
                }
                key={item}
                value={item}
              >
                {item}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </div>
      </Combobox_>
    </ComponentTitle>
  );
}
