import { Checkbox as Checkbox_ } from "@headlessui/react";
import CheckIcon from "@mui/icons-material/Check";
import { twMerge } from "tailwind-merge";
export interface CheckboxProps {
  children?: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}

export function Checkbox(props: CheckboxProps) {
  const { checked, onChange, children, className } = props;

  return (
    <Checkbox_
      checked={checked}
      onChange={onChange}
      className="group flex cursor-pointer"
    >
      <div
        className={twMerge(
          "size-5 rounded-md bg-neutral-100 p-0.5 mr-1 ring-1 ring-neutral-500 ring-inset group-data-[checked]:bg-primary-500 flex items-center justify-center",
          className
        )}
      >
        <div className="hidden fill-black group-data-[checked]:block ">
          <CheckIcon color="inherit" fontSize="inherit" />
        </div>
      </div>
      {children}
    </Checkbox_>
  );
}
