import { Request } from "@/api/request/request";
import { RequestGraph } from "@/features/RequestGraph";
import { Local } from "@/pages/requests/helper/api";
import { useSectorStore } from "@/pages/requests/stores/sectorStore";
import { memo } from "react";
import { LocationSettings } from "../../RequestSettings/components/Location";
import { ProjectSettings } from "../../RequestSettings/components/Project";

const Graph = memo(() => {
  const sectors = useSectorStore((state) => {
    return {
      industry: state.industries,
      residential: state.residentials,
    };
  });
  return (
    <div>
      <RequestGraph
        hidden={false}
        sectors={sectors}
        height="15rem"
      ></RequestGraph>
    </div>
  );
});

interface OverviewPageProps {
  editableRow?: Local<Request> | null;
  setEditableRow: React.Dispatch<
    React.SetStateAction<OverviewPageProps["editableRow"]>
  >;
}

export const OverviewPage = memo(
  (props: OverviewPageProps) => {
    const { editableRow, setEditableRow } = props;

    if (!editableRow) return null;
    const responsibleStatus = editableRow.responsible ? "green" : "red";
    return (
      <div className="w-1/2 space-y-4 mx-auto">
        <ProjectSettings request={editableRow} setRequest={setEditableRow} />
        <LocationSettings request={editableRow} setRequest={setEditableRow} />
      </div>
    );
  },
  (prev, next) => {
    return prev.editableRow === next.editableRow;
  }
);
