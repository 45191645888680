import fetchData from "@/utils/fetch";

export interface BuildingAreaData {
  building_type: string[];
  area: number[];
}
export async function getBuildingAreaData(
  token: string,
  location: number,
  selectedSubstations?: number[]
): Promise<BuildingAreaData> {
  let query = "basis/buildings/" + location + "?";
  if (selectedSubstations && selectedSubstations.length > 0) {
    query += `station_id=${selectedSubstations.join("&station_id=")}&`;
  }
  let data = await fetchData(query, token);
  return data;
}
