import { useEffect, useMemo } from "react";
import {
  useGetChildren,
  useGetLevels,
  useGetSubstationInfo,
  useGetTiles,
  useGetTopStation,
} from "../api/grid";
import { useHierarchyStore } from "../stores/levelStore";
import { Features } from "../types";

function filterTiles(tiles: Features, children: string[]) {
  if (!children || children.length === 0) {
    return tiles;
  }
  return tiles.filter((tile: any) => {
    return children.includes(tile.properties.station_id.toString());
  });
}

function setFilteredTiles(
  tiles: ReturnType<typeof useGetTiles>["data"],
  childrenIds?: string[] | null
) {
  if (!tiles || !childrenIds || childrenIds.length === 0) {
    return;
  }
  return filterTiles(tiles.features, childrenIds);
}

interface SubstationProps {
  setSelectedSubstations?: React.Dispatch<React.SetStateAction<string[]>>;
}

export function useSubstationsHandle(props: SubstationProps) {
  const { setSelectedSubstations } = props;
  const { data: substationInfo } = useGetSubstationInfo();

  const { level, topStationsIds, setTopStation, setMinMaxLevel } =
    useHierarchyStore();
  const { data: levelInfo } = useGetLevels();
  const { data: tiles } = useGetTiles();
  const { topStationId } = useGetTopStation();
  const { data: childrenIds } = useGetChildren(
    topStationsIds[topStationsIds.length - 1]
  );

  useEffect(() => {
    console.log("levelInfo", levelInfo);
    if (levelInfo) setMinMaxLevel(levelInfo);
  }, [levelInfo]);

  useMemo(() => {
    const asyncFunc = async () => {
      if (topStationsIds.length > 0 || !topStationId) {
        return;
      }
      if (!setSelectedSubstations) {
        return;
      }
      setTopStation([topStationId]);
      setSelectedSubstations([topStationId]);
    };
    asyncFunc();
  }, [topStationId]);

  useMemo(() => {
    if (!setSelectedSubstations) return;
    setSelectedSubstations([topStationsIds[topStationsIds.length - 1]]);
  }, [topStationsIds]);

  let filteredTiles;
  if (level === 0) {
    filteredTiles = setFilteredTiles(tiles, [topStationsIds[0]]);
  } else {
    filteredTiles = setFilteredTiles(tiles, childrenIds);
  }

  return { tiles, substationInfo, filteredTiles };
}
