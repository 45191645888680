import { useApi } from "@/services/Api";
import { Sector } from "./request";

export interface Industry extends Sector {
  subsector: "generic" | "warehouse";
  power_demand?: number;
  area?: number;
  use_demand?: boolean;
  ev_stations: boolean; // only if industry is warehouse
}

export function useUpdateIndustry() {
  const { loading, fetchData } = useApi("PUT");
  async function updateIndustry(newRow: Industry) {
    return fetchData("request/industry/", newRow).then((data) => {
      return data;
    });
  }
  return { loading, updateIndustry };
}

export function useInsertIndustry() {
  const { loading, fetchData } = useApi("POST");
  async function insertIndustry(newRow: Industry) {
    return fetchData("request/industry/", newRow);
  }
  return { loading, insertIndustry };
}

export function useDeleteIndustry() {
  const { loading, fetchData } = useApi("DELETE");
  async function deleteIndustry(id: number) {
    return fetchData("request/industry/" + id);
  }
  return { loading, deleteIndustry };
}

export function useGetIndustries() {
  const { loading, fetchData } = useApi();

  async function getIndustries(requestId: number) {
    let endpoint = "request/industries/" + requestId;
    let data = await fetchData(endpoint)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }
  return { getIndustries, loading };
}
