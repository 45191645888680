import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import "./index.css";

if (import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: "https://6c1eadb4b1d7ae7d7eb5d221fb6fc06f@o4508239625125888.ingest.de.sentry.io/4508239630303312",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <Auth0Provider
      domain="dev-ogt4cea8rz7kt35m.eu.auth0.com"
      clientId="PlEiMBBpcQff44vL05PyESNnknAOOC6c"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://dev-ogt4cea8rz7kt35m.eu.auth0.com/api/v2/",
      }}
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>
);
