import { Checkbox } from "@headlessui/react";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Slider, ThemeProvider, createTheme } from "@mui/material";
import { round } from "mathjs";
import { twMerge } from "tailwind-merge";
import { SliderSettingsProps } from "./types";
const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#05b1f0",
    },
  },
});

interface LockProps {
  className?: string;
  disabled?: boolean;
  locked: boolean;
  setLocked?: (locked: boolean) => void;
}

function PercentageText(props: { text?: string }) {
  const { text } = props;
  if (!text) return <></>;
  return <div className="text-sm h-6 mt-2 overflow-hidden">{text}</div>;
}
function Lock(props: LockProps) {
  const { locked, setLocked, disabled } = props;
  return (
    <>
      <Checkbox
        className={twMerge(
          "cursor-pointer ml-2",
          disabled ? "opacity-50 cursor-auto" : ""
        )}
        checked={locked}
        onChange={setLocked}
        disabled={disabled}
      >
        <div className={!locked ? "hidden" : ""}>
          <LockIcon
            fontSize="small"
            className=""
            style={{ color: "#eddb34" }}
          />
        </div>
        <div className={locked ? "hidden" : ""}>
          <LockOpenIcon fontSize="small" className="" />
        </div>
      </Checkbox>
    </>
  );
}

function Percentages(props: {
  percentages: number | number[];
  text?: string[];
}) {
  const { percentages, text } = props;
  if (typeof percentages === "number") {
    return (
      <div className="flex mb-[-10px] ">
        <div className={`text-center`} style={{ width: `${percentages}%` }}>
          <PercentageText text={text && text[0]} />
          {percentages > 0 && percentages + "%"}
        </div>
        <div
          className={`text-center`}
          style={{ width: `${100 - percentages}%` }}
        >
          <PercentageText text={text && text[1]} />
          {percentages < 100 && 100 - percentages + "%"}
        </div>
      </div>
    );
  }
  let sumP = percentages.reduce((summation, p) => summation + p, 0);
  return (
    <div className="flex mb-[-10px] ">
      {" "}
      {percentages.map((p, i) => {
        return (
          <div className={`text-center`} style={{ width: `${p}%` }}>
            <PercentageText text={text && text[i]} />
            {p > 0 && p + "%"}
          </div>
        );
      })}
      <div className={`text-center`} style={{ width: `${100 - sumP}%` }}>
        <PercentageText text={text && text[text.length - 1]} />
        {sumP < 100 && 100 - sumP + "%"}
      </div>
    </div>
  );
}
function SliderSetting(props: SliderSettingsProps) {
  const {
    value,
    onChange,
    title,
    subtext,
    tooltip,
    marks,
    min,
    max,
    step,
    defaultValue,
    track,
    disabled,
    locked,
    setLocked,
    valueLabelDisplay,
    valueLabelFormat,
    percentageText,
  } = props;
  let percentages;
  if (value !== undefined)
    percentages =
      typeof value === "number"
        ? round((value / max) * 100)
        : value.reduce((prev: number[], v, i) => {
            if (prev.length === 0) return [round((v / max) * 100)];
            prev.push(round((v / max) * 100) - prev[i - 1]);
            return prev;
          }, []);
  return (
    <div className="px-8 py-3 text-neutral-800 text-lg text-left">
      <div className="flex justify-between">
        <div className="flex">
          <div className="font-medium">{title}</div>
          {locked !== undefined ? (
            <>
              <Lock locked={locked} setLocked={setLocked} disabled={disabled} />
            </>
          ) : null}
        </div>
        <InfoIcon fontSize="small" className="mt-2" />
      </div>
      <div className="text-left text-sm text-neutral-600">{subtext}</div>
      {step && percentages !== undefined && step < 0.5 ? (
        <Percentages
          percentages={percentages}
          text={percentageText}
        ></Percentages>
      ) : null}
      <ThemeProvider theme={muiTheme}>
        <Slider
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
          defaultValue={defaultValue}
          marks={marks}
          size="small"
          disabled={locked === undefined ? disabled : locked || disabled}
          track="normal"
          valueLabelDisplay={valueLabelDisplay}
          valueLabelFormat={valueLabelFormat}
        />
      </ThemeProvider>
    </div>
  );
}

export function SliderSettings(props: {
  sliderSettings: SliderSettingsProps[];
}) {
  const { sliderSettings } = props;
  return (
    <div>
      {sliderSettings.map((prop, index) => {
        const {
          value,
          onChange,
          title,
          subtext,
          tooltip,
          marks,
          min,
          max,
          step,
          defaultValue,
          track,
          disabled,
          locked,
          setLocked,
          valueLabelDisplay,
          valueLabelFormat,
          percentageText,
        } = prop;
        return (
          <SliderSetting
            value={value}
            onChange={onChange}
            title={title}
            subtext={subtext}
            tooltip={tooltip}
            marks={marks}
            min={min}
            max={max}
            step={step}
            defaultValue={defaultValue}
            track={track}
            disabled={disabled}
            locked={locked}
            setLocked={setLocked}
            valueLabelDisplay={valueLabelDisplay}
            valueLabelFormat={valueLabelFormat}
            percentageText={percentageText}
          />
        );
      })}
    </div>
  );
}
