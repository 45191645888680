import { getColor } from "../../../Colors";
import SeriesSort from "../../SeriesSort";
import { inputData, optionProps } from "../types";

function formatDate(date: string) {
  return new Date(date).toString().slice(0, 25);
}

function sortToDurationCurve(data: inputData["rawData"]) {
  // sort by total power per hour
  let totalPower: number[] = new Array(data.time.length).fill(0);
  let sortedData: inputData["rawData"] = data;
  for (let key of Object.keys(data) as (keyof typeof data)[]) {
    if (key === "time") {
      continue;
    }
    totalPower = totalPower.map((_, i) => {
      return totalPower[i] + (data[key][i] as number);
    });
  }
  let order = totalPower.map((_, i) => i);
  order.sort((a, b) => totalPower[b] - totalPower[a]);
  for (let key of Object.keys(data) as (keyof typeof data)[]) {
    // @ts-ignore
    sortedData[key] = order.map((i) => data[key][i]);
  }
  return sortedData;
}
export function processData(data: inputData) {
  const { rawData, selectedDataColumn } = data;
  // Your formatting logic here
  let rawDataCopy: typeof rawData = JSON.parse(JSON.stringify(rawData));
  const processedData: optionProps = { xAxis: [], series: [] };
  if (!rawDataCopy || Object.keys(rawDataCopy).length === 0) {
    return { processedData };
  }
  //
  let stackingStrategy: "samesign" | "positive" | "all" = "samesign";
  processedData.xAxis = rawDataCopy.time.map((date: string) => {
    return formatDate(date);
  });
  let totalSeriesData: {
    value: [number | string | Date, number];
    name: string;
  }[] = [];
  if (data.durVHour !== "Duration") {
    totalSeriesData = rawDataCopy.time.map((time: string) => {
      return { value: [time, 0], name: formatDate(time) };
    });
  }
  if (data.durVHour === "Duration") {
    // sort data
    rawDataCopy = sortToDurationCurve(rawDataCopy);
    // format xAxis as range or 0 to length
    processedData.xAxis = rawDataCopy.time.map((_: string, i: number) => {
      return i.toString();
    });
    stackingStrategy = "all";
  }

  for (let key_string of Object.keys(rawDataCopy)) {
    const key = key_string as keyof typeof rawDataCopy;
    if (key === "time") {
      continue;
    }

    processedData.series.push({
      name: key,
      type: "line",
      stack: "Total",
      smooth: true,
      symbol: "none",
      sampling: "lttb",
      stackStrategy: stackingStrategy,
      progressive: 500,
      progressiveThreshold: 1000,
      animation: true,
      areaStyle: {
        color: getColor(key, selectedDataColumn),
      },
      lineStyle: {
        color: getColor(key, selectedDataColumn),
        width: 0,
      },
      emphasis: {
        focus: "series",
      },
      data: rawDataCopy[key].map((columnValue: number, i: number) => {
        let xValue: number | Date;

        if (data.durVHour === "Duration") {
          xValue = i;
        } else {
          xValue = new Date(rawDataCopy.time[i]);
          totalSeriesData[i].name = formatDate(rawDataCopy.time[i]);
          totalSeriesData[i].value[0] = xValue;
          totalSeriesData[i].value[1] += columnValue;
        }

        return {
          value: [xValue, columnValue],
          name: formatDate(rawDataCopy.time[i]),
        };
      }),
    });
  }
  if (data.durVHour !== "Duration") {
    // Add the total series
    processedData.series.push({
      name: "Total",
      type: "line",
      stack: "self",
      symbol: "none",
      smooth: true,
      emphasis: {
        //disabled: true,
        focus: "series",
      },
      symbolSize: 0,
      itemStyle: {
        color: "#242424",
      },
      lineStyle: {
        color: "#242424",
        opacity: 0.6,
      },
      data: totalSeriesData,
    });
  }
  // Sort the series by name.
  // This is in order to ensure it can be referenced
  // easily with other charts
  processedData.series.sort(SeriesSort);

  return { processedData };
}
