import * as React from "react";
import { twMerge } from "tailwind-merge";

const SIZES_TITLE = {
  small: "text-xs",
  medium: "text-sm",
  large: "text-base",
};

export interface IComponentTitleProps {
  children?: React.ReactNode;
  title?: string;
  size?: "small" | "medium" | "large";
  className?: string;
}

export default function ComponentTitle(props: IComponentTitleProps) {
  const { children, title, size, className } = props;
  return (
    <div className={className}>
      <div
        className={twMerge(
          "text-sm pb-1 text-neutral-700",
          size ? SIZES_TITLE[size] : SIZES_TITLE.medium
        )}
      >
        {title}
      </div>
      {children}
    </div>
  );
}
