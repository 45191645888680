import fetchData from "@/utils/fetch";

export interface SolarData {
  direction: string[];
  sum: number[];
}

export async function getSolarData(
  token: string,
  location: number,
  selectedSubstations?: number[]
): Promise<SolarData> {
  let query = "basis/solar/" + location + "?";
  if (selectedSubstations && selectedSubstations.length > 0) {
    query += `station_id=${selectedSubstations.join("&station_id=")}&`;
  }
  const data = await fetchData(query, token);
  return data;
}
