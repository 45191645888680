/// <reference types="vite-plugin-svgr/client" />
import CollapseMenu from "@/assets/CollapseMenu.svg?react";
import Dashboard from "@/assets/Dashboard.svg?react";
import ExpandMenu from "@/assets/ExpandMenu.svg?react";
import Forecast from "@/assets/Forecast.svg?react";
import LogoSmall from "@/assets/LogoSmall.png";
import Requests from "@/assets/Requests.svg?react";
import Logo from "@/assets/endre_logo_white.png";
import SubmenuStore from "@/hooks/submenu";
import { useAuth0 } from "@auth0/auth0-react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const navLinks = [
  {
    name: "Home",
    url: "/",
    icon: Dashboard,
    submenu: new Map(),
  },
  {
    name: "Forecast",
    url: "/forecast",
    icon: Forecast,
    submenu: new Map([["Scenarios", "forecast-settings"]]),
  } /*
  {
    name: "AI",
    url: "/AI",
    icon: PsychologyIcon,
    submenu: new Map(),
  },,
  {
    name: "Data Analytics",
    url: "/data-analytics",
    icon: Data,
    submenu: new Map(),
  },
*/,
  {
    name: "Requests",
    url: "/requests",
    icon: Requests,
    submenu: new Map([["Settings", "request-settings"]]),
  },
  {
    name: "Admin",
    url: "/admin",
    icon: AdminPanelSettingsIcon,
    submenu: new Map([]),
  },
];
const hiddenStyle = "hidden";
const visibleStyle = "visible";

export default function Sidebar() {
  const [hidden, setHidden] = useState(false);
  const location = useLocation();
  const activeRoute = location.pathname;
  const setSubmenu = SubmenuStore((state) => state.setSubmenu);
  const { logout, user } = useAuth0();

  return (
    <div
      className={`bg-primary-600 sticky top-0 h-svh m-0 px-5  flex flex-col text-md font-medium justify-between pb-5 ${
        !hidden ? "w-52" : "w-20"
      } `}
    >
      <div>
        <img className="py-3" src={!hidden ? Logo : LogoSmall}></img>
        <div className="flex flex-col ">
          {navLinks.map((item) => {
            if (item.name === "AI" && user!.locs.length < 2) {
              return null;
            }
            if (item.name === "Admin" && !user!.admin) {
              return null;
            }
            return (
              <>
                <NavLink
                  to={item.url}
                  key={`${item.name}-${item.url}`}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-primary-100 text-neutral-800 rounded-full px-2 py-1 my-2 fill-neutral-800"
                      : "bg-primary-600 px-2 py-1 my-2 fill-neutral-100 hover:bg-primary-500 rounded-full"
                  }
                >
                  <div className="flex">
                    <item.icon className="mt-0.5 h-5 w-5" fontSize="small" />
                    {!hidden ? <div className="pl-2">{item.name}</div> : null}
                  </div>
                </NavLink>
                {item.submenu.size > 0 &&
                activeRoute === item.url &&
                !hidden ? (
                  <ul className="text-neutral-100 text-sm ml-8 pl-4 border-l-2 border-primary-100">
                    {Array.from(item.submenu).map((item, index) => {
                      return (
                        <li
                          key={`${item[1]}-${index}`}
                          className="py-0.5 rounded-full opacity-80 hover:opacity-100"
                        >
                          <button
                            key={`${index}-${item[1]}`}
                            onClick={() => {
                              setSubmenu(item[1]);
                            }}
                          >
                            {item[0]}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </>
            );
          })}
        </div>
        <button
          className="bg-primary-600 px-2 py-1 my-2 fill-neutral-100 hover:bg-primary-500 rounded-full"
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: "https://www.app.endre.tech/",
              },
            })
          }
        >
          <LogoutIcon fontSize="small" className="" />{" "}
          {!hidden && <span className="pl-1">Logout</span>}
        </button>
      </div>

      <div>
        <button
          onClick={() => setHidden(!hidden)}
          className="text-neutral-100 flex my-3 fill-neutral-100"
        >
          {!hidden ? (
            <>
              <CollapseMenu className="mr-2 h-6 w-6" /> Hide menu
            </>
          ) : (
            <ExpandMenu className="h-6" />
          )}
        </button>
      </div>
    </div>
  );
}
